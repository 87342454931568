import { ColumnDef } from "@tanstack/react-table";
import { HistorySchema } from "@/lib/react-query/queryHooks/useInfiniteAssetHistory";

type Params = {
  formattedAddres?: string | null | undefined;
  radius?: number;
};

export const assetHistoryColumnsDefinition = (
  params: Params
): ColumnDef<HistorySchema>[] => {
  const { formattedAddres, radius } = params;

  return [
    {
      accessorKey: "dateTime",
      header: "DATE TIME",
      cell: ({
        row: {
          original: { dateTime },
        },
      }) => (
        <span className="block w-[350px] text-start text-base text-palette-black">
          {dateTime.toString()}
        </span>
      ),
      enableSorting: false,
    },
    {
      accessorKey: "latlng",
      header: "LAT / LNG",
      cell: ({
        row: {
          original: { lat, lng },
        },
      }) => (
        <span className="block text-start text-base text-palette-black">
          {lat} | {lng}
        </span>
      ),
      enableSorting: false,
    },
    {
      accessorKey: "localDateTime",
      header: "LOCAL DATE TIME",
      cell: ({
        row: {
          original: { localDateTime },
        },
      }) => {
        return (
          <span className="block text-start text-base text-palette-black">
            {localDateTime.toString()}
          </span>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "macAddress",
      header: "MAC ADDRESS",
      cell: ({
        row: {
          original: { macAddress },
        },
      }) => {
        return (
          <span className="block text-start text-base text-palette-black">
            {macAddress}
          </span>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "provider",
      header: "PROVIDER",
      cell: ({
        row: {
          original: { provider },
        },
      }) => {
        return (
          <span className="block text-start text-base text-palette-black">
            {provider}
          </span>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "source",
      header: "SOURCE",
      cell: ({
        row: {
          original: { source },
        },
      }) => {
        return (
          <span className="block text-start text-base text-palette-black">
            {source.type}
          </span>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "address",
      header: "ADDRESS",
      cell: () => {
        return (
          <span className="block text-start text-base text-palette-black">
            {formattedAddres || ""}
          </span>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "radius",
      header: "RADIUS",
      cell: () => {
        return (
          <span className="block text-start text-base text-palette-black">
            {`${radius}` || ""}
          </span>
        );
      },
      enableSorting: false,
    },
  ];
};
