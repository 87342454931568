/* eslint-disable @typescript-eslint/no-explicit-any */
import { Control, Controller, Path } from "react-hook-form";
import { Select } from ".";
import { Option } from "../../types/option";
import { MouseEventHandler } from "react";

type ControlledSelectProps<T extends object> = {
  capitalize?: boolean;
  className?: string;
  containerClassNames?: string;
  control: Control<T>;
  disabled?: boolean;
  externalHandler?: (_option: Option) => void;
  hidePlaceholder?: boolean;
  label?: string;
  name: Path<T>;
  onChange?: ((_selectedOption: Option | undefined) => void) | undefined;
  onClick?: MouseEventHandler<HTMLSelectElement> | undefined;
  optionClassNames?: string;
  options: Option[];
  placeholder?: string;
  required?: boolean;
  selectClassNames?: string;
};

function ControlledSelect<T extends object>({
  capitalize = false,
  containerClassNames,
  className,
  control,
  disabled = false,
  externalHandler,
  label,
  name,
  optionClassNames,
  options,
  placeholder,
  required = false,
  selectClassNames,
  onClick,
  hidePlaceholder,
  onChange,
}: ControlledSelectProps<T>) {
  const handleChange = (
    option: Option | undefined,
    onChange: (..._event: any[]) => void
  ) => {
    onChange(option);
    if (externalHandler && option) {
      externalHandler(option);
    }
  };

  return (
    <Controller
      name={name}
      rules={{ required }}
      control={control}
      render={({
        field: { onChange: onFormChange, value },
        fieldState: { error },
      }) => {
        return (
          <Select
            capitalize={capitalize}
            containerClassNames={containerClassNames}
            className={className}
            disabled={disabled}
            error={error}
            label={label}
            onChange={(e) => {
              handleChange(e, onFormChange);
              if (onChange) {
                onChange(e);
              }
            }}
            optionClassNames={optionClassNames}
            options={options}
            placeholder={placeholder}
            selectClassNames={selectClassNames}
            value={value}
            onClick={onClick}
            hidePlaceholder={hidePlaceholder}
          />
        );
      }}
    />
  );
}

export { ControlledSelect };
