import "./styles.css";
import { FC } from "react";
import { FieldError } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { classNames } from "@/utils/helpers/classNames";

type TimePickerProps = {
  label?: string;
  onChange: (_date: Date | null) => void;
  error?: FieldError | undefined;
  disabled?: boolean;
  value: Date | null;
  dateFormat?: string;
};

const TimePicker: FC<TimePickerProps> = ({
  label,
  onChange,
  error,
  disabled = false,
  value,
  dateFormat = "h:mm aa",
}) => {
  const handleChange = (date: Date | null) => {
    onChange(date);
  };

  return (
    <div className="flex flex-col">
      {label && (
        <label
          className={classNames(
            "text-sm font-medium",
            disabled ? "text-[#97989e]" : "text-[#575757]"
          )}
          htmlFor={label}
        >
          {label}
        </label>
      )}

      <ReactDatePicker
        id={label}
        selected={value}
        onChange={handleChange}
        className={`${error && "!border !border-[#D65D59]"}`}
        disabled={disabled}
        showTimeSelectOnly
        showTimeSelect
        timeIntervals={15}
        timeCaption="Time"
        dateFormat={value ? dateFormat : undefined}
        wrapperClassName="tp"
        popperClassName="tp"
        calendarClassName="tp"
      />

      {error && (
        <p className="mt-0 pt-0 text-[12px] text-xs font-medium text-[#D65D59]">
          {error.message || "Error"}
        </p>
      )}
    </div>
  );
};

export { TimePicker };
