import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { AssetsAsset } from "../../../types/assetInventory/assets/general";
import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

interface UseAssetQueryParams {
  assetId: string;
  options?: Partial<DefinedInitialDataOptions<UseAssetQueryReturn>>;
}

interface UseAssetQueryReturn {
  asset: AssetsAsset;
}

export const useAsset = (params: UseAssetQueryParams) => {
  const { fetchWithAuth } = useAuthContext();
  const { assetId, options } = params;

  return useQuery<UseAssetQueryReturn, Error>({
    queryKey: [QUERY_KEYS.ASSET, { assetId }],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/assets/""/${assetId}`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching asset");
      }

      return apiRes.json();
    },
    enabled: Boolean(assetId),
    ...(options && { ...options }),
  });
};
