import { create } from "zustand";
import { createSelectors } from "./createSelectors";

type SidebarStore = {
  setUnsavedDataWarning: (warn: boolean) => void;
  setUnsavedDataWarningMessage: (message: string) => void;
  setHrefToHighlight: (href: string) => void;
  showUnsavedDataWarning: boolean;
  unsavedDataWarningMessage: string;
  hrefToHighlight: string;
};

export const useSidebarStore = create<SidebarStore>((set) => ({
  setUnsavedDataWarning: (warn) => set({ showUnsavedDataWarning: warn }),
  setUnsavedDataWarningMessage: (message) =>
    set({ unsavedDataWarningMessage: message }),
  setHrefToHighlight: (href) => set({ hrefToHighlight: href }),
  showUnsavedDataWarning: false,
  unsavedDataWarningMessage: "",
  hrefToHighlight: "",
}));

export const useSidebarStoreSelectors = createSelectors(useSidebarStore);
