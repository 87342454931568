/* eslint-disable @typescript-eslint/no-explicit-any */

import { ColorOption } from "@/types/option";
import { ColorSelect } from "./ColorSelect";
import { Control, Controller } from "react-hook-form";
import { FC } from "react";

type ControlledColorSelectProps = {
  control: Control<any>;
  label: string;
  name: string;
  options: ColorOption[];
  required?: boolean;
};

export const ControlledColorSelect: FC<ControlledColorSelectProps> = ({
  control,
  label,
  name,
  options,
  required,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <ColorSelect
            option={value}
            onChange={onChange}
            label={label}
            error={error}
            options={options}
          />
        );
      }}
      rules={{ required }}
    />
  );
};
