import { useAuth0 } from "@auth0/auth0-react";
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { classNames } from "../../utils/helpers/classNames";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ProfileIcon } from "../../assets";
import { signOut } from "@/utils/helpers/signOut";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

const UserMenu = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  return (
    <div id="user-button">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button>
            <ProfileIcon />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute bottom-1 left-10 min-w-[224px] origin-bottom-left rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="submit"
                  className={classNames(
                    "block w-full px-4 py-2 text-left text-sm text-gray-700",
                    active && "bg-slate-50"
                  )}
                  onClick={() => navigate("profile")}
                >
                  Profile
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  type="submit"
                  className={classNames(
                    "block w-full px-4 py-2 text-left text-sm text-gray-700",
                    active && "bg-slate-50"
                  )}
                  onClick={() => {
                    window.open(
                      "https://ribbiot.zendesk.com/hc/en-us",
                      "_blank"
                    );

                    amplitude.track(AMPLITUDE_EVENTS.HELP_CENTER_VIEWED);
                  }}
                >
                  Support
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  type="submit"
                  className={classNames(
                    "block w-full px-4 py-2 text-left text-sm text-gray-700",
                    active && "bg-slate-50"
                  )}
                  onClick={() => signOut(logout)}
                >
                  Sign out
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export { UserMenu };
