import { useMutation } from "@tanstack/react-query";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

interface UseUploadAssetImgMutationParams {
  img: {
    mime: string;
    image: string | ArrayBuffer | null | undefined;
  };
}

interface UseUploadAssetImgMutationReturn {
  ETag: string;
  ServerSideEncryption: string;
  Location: string;
  key: string;
  Key: string;
  Bucket: string;
}

export const useUploadAssetImg = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<
    UseUploadAssetImgMutationReturn,
    Error,
    UseUploadAssetImgMutationParams
  >({
    mutationFn: async ({ img }) => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/images`,
        method: "POST",
        body: JSON.stringify(img),
      });

      if (!apiRes.ok) {
        throw new Error(`Error while uploading Asset Image`);
      }

      return apiRes.json();
    },
  });
};
