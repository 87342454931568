import { JobDocument } from "@/types/jobManagementService/general";
import { MUTATION_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const jobsBaseUrl = import.meta.env.VITE_JOBS_BASE_URL;

interface UseUpdateDocumentMutationParams {
  body: JobDocument;
  jobId: string;
  documentId: string;
}

interface UseUpdateDocumentMutationReturn {
  data: JobDocument;
}

export const useUpdateJobDocument = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<
    UseUpdateDocumentMutationReturn,
    Error,
    UseUpdateDocumentMutationParams
  >({
    mutationFn: async ({ jobId, body, documentId }) => {
      const apiRes = await fetchWithAuth({
        url: `${jobsBaseUrl}/job/${jobId}/document/${documentId}`,
        method: "PATCH",
        body: JSON.stringify(body),
      });

      if (!apiRes.ok) {
        throw new Error(`Error updating document`);
      }

      return apiRes.json();
    },
    mutationKey: [MUTATION_KEYS.UPDATE_JOB_DOCUMENT],
  });
};
