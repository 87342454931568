import { DivisionSettingsForm } from "@/components/AccountSettings";
import { useDivision } from "@/lib/react-query/queryHooks/useDivision";
import { useLocation } from "react-router-dom";

const DivisionSettingsPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const divisionId = searchParams.get("divisionId") ?? "";
  const { data: division } = useDivision({ divisionId });

  return (
    <div className="w-full bg-transparent">
      <div className="h-screen items-center overflow-y-auto">
        <h1 className="mb-12 mt-24 text-4xl font-semibold">
          Division Settings
        </h1>
        <DivisionSettingsForm divisionData={division} edit={true} />
      </div>
    </div>
  );
};

export default DivisionSettingsPage;
