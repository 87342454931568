import * as React from "react";

interface CircleIconProps {
  color?: string;
}

export const CircleIcon: React.FC<CircleIconProps> = ({ color = "red" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
    >
      <circle cx="7.5" cy="7.5" r="7.5" fill={color} />
    </svg>
  );
};
