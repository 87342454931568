import { ChangeEvent, FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ControlledTimePicker } from "@/UI/TimePicker";
import {
  addDays,
  differenceInDays,
  isSameDay,
  isValid,
  startOfDay,
} from "date-fns";
import { formatDateWithinTimezoneOffset } from "@/utils/helpers/dateFormat";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useFormRendererStoreSelectors } from "@/lib/zustand/formRendererStore";
import { get } from "@/utils/helpers/lodashCopies";
import { RowField } from "@/types/forms/formEngine";
import { transformTitleByFieldItem } from "@/utils/helpers/forms/formRendererHelpers";

type EditTimeFieldProps = {
  fieldItem: RowField;
};

export const EditTimeField: FC<EditTimeFieldProps> = ({ fieldItem }) => {
  const formSubmission = useFormRendererStoreSelectors.use.formSubmission();
  const timezoneOffset = get(formSubmission, "timezoneOffset");
  const initialTimecardDate = get(formSubmission, "localDateTime");
  const { control, setValue, watch } = useFormContext();
  const formDateWatch = watch("localDateTime");
  const timefieldWatch = watch(`${fieldItem?.id}`);
  const [formDate, setFormDate] = useState<Date>(
    formatDateWithinTimezoneOffset(
      new Date(initialTimecardDate ?? Date.now()),
      timezoneOffset
    )
  );
  // timefieldDateTracker is used when changing undefined to date value. Need it to default to the sane day as "Date Of Work"
  const [timefieldDateTracker, setTimefieldDateTracker] = useState<
    Date | undefined
  >(timefieldWatch);

  const getIsNextDay = () => {
    if (!formDateWatch || !timefieldWatch) return false;
    return !isSameDay(formDateWatch, timefieldWatch);
  };

  const [isNextDay, setIsNextDay] = useState<boolean>(getIsNextDay());
  const handleNextDayClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue(`${fieldItem?.id}`, addDays(timefieldWatch, 1));
    } else {
      setValue(`${fieldItem?.id}`, addDays(timefieldWatch, -1));
    }
    setIsNextDay(event.target.checked);
  };

  const handleClear = () => {
    setValue(`${fieldItem?.id}`, null);
    setTimefieldDateTracker(undefined);
  };

  const title = transformTitleByFieldItem(fieldItem);

  useEffect(() => {
    if (!formDateWatch || !isValid(timefieldWatch)) return;
    const daysDifference = differenceInDays(
      startOfDay(formDateWatch),
      startOfDay(formDate)
    );
    setValue(`${fieldItem?.id}`, addDays(timefieldWatch, daysDifference));
    setFormDate(formDateWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDateWatch]);

  useEffect(() => {
    if (!timefieldWatch) return;
    if (!timefieldDateTracker) {
      const daysDifference =
        differenceInDays(startOfDay(formDateWatch), timefieldWatch) +
        (isNextDay ? 1 : 0);
      setValue(`${fieldItem?.id}`, addDays(timefieldWatch, daysDifference));
    }
    setTimefieldDateTracker(timefieldWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timefieldWatch]);

  return (
    <div
      className="w-24 space-y-1"
      style={{
        gridColumnStart: fieldItem?.columnStart,
      }}
    >
      <div className="group relative mr-4  w-24 text-base">
        <div className="flex">
          <ControlledTimePicker
            dateFormat="h:mmaaa"
            control={control}
            name={`${fieldItem?.id}`}
          />
        </div>
        <button
          type="button"
          className="absolute -right-2 -top-2 hidden cursor-pointer rounded-xl bg-white transition-all group-hover:block"
          onClick={handleClear}
        >
          <XCircleIcon color="red" width={20} height={20} />
        </button>
        {isValid(timefieldWatch) && (
          <div className=" mt-1 flex space-x-1">
            <input
              className={`h-3 w-3 bg-inherit checked:bg-theme-green-primary checked:text-theme-green-primary focus:ring-0 `}
              type="checkbox"
              id={`next-day-checkbox-${fieldItem?.id}`}
              name={title}
              checked={isNextDay}
              onChange={handleNextDayClick}
            />
            <label
              id={`next-day-checkbox-${fieldItem?.id}`}
              htmlFor={`next-day-checkbox-${fieldItem?.id}`}
              className="self-start text-xs "
            >
              Next Day
            </label>
          </div>
        )}
      </div>
    </div>
  );
};
