import { FC, InputHTMLAttributes } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { CheckBox } from ".";

interface ControlledCheckBoxProps
  extends InputHTMLAttributes<HTMLInputElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  error?: FieldError;
  id?: string;
  label?: string;
  name: string;
  required?: boolean;
  sideText?: string;
}

export const ControlledCheckbox: FC<ControlledCheckBoxProps> = ({
  control,
  defaultValue,
  error,
  id,
  label,
  name,
  required,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
      }}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <CheckBox
          checked={value}
          defaultValue={defaultValue}
          error={error}
          id={id}
          label={label}
          onChange={onChange}
          {...rest}
        />
      )}
    />
  );
};
