const FilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
    >
      <line y1="2.5" x2="3" y2="2.5" stroke="white" />
      <line x1="6" y1="2.5" x2="14" y2="2.5" stroke="white" />
      <line x1="12" y1="6.5" x2="14" y2="6.5" stroke="white" />
      <line y1="6.5" x2="8.5" y2="6.5" stroke="white" />
      <line x1="6" y1="10.5" x2="14" y2="10.5" stroke="white" />
      <line y1="10.5" x2="2.5" y2="10.5" stroke="white" />
      <circle cx="4.25" cy="2.5" r="1.75" stroke="white" />
      <circle cx="4.25" cy="10.5" r="1.75" stroke="white" />
      <path
        d="M12 6.5C12 7.4665 11.2165 8.25 10.25 8.25C9.2835 8.25 8.5 7.4665 8.5 6.5C8.5 5.5335 9.2835 4.75 10.25 4.75C11.2165 4.75 12 5.5335 12 6.5Z"
        stroke="white"
      />
    </svg>
  );
};

export { FilterIcon };
