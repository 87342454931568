import { create } from "zustand";
import { createSelectors } from "./createSelectors";
import { ColumnFiltersState } from "@tanstack/react-table";
import { sub } from "date-fns";

type DateRange = [Date | null, Date | null];
type QuoteStore = {
  quoteDateRange: DateRange;
  setQuoteDateRange: (quoteDateRange: DateRange) => void;
  clientQuoteFormColumnFiltersState: ColumnFiltersState;
  setClientQuoteFormColumnFiltersState: (
    clientQuoteFormColumnFiltersState: ColumnFiltersState
  ) => void;
  serverQuoteFormColumnFiltersState: Record<string, string | undefined>;
  setServerQuoteFormColumnFiltersState: (
    serverQuoteFormColumnFiltersState: Record<string, string | undefined>
  ) => void;
  serverQuoteFormColumnOrderState: Record<string, string | undefined>;
  setServerQuoteFormColumnOrderState: (
    serverQuoteFormColumnOrderState: Record<string, string | undefined>
  ) => void;
};
export const useQuoteStore = create<QuoteStore>((set) => ({
  quoteDateRange: [sub(new Date(), { days: 7 }), new Date()],
  setQuoteDateRange: (quoteDateRange) => {
    set({ quoteDateRange });
  },
  clientQuoteFormColumnFiltersState: [],
  setClientQuoteFormColumnFiltersState: (clientQuoteFormColumnFiltersState) => {
    set({ clientQuoteFormColumnFiltersState });
  },
  serverQuoteFormColumnFiltersState: {},
  setServerQuoteFormColumnFiltersState: (serverQuoteFormColumnFiltersState) => {
    set({ serverQuoteFormColumnFiltersState });
  },
  serverQuoteFormColumnOrderState: {},
  setServerQuoteFormColumnOrderState: (serverQuoteFormColumnOrderState) => {
    set({ serverQuoteFormColumnOrderState });
  },
}));
export const useQuoteStoreSelectors = createSelectors(useQuoteStore);
