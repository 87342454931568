import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { FC, Fragment, ReactNode } from "react";
import { classNames } from "../../utils/helpers/classNames";
import { Option } from "../../types/option";
import { Divider } from "../Divider";
import { DragEvent } from "react";
import { FieldError } from "react-hook-form";

type DropdownProps = {
  label?: string;
  title?: string;
  closeIcon?: boolean;
  options: (Option & { icon?: ReactNode; color?: string })[];
  optionsClassNames?: string;
  valuesClassNames?: string;
  buttonClassNames?: string;
  buttonElement?: ReactNode;
  menuClassNames?: string;
  defaultOption?: Option;
  selectedOption?: Option;
  menuItemsClassNames?: string;
  error?: FieldError;
  onChange?: (item: Option) => void;
  onItemDragStart?: (
    e: DragEvent<HTMLLIElement | HTMLDivElement>,
    jobId: string
  ) => void;
};
export const Dropdown: FC<DropdownProps> = ({
  label,
  title,
  closeIcon,
  options,
  buttonClassNames = "",
  menuClassNames = "",
  onChange,
  error,
  onItemDragStart,
  optionsClassNames,
  valuesClassNames,
  buttonElement,
  menuItemsClassNames,
  defaultOption,
  selectedOption,
}) => {
  return (
    <Menu
      as="div"
      className={classNames("relative", menuClassNames)}
      defaultValue={defaultOption?.value}
    >
      {({ close }) => (
        <>
          <Menu.Button
            type="button"
            className={classNames(
              "flex items-center gap-x-1.5 rounded-md border border-[#949494] bg-[#fafdfe] px-3 py-2 text-sm font-semibold text-[#141c22] hover:border-[#393d41]",
              buttonClassNames,
              error ? "border border-red-500" : ""
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {buttonElement ? (
              <>{buttonElement}</>
            ) : (
              <>
                {label}
                <ChevronDownIcon
                  className="ml-auto h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </>
            )}
          </Menu.Button>
          {error && (
            <p className="text-[12px] font-medium text-theme-red-primary">
              {error.message || "Error"}
            </p>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={classNames(
                "absolute left-0 z-50 w-40 rounded-md bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ",
                options.length > 10 ? "h-[345px] overflow-scroll" : "",
                menuItemsClassNames
              )}
            >
              <div className="flex">
                {title && <h1>{title}</h1>}
                {closeIcon && (
                  <XMarkIcon
                    onClick={() => close()}
                    className="ml-auto h-4 w-4 rounded-full hover:bg-slate-300"
                  />
                )}
              </div>
              {(title || closeIcon) && <Divider />}
              <div className="py-1">
                {options.map((item, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <>
                        <li
                          className={classNames(
                            active
                              ? "bg-opacity-75 text-gray-900 "
                              : "text-gray-700",
                            "my-0 flex w-full items-center justify-center rounded-sm  text-sm hover:bg-gray-100",
                            optionsClassNames ?? "",
                            selectedOption?.value === item.value &&
                              "bg-gray-100"
                          )}
                          style={{
                            backgroundColor: item.color,
                          }}
                        >
                          {item.icon && (
                            <i className="my-auto ml-4">{item.icon}</i>
                          )}

                          <button
                            type="button"
                            {...(onChange && {
                              onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onChange(item);
                                close();
                              },
                            })}
                            className={classNames(
                              "flex h-full w-full items-center px-0.5 py-2 text-left"
                            )}
                          >
                            <span
                              className={classNames("ml-2", valuesClassNames)}
                            >
                              {item.value}
                            </span>
                          </button>

                          {onItemDragStart && (
                            <div
                              draggable={!!onItemDragStart}
                              onDragStart={(e) => {
                                onItemDragStart && onItemDragStart(e, item.id);
                              }}
                              onFocus={() => undefined}
                              className={classNames(
                                "absolute inset-0 z-20  flex h-6 cursor-grab bg-opacity-0"
                              )}
                            />
                          )}
                        </li>
                      </>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
