import "./styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import { AppRouter } from "./AppRouter.tsx";
import { Auth0ProviderWithHistory } from "./components/Auth/Auth0ProviderWithHistory";
import { AuthWrapper } from "./components/Auth/AuthWrapper";
import { BrowserRouter } from "react-router-dom";
import { datadogRum } from "@datadog/browser-rum";
import { DefaultLayout } from "./layouts";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback } from "./UI/Fallback/Fallback.tsx";
import { FC, Suspense } from "react";
import { isLocal, isProduction } from "./utils/helpers/envHelpers.ts";
import { LoadingSpinner } from "./UI/Loading";
import { MapWrapper } from "./components/Map/MapWrapper.tsx";
import { NotFoundWrapper } from "./components/NotFound/NotFoundWrapper.tsx";
import { queryClient } from "./lib/react-query/general";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import * as amplitude from "@amplitude/analytics-browser";
import { LDWrapper } from "./components/LDWrapper/LDWrapper.tsx";

if (!isLocal) {
  datadogRum.init({
    applicationId: "3988b693-cf7b-498f-ac13-e0630d0b98ba",
    clientToken: "pube0df277293d0f77267d3f240a798aecb",
    site: "us5.datadoghq.com",
    service: "ribbiot-web",
    env: import.meta.env.VITE_ENV,
    version: import.meta.env.VITE_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });
  datadogRum.startSessionReplayRecording();
}

amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
  logLevel: isProduction
    ? amplitude.Types.LogLevel.None
    : amplitude.Types.LogLevel.Warn,
  identityStorage: "localStorage",
  defaultTracking: {
    attribution: false,
    pageViews: {
      trackOn: () => {
        // probably don't want to track auth0 authorization code page
        return !window.location.search.includes("code");
      },
    },
    sessions: false,
    formInteractions: false,
    fileDownloads: false,
  },
  // for local testing of analytics, comment out following line
  optOut: isLocal,
});

const App: FC = () => {
  return (
    <>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <ErrorBoundary
            fallbackRender={({ error }) => <Fallback error={error} />}
          >
            <AuthWrapper>
              <LDWrapper>
                <QueryClientProvider client={queryClient}>
                  <ReactQueryDevtools
                    initialIsOpen={false}
                    buttonPosition="bottom-right"
                  />
                  <ToastContainer />
                  <DefaultLayout>
                    <Suspense fallback={<LoadingSpinner />}>
                      <NotFoundWrapper>
                        <MapWrapper>
                          <AppRouter />
                        </MapWrapper>
                      </NotFoundWrapper>
                    </Suspense>
                  </DefaultLayout>
                </QueryClientProvider>
              </LDWrapper>
            </AuthWrapper>
          </ErrorBoundary>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </>
  );
};

export default App;
