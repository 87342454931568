const LeftArrow = () => {
  return (
    <svg
      id="left-arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
    >
      <path d="M10 1L2 9L10 17" stroke="#2E3A59" strokeWidth="2" />
    </svg>
  );
};

export { LeftArrow };
