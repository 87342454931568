import { ColumnDef, Row } from "@tanstack/react-table";
import { IndeterminateCheckbox } from "@/UI/IndeterminateCheckbox";
import { GqlPriceBookItem } from "@/lib/graphql/graphql";

const selectorFilterFn = (
  row: Row<GqlPriceBookItem>,
  _id: string,
  value: string,
  type: "branch" | "division" | "name" | "unit" | "rate" | "category"
) => {
  if (!value) return true;

  const {
    original: { branchIds, divisionIds, name, category },
  } = row;

  let match: string | undefined;

  switch (type) {
    case "branch":
      match = branchIds?.find((branch) => branch === value);
      break;
    case "division":
      match = divisionIds?.find((division) => division === value);
      break;
    case "category":
      match = category?.toLowerCase().includes(value.toLowerCase())
        ? "found"
        : "";
      break;
    case "name":
      match = name?.toLowerCase().includes(value.toLowerCase()) ? "found" : "";
      break;
    default:
      break;
  }

  if (match) return true;

  return false;
};
interface PricebookColumnsProps {
  selectedPricebookIds: string[];
}

export const quoteItemsColumnsDefinition = ({
  selectedPricebookIds,
}: PricebookColumnsProps): ColumnDef<GqlPriceBookItem>[] => {
  return [
    {
      accessorKey: "select",
      header: () => <span className="!m-0 !w-0 !p-0"></span>,
      cell: ({ row }) => {
        if (selectedPricebookIds.includes(row.original.id)) {
          row.toggleSelected();
        }
        return (
          <div className="!m-0 !w-0 !p-0">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      },
      enableSorting: false,
    },
    {
      header: "Name",
      accessorKey: "name",
      cell: ({
        row: {
          original: { name, description },
        },
      }) => {
        return (
          <div className="flex flex-col gap-y-5">
            <p>{name}</p>
            <p>{description || ""}</p>
          </div>
        );
      },
      enableSorting: true,
      filterFn: (row, id, value) => selectorFilterFn(row, id, value, "name"),
      sortingFn: (rowA, rowB) =>
        rowA.original.name.localeCompare(rowB.original.name),
    },
    {
      header: "Unit",
      accessorKey: "unit",
    },
    {
      header: "Quantity",
      accessorKey: "quantity",
      cell: "1",
    },
    {
      header: "Rate",
      accessorKey: "rate",
      cell: ({
        row: {
          original: { rate },
        },
      }) => {
        return <span>{`$${rate}`}</span>;
      },
    },
    {
      header: "Division",
      accessorKey: "division",
      filterFn: (row, id, value) =>
        selectorFilterFn(row, id, value, "division"),
      enableSorting: false,
    },
    {
      header: "Branch",
      accessorKey: "branch",
      filterFn: (row, id, value) => selectorFilterFn(row, id, value, "branch"),
      enableSorting: false,
    },
    {
      header: "Category",
      accessorKey: "category",
      filterFn: (row, id, value) =>
        selectorFilterFn(row, id, value, "category"),
      enableSorting: false,
    },
  ];
};
