/* eslint-disable @typescript-eslint/no-explicit-any */
import { classNames } from "@/utils/helpers/classNames";
import { getLayoutClassesByAttributes } from "@/utils/helpers/forms/formRendererHelpers";
import * as React from "react";

interface RowItemsContainerProps {
  children: React.ReactNode;
  layoutField: any;
}

export const RowItemsContainer: React.FC<RowItemsContainerProps> = ({
  children,
  layoutField,
}) => {
  // If there are no items in the row, do not render a grid row, return null
  const count = React.Children.toArray(children).filter((item) => item).length;
  if (count === 0) return null;
  return (
    <div
      className={classNames(
        "mx-6 my-6 grid h-full",
        getLayoutClassesByAttributes(layoutField.attributes)
      )}
      style={{
        gridTemplateColumns: `repeat(${layoutField.columns}, minmax(0, 1fr))`,
      }}
    >
      {children}
    </div>
  );
};
