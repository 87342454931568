import * as React from "react";
import { classNames } from "@/utils/helpers/classNames";
import { getTitleClassesByAttributes } from "@/utils/helpers/forms/formRendererHelpers";
import { MultiUserFieldValue, RowField } from "@/types/forms/formEngine";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Select } from "@/UI/Select";
import { useDocumentDetailHelpers } from "@/utils/hooks/useDocumentDetailHelpers";

interface EditMultiUserFieldProps {
  fieldItem: RowField;
}

export const EditMultiUserField: React.FC<EditMultiUserFieldProps> = ({
  fieldItem,
}) => {
  const { control, setValue, watch } = useFormContext();
  const {
    fields: formUsers,
    append,
    remove,
  } = useFieldArray({
    control,
    name: fieldItem?.id ?? "",
  });

  const { getUserOptions, getOccupationOptions, getOccupationNameById } =
    useDocumentDetailHelpers();

  return (
    <>
      <div className="space-y-5">
        {(formUsers as unknown as MultiUserFieldValue[]).map(
          (formUser, index) => {
            const formUserWatch = watch(`${fieldItem?.id}.${index}`);
            return (
              <div key={formUser.userId ?? index} className="flex space-x-2">
                <div className="w-60 space-y-1 pr-2">
                  <h4
                    className={classNames(
                      "text-sm font-medium text-theme-black-secondary",
                      getTitleClassesByAttributes(fieldItem?.attributes)
                    )}
                  >
                    Crew Member
                  </h4>
                  <Select
                    value={{
                      id: formUserWatch.userId,
                      value: formUserWatch.userName,
                    }}
                    onChange={(selectedOption) => {
                      setValue(`${fieldItem?.id}.${index}`, {
                        userId: selectedOption?.id,
                        userName: selectedOption?.value,
                        occupationId: formUserWatch.occupationId,
                      });
                    }}
                    options={getUserOptions()}
                  />
                </div>
                <div className="w-60 space-y-1 pr-2">
                  <h4 className="text-sm font-medium text-theme-black-secondary">
                    Role
                  </h4>
                  <Select
                    value={{
                      id: formUserWatch.occupationId,
                      value:
                        getOccupationNameById(formUserWatch.occupationId) ?? "",
                    }}
                    onChange={(selectedOption) => {
                      setValue(`${fieldItem?.id}.${index}`, {
                        userId: formUserWatch.userId,
                        userName: formUserWatch.userName,
                        occupationId: selectedOption?.id,
                      });
                    }}
                    options={getOccupationOptions()}
                  />
                </div>
                <button
                  type="button"
                  className={classNames(
                    "translate-x-2 translate-y-3 text-theme-red-primary",
                    formUsers.length === 1 ? "hidden" : ""
                  )}
                  onClick={() => remove(index)}
                >
                  Remove
                </button>
              </div>
            );
          }
        )}
        <button
          type="button"
          onClick={() => append({})}
          className="mt-4 text-theme-green-primary"
        >
          + Add Crew Member
        </button>
      </div>
    </>
  );
};
