import { QUERY_KEYS } from "../constants";
import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { User } from "@/types/users/general";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseUserQueryResult {
  data: User;
}

interface UseUserQueryParams {
  userId?: string;
  options?: Partial<DefinedInitialDataOptions<UseUserQueryResult>>;
}

export const useUser = (params?: UseUserQueryParams) => {
  const { fetchWithAuth, ribbiotId } = useAuthContext();
  const userId = params?.userId ?? ribbiotId;
  const options = params?.options;

  return useQuery<UseUserQueryResult, Error>({
    queryKey: [QUERY_KEYS.USER, { userId }],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${usersBaseUrl}/users/${userId}`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching user");
      }

      return apiRes.json();
    },
    enabled: Boolean(userId.length),
    ...(options && { ...options }),
  });
};
