import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { z } from "zod";
import { ControlledInput } from "@/UI/Input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { v4 as uuidv4 } from "uuid";
import { ControlledSelect } from "@/UI/Select/ControlledSelect";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { ControlledTextArea } from "@/UI/TextArea";
import { Button } from "@/UI/Button";
import {
  UseCreateFormMutationParams,
  useCreateForm,
} from "@/lib/react-query/mutationHooks/useCreateForm";
import { toast } from "react-toastify";
import { queryClient } from "@/lib/react-query/general";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { useAuthContext } from "@/components/Auth/AuthWrapper";

const createFormSchema = z.object({
  formVersion: z.string(),
  name: z.string().min(1, { message: "Please provide a Form Name." }),
  description: z
    .string()
    .min(1, { message: "Please provide a Form Description." }),
  division: z.object({ value: z.string(), id: z.string() }),
});
export type CreateFormSchema = z.infer<typeof createFormSchema>;

export const createFormDefaultValues = {
  formVersion: "0.0.5",
  name: "My Form",
  description: "Description for My Form",
};
const CreateFormPage: FC = () => {
  const { accountId } = useAuthContext();
  const createForm = useCreateForm();
  const formMethods = useForm<CreateFormSchema>({
    defaultValues: createFormDefaultValues,
    resolver: zodResolver(createFormSchema),
  });

  const { data: divisionsData } = useDivisions();
  const divisions =
    divisionsData?.data?.map((division) => ({
      value: division.divisionName ?? "",
      id: division.id ?? "",
    })) ?? [];
  const navigate = useNavigate();
  const onSubmit = (data: CreateFormSchema) => {
    const createFormBody: UseCreateFormMutationParams = {
      body: {
        divisionId: data.division.id,
        formVersion: data.formVersion,
        name: data.name,
        fields: [
          {
            name: "Form Description",
            attributes: {
              hidden: "true",
            },
            id: uuidv4(),
            text: data.description,
            type: "Text",
          },
        ],
      },
    };
    createForm.mutate(createFormBody, {
      onSuccess: () => {
        toast.success("Form Created");
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.ACCOUNT, { accountId }],
        });
        navigate("/settings/forms");
      },
      onError: () => {
        toast.error("Error creating form");
      },
    });
  };

  return (
    <div className="w-full max-w-[1600px] pt-14">
      <header className="mb-5 flex justify-between">
        <div>
          <Link
            className="my-4 flex w-fit items-center gap-x-1 text-theme-black-secondary"
            to="/settings/forms"
          >
            <ArrowLeftIcon className="h-6 w-6" />
            <span className="text-xs font-medium">Back to Forms</span>
          </Link>
          <h1 className="text-4xl font-semibold">Create Form</h1>
        </div>
      </header>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="flex max-w-xl space-x-4">
          <ControlledInput
            disabled
            control={formMethods.control}
            name="formVersion"
            label="Form Version"
            containerClasses="!w-[280px]"
          />
        </div>
        <div className="my-4 flex max-w-xl space-x-4">
          <ControlledInput
            control={formMethods.control}
            name="name"
            label="Form Name"
            containerClasses="!w-full"
          />
          <ControlledSelect<CreateFormSchema>
            control={formMethods.control}
            name="division"
            label="Division"
            options={divisions}
            placeholder="Select a Division"
            className="!w-full"
          />
        </div>
        <div className="my-4 flex max-w-xl space-x-4">
          <ControlledTextArea
            control={formMethods.control}
            name="description"
            label="Form Description"
            containerClassNames="w-full "
            textareaStyles="min-h-[100px]"
          />
        </div>
        <Button
          className="rounded-md"
          type="submit"
          disabled={createForm.isPending || createForm.isSuccess}
          loading={createForm.isPending}
          label="Create"
          variant="primary"
          labelClasses="text-white"
        />
      </form>
    </div>
  );
};

export default CreateFormPage;
