import { FC } from "react";

interface AssetOverviewDetailFieldProps {
  title: string;
  value: string | undefined;
}

export const AssetOverviewDetailField: FC<AssetOverviewDetailFieldProps> = ({
  title,
  value,
}) => {
  return (
    <div>
      <span className="text-sm  text-theme-black-secondary">{title}</span>
      <p className="font-medium">{value && value !== "" ? value : "—"}</p>
    </div>
  );
};
