import { RowField } from "@/types/forms/formEngine";
import * as React from "react";
import { EditField } from "./EditFIeld";

interface EditNumberFieldProps {
  fieldItem: RowField;
}

export const EditNumberField: React.FC<EditNumberFieldProps> = ({
  fieldItem,
}) => {
  return <EditField fieldItem={fieldItem} inputType="number" />;
};
