import ribbiotLogo from "@/assets/RibbiotLogo.png";

export const branchFormDefaultValues = {
  address: "",
  city: "",
  email: "",
  logo: ribbiotLogo,
  name: "",
  phoneNumber: "",
  postalCode: "",
  state: "",
  divisionId: "",
};

export const divisionFormDefaultValues = {
  id: "",
  divisionName: "",
};
