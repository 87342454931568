import { useNotFoundStore } from "@/lib/zustand/notFoundStore";
import Page404 from "@/pages/404";
import { FC, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

type NotFoundWrapperProps = {
  children: ReactNode;
};
export const NotFoundWrapper: FC<NotFoundWrapperProps> = ({ children }) => {
  const { message, notFound, setNotFound } = useNotFoundStore((state) => ({
    message: state.message,
    notFound: state.notFound,
    setNotFound: state.setNotFound,
  }));
  const location = useLocation();

  useEffect(() => {
    setNotFound({ message: undefined, notFound: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (notFound) {
    return <Page404 message={message} notFound={notFound} />;
  }
  return children;
};
