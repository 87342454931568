import { classNames } from "@/utils/helpers/classNames";
import { HTMLProps, useEffect, useRef } from "react";

const IndeterminateCheckbox = ({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (typeof indeterminate === "boolean" && ref.current) {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={classNames(
        `cursor-pointer rounded-sm border border-theme-gray-secondary bg-theme-gray-senary  checked:bg-theme-green-primary indeterminate:bg-theme-green-primary 
            hover:bg-theme-green-senary checked:hover:bg-theme-green-senary indeterminate:hover:bg-theme-green-senary focus:ring-0
          `,
        className
      )}
      onClick={(ev) => ev.stopPropagation()}
      {...rest}
    />
  );
};

export { IndeterminateCheckbox };
