import { BackArrow } from "@/assets";
import { classNames } from "@/utils/helpers/classNames";
import { handleNavigation } from "@/utils/helpers/handleNavigation";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SettingsExpandedContent = () => {
  const [throwError, setThrowError] = useState<boolean>(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (throwError) {
      throw new Error("Test datadog error.");
    }
  }, [throwError]);

  return (
    <>
      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start gap-x-2 rounded-md pl-3"
        )}
        onClick={(event) => {
          handleNavigation({ event, href: "settings", navigate, pathname });
        }}
      >
        <BackArrow />

        <span className="text-xs font-medium text-palette-secondaryBlack">
          Settings
        </span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname.includes("/settings/icons")
            ? "bg-palette-secondaryGreen"
            : ""
        )}
        onClick={() => {
          navigate("/settings/icons");
        }}
      >
        <span
          className={classNames(
            "text-sm font-medium",
            pathname.includes("/settings/icons") ? "text-palette-green" : ""
          )}
        >
          App Icons
        </span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname.includes("/settings/branch/create")
            ? "bg-palette-secondaryGreen"
            : ""
        )}
        onClick={() => {
          navigate("/settings/branch/create");
        }}
      >
        <span
          className={classNames(
            "text-sm font-medium",
            pathname.includes("/settings/branch/create")
              ? "text-palette-green"
              : ""
          )}
        >
          Create Branch
        </span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3"
        )}
        onClick={() => {
          setThrowError(true);
        }}
      >
        <span className={classNames("text-sm font-medium")}>Datadog Test</span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname.includes("/settings/division/create")
            ? "bg-palette-secondaryGreen"
            : ""
        )}
        onClick={() => {
          navigate("/settings/division/create");
        }}
      >
        <span
          className={classNames(
            "text-sm font-medium",
            pathname.includes("/settings/division/create")
              ? "text-palette-green"
              : ""
          )}
        >
          Create Division
        </span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname.includes("/settings/forms")
            ? "bg-palette-secondaryGreen"
            : ""
        )}
        onClick={() => {
          navigate("/settings/forms");
        }}
      >
        <span
          className={classNames(
            "text-sm font-medium",
            pathname.includes("/settings/forms") ? "text-palette-green" : ""
          )}
        >
          Manage Forms
        </span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname.includes("/settings/location")
            ? "bg-palette-secondaryGreen"
            : ""
        )}
        onClick={() => {
          navigate("/settings/location");
        }}
      >
        <span
          className={classNames(
            "text-sm font-medium",
            pathname.includes("/settings/location") ? "text-palette-green" : ""
          )}
        >
          Manage Locations
        </span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname.includes("/settings/users")
            ? "bg-palette-secondaryGreen"
            : ""
        )}
        onClick={() => {
          navigate("/settings/users");
        }}
      >
        <span
          className={classNames(
            "text-sm font-medium",
            pathname.includes("/settings/users") ? "text-palette-green" : ""
          )}
        >
          Manage Users
        </span>
      </button>
      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname.includes("/settings/pricebook")
            ? "bg-palette-secondaryGreen"
            : ""
        )}
        onClick={() => {
          navigate("/settings/pricebook");
        }}
      >
        <span
          className={classNames(
            "text-sm font-medium",
            pathname.includes("/settings/pricebook") ? "text-palette-green" : ""
          )}
        >
          Manage Pricebook
        </span>
      </button>
    </>
  );
};

export { SettingsExpandedContent };
