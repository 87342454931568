const MagnifyingGlassIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <mask
        id="mask0_709_2091"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="black" />
      </mask>
      <g mask="url(#mask0_709_2091)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1816 9.98937C12.5894 8.01396 12.4072 5.25378 10.6349 3.48152C8.65958 1.50616 5.45688 1.50616 3.48152 3.48152C1.50616 5.45688 1.50616 8.65958 3.48152 10.6349C5.25378 12.4072 8.01396 12.5894 9.98937 11.1816L14.8078 16L16 14.8078L11.1816 9.98937ZM9.4427 4.67376C10.7596 5.99066 10.7596 8.12579 9.4427 9.4427C8.12579 10.7596 5.99066 10.7596 4.67376 9.4427C3.35685 8.12579 3.35685 5.99066 4.67376 4.67376C5.99066 3.35685 8.12579 3.35685 9.4427 4.67376Z"
          fill="#575757"
        />
      </g>
    </svg>
  );
};

export { MagnifyingGlassIcon };
