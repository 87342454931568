import { RowField } from "@/types/forms/formEngine";
import * as React from "react";
import { Field } from "./FIeld";
import { useDocumentDetailHelpers } from "@/utils/hooks/useDocumentDetailHelpers";

interface UserFieldProps {
  fieldItem: RowField;
  value: string;
}

export const UserField: React.FC<UserFieldProps> = ({ fieldItem, value }) => {
  const { getUserNameById } = useDocumentDetailHelpers();
  return (
    <Field
      key={fieldItem.id}
      fieldItem={fieldItem}
      value={getUserNameById(value)}
    />
  );
};
