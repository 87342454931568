import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const timecardsBaseUrl = import.meta.env.VITE_TIMECARDS_BASE_URL;

export const useExportDocumentPDF = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<{ data: string }, Error, { formSubmissionId?: string }>({
    mutationKey: [QUERY_KEYS.DOCUMENT_PDF],
    mutationFn: async ({ formSubmissionId }) => {
      if (formSubmissionId) {
        const apiRes = await fetchWithAuth({
          url: `${timecardsBaseUrl}/forms/${formSubmissionId}/download-link`,
          method: "GET",
        });

        if (!apiRes.ok) {
          throw new Error("Error exporting PDF");
        }

        return apiRes.json();
      }
    },
  });
};
