/* eslint-disable @typescript-eslint/no-explicit-any */
interface ResizeImageProps {
  file: File;
  maxSize: number;
}
export const resizeFile = async ({
  file,
  maxSize,
}: ResizeImageProps): Promise<string> =>
  new Promise((resolve) => {
    imageFileResizer({
      file,
      maxHeight: 500,
      maxWidth: 500,
      quality: 100,
      maxSize,
      responseURIFunc: (uri: string) => {
        resolve(uri);
      },
    });
  });

interface ImageFileResizerProps {
  file: File;
  maxWidth: number;
  maxHeight: number;
  quality: number;
  maxSize: number;
  responseURIFunc: any;
}
const imageFileResizer = ({
  file,
  maxHeight,
  maxWidth,
  quality,
  maxSize,
  responseURIFunc,
}: ImageFileResizerProps) => {
  const reader = new FileReader();
  if (!file || !file.type || !file.type.includes("image")) {
    throw Error("File Is NOT Image!");
  }
  reader.readAsDataURL(file);
  const fileType = file.type.split("/")[1] ?? "jpeg";
  reader.onload = (readerEvent) => {
    if (readerEvent.loaded > maxSize) {
      const image = new Image();
      image.src = reader.result as string;
      image.onload = () => {
        const resizedDataUrl = resizeAndRotateImage(
          image,
          maxWidth,
          maxHeight,
          quality,
          fileType
        );
        responseURIFunc(resizedDataUrl);
      };
    } else {
      responseURIFunc(readerEvent.target?.result);
    }
  };
  reader.onerror = (error: any) => {
    throw Error(error);
  };
};

const resizeAndRotateImage = (
  image: HTMLImageElement,
  maxWidth: number,
  maxHeight: number,
  quality = 100,
  fileType: string
) => {
  const qualityDecimal = quality / 100;
  const canvas = document.createElement("canvas");
  let width = image.width;
  let height = image.height;
  const newHeightWidth = changeHeightWidth(height, maxHeight, width, maxWidth);
  canvas.width = newHeightWidth.width;
  canvas.height = newHeightWidth.height;
  width = newHeightWidth.width;
  height = newHeightWidth.height;
  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.fillStyle = "rgba(0, 0, 0, 0)";
    ctx.fillRect(0, 0, width, height);
  }
  if (ctx?.imageSmoothingEnabled && ctx.imageSmoothingQuality) {
    ctx.imageSmoothingQuality = "high";
  }
  ctx?.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL(`image/${fileType}`, qualityDecimal);
};

const changeHeightWidth = (
  height: number,
  maxHeight: number,
  width: number,
  maxWidth: number
) => {
  if (width > maxWidth) {
    height = Math.round((height * maxWidth) / width);
    width = maxWidth;
  }
  if (height > maxHeight) {
    width = Math.round((width * maxHeight) / height);
    height = maxHeight;
  }
  return { height, width };
};
