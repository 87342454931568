import { classNames } from "@/utils/helpers/classNames";
import { Transition } from "@headlessui/react";
import * as React from "react";
import { useEffect, useState } from "react";

interface StickyHeaderProps {
  children: React.ReactNode;
  headerClasses?: string;
  id?: string;
}

export const StickyHeader: React.FC<StickyHeaderProps> = ({
  children,
  headerClasses = "",
  id,
}) => {
  const [stickyHeaderVisible, setStickyHeaderVisible] = useState(false);
  const scrollElement = document.getElementById("default-layout");

  const listenToScroll = () => {
    const heightToHideFrom = 100;
    const winScroll =
      scrollElement?.scrollTop || document.documentElement.scrollTop;

    if (winScroll < heightToHideFrom) {
      setStickyHeaderVisible(false);
    } else {
      setStickyHeaderVisible(true);
    }
  };

  useEffect(() => {
    scrollElement?.addEventListener("scroll", listenToScroll);
    return () => scrollElement?.removeEventListener("scroll", listenToScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="m-0 h-0 w-0 p-0">
      <Transition
        show={stickyHeaderVisible}
        enter="transition-opacity ease-linear duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <header
          id={id}
          className={classNames(
            "fixed left-14 right-0 top-0 z-20 flex h-20 content-center justify-between bg-theme-general-background shadow-xl",
            headerClasses
          )}
        >
          {children}
        </header>
      </Transition>
    </div>
  );
};
