import { classNames } from "@/utils/helpers/classNames";
import { InputHTMLAttributes } from "react";
import { FieldError } from "react-hook-form";
import * as React from "react";

interface TextAreaProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    "required"
  > {
  label?: string;
  error?: FieldError | undefined;
  textareaStyles?: string;
  containerClassNames?: string;
}

const onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
  e.currentTarget.setSelectionRange(
    e.currentTarget.value.length,
    e.currentTarget.value.length
  );
};
function TextArea({
  label,
  error,
  disabled = false,
  textareaStyles = "",
  placeholder,
  value,
  id,
  containerClassNames,
  onChange,
}: TextAreaProps) {
  return (
    <div
      className={classNames("box-border flex flex-col", containerClassNames)}
    >
      {label && (
        <label
          className={classNames(
            "text-sm font-medium",
            disabled ? "text-[#97989e]" : "text-[#575757]"
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <textarea
        placeholder={placeholder}
        onChange={onChange}
        className={classNames(
          "h-10 w-full rounded-md border border-[#949494] bg-[#fafdfe] text-[#141c22] placeholder:leading-6 placeholder:text-[#97989e] hover:border-[#393d41]",
          disabled
            ? "border-[#ebebeb] bg-[#ecf2f2] text-[#97999f] hover:!border-[#ebebeb]"
            : "",
          error ? "border-[#D65D59]" : "",
          textareaStyles
        )}
        disabled={disabled}
        id={id}
        value={value}
        onFocus={onFocus}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !event.shiftKey) {
            event.stopPropagation();
          }
        }}
      />
      {error && (
        <p className="text-xs font-medium text-[#D65D59]">
          {error.message || "Error"}
        </p>
      )}
    </div>
  );
}

export { TextArea };
