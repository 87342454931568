const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM19.5 21.7929V8.5H20.5V21.7929L25.6464 16.6464L26.3536 17.3536L20.3536 23.3536L20 23.7071L19.6464 23.3536L13.6464 17.3536L14.3536 16.6464L19.5 21.7929ZM10.5 26C10.5 26.8284 11.1716 27.5 12 27.5H14.5455H25.4545H28C28.8284 27.5 29.5 26.8284 29.5 26V23H30.5V26C30.5 27.3807 29.3807 28.5 28 28.5H25.4545H14.5455H12C10.6193 28.5 9.5 27.3807 9.5 26V23H10.5V26Z"
        fill="#E3E3E3"
      />
    </svg>
  );
};

export { DownloadIcon };
