import { Division } from "@/types/users/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useQuery } from "@tanstack/react-query";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseDivisionForAccountQueryReturn {
  data: Division[];
}
type UseDivisionsForAccountProps = {
  accountId: string;
  enabled?: boolean;
};

export const useDivisionsForAccount = ({
  accountId,
  enabled,
}: UseDivisionsForAccountProps) => {
  const { fetchWithAuth } = useAuthContext();
  return useQuery<UseDivisionForAccountQueryReturn, Error>({
    queryKey: [QUERY_KEYS.DIVISIONS_FOR_ACCOUNT, { accountId }],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${usersBaseUrl}/division/${accountId}`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching divisions for account.");
      }

      return apiRes.json();
    },
    enabled,
  });
};
