import { Branch } from "@/types/users/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { PartialBy } from "@/types/utility";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseEditBranchMutationParams {
  body: PartialBy<Omit<Branch, "accountId" | "id">, "logo">;
  branchId: string;
}

export const useEditBranch = () => {
  const { fetchWithAuth } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<Branch, Error, UseEditBranchMutationParams>({
    mutationFn: async ({ body, branchId }) => {
      const apiRes = await fetchWithAuth({
        url: `${usersBaseUrl}/branch/${branchId}`,
        method: "PATCH",
        body: JSON.stringify(body),
      });

      if (!apiRes.ok) {
        throw new Error(`Error editing branch`);
      }

      return apiRes.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.BRANCHES],
        refetchType: "all",
        stale: true,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.BRANCH],
        refetchType: "all",
        stale: true,
      });
    },
  });
};
