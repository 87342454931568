/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { RowField } from "@/types/forms/formEngine";
import { getFormSubmissionValueById } from "@/utils/helpers/forms/formRendererHelpers";
import { Field } from "./FIeld";

export interface RowFieldProps {
  fieldItem: RowField;
  value?: any;
}
export const TotalField: FC<RowFieldProps> = ({ fieldItem, value }) => {
  const startValue = getFormSubmissionValueById(
    fieldItem?.attributes?.startId ?? ""
  );
  const endValue = getFormSubmissionValueById(
    fieldItem?.attributes?.endId ?? ""
  );
  return (
    <Field value={startValue && endValue ? value : "—"} fieldItem={fieldItem} />
  );
};
