import { ColumnDef, Row } from "@tanstack/react-table";
import { Option } from "@/types/option";
import { User } from "@/types/users/general";

const mobileAccess = [
  "timecard:user",
  "mobilehome:user",
  "mobile:user",
  "general:tracker",
];

type Props = {
  branches?: Option[];
  divisions?: Option[];
  occupations: Option[];
};

const customSortingFn = (
  rowA: Row<User>,
  rowB: Row<User>,
  _columnId: string
) => {
  const rowAFirstName = rowA.original.firstName;
  const rowALastName = rowA.original.lastName;
  const rowAFullName = `${rowAFirstName} ${rowALastName}`;

  const rowBFirstName = rowB.original.firstName;
  const rowBLastName = rowB.original.lastName;
  const rowBFullName = `${rowBFirstName} ${rowBLastName}`;

  return rowAFullName.localeCompare(rowBFullName);
};

const selectorFilterFn = (
  row: Row<User>,
  _id: string,
  value: string,
  type: "branch" | "division" | "occupation" | "name"
) => {
  if (!value) return true;

  const {
    original: { branchIds, divisionIds, firstName, lastName, occupations },
  } = row;

  let match: string | undefined;

  switch (type) {
    case "branch":
      match = branchIds?.find((branch) => branch === value);
      break;
    case "division":
      match = divisionIds?.find((division) => division === value);
      break;
    case "occupation":
      match = occupations?.find((occupation) => occupation === value);
      break;
    case "name":
      match = `${firstName} ${lastName}`
        .toLowerCase()
        .includes(value.toLowerCase())
        ? "found"
        : "";
      break;
    default:
      break;
  }

  if (match) return true;

  return false;
};

export const getNewUsersColumnsDefinitions = ({
  branches = [],
  divisions = [],
  occupations = [],
}: Props): ColumnDef<User>[] => {
  return [
    {
      header: "Name",
      accessorKey: "name",
      cell: ({
        row: {
          original: { firstName, lastName },
        },
      }) => {
        const fullName = `${firstName} ${lastName}`;
        const firstNameInitial = firstName[0];
        const lastNameInitial = lastName[0];

        return (
          <div className="flex items-center gap-x-4">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-palette-companyGreen">
              <span className="text-base font-medium text-black">
                {firstNameInitial}
                {lastNameInitial}
              </span>
            </div>

            <span>{fullName}</span>
          </div>
        );
      },
      enableSorting: true,
      filterFn: (row, id, value) => selectorFilterFn(row, id, value, "name"),
      sortingFn: customSortingFn,
    },
    {
      header: "Occupation",
      accessorKey: "occupation",
      cell: ({
        row: {
          original: { occupations: userOccupations },
        },
      }) => {
        const userOccupationsSet = new Set(userOccupations);

        const filterdOccupations = occupations.filter((occupation) =>
          userOccupationsSet.has(occupation.id)
        );

        const occupationNames = filterdOccupations.map(
          (occupation) => occupation.value
        );

        return (
          <p className="line-clamp-2 max-w-[200px] whitespace-normal text-start">
            {occupationNames.join(", ")}
          </p>
        );
      },
      filterFn: (row, id, value) =>
        selectorFilterFn(row, id, value, "occupation"),
      enableSorting: false,
    },
    {
      header: "Division",
      accessorKey: "division",
      cell: ({
        row: {
          original: { divisionIds: userDivisionIds },
        },
      }) => {
        const userDivisionsSet = new Set(userDivisionIds);

        const filterdDivisions = divisions.filter((division) =>
          userDivisionsSet.has(division.id)
        );

        const divisionNames = filterdDivisions.map(
          (division) => division.value
        );

        return (
          <p className="line-clamp-2 max-w-[200px] whitespace-normal text-start">
            {divisionNames.join(", ")}
          </p>
        );
      },
      filterFn: (row, id, value) =>
        selectorFilterFn(row, id, value, "division"),
      enableSorting: false,
    },
    {
      header: "Branch",
      accessorKey: "branch",
      cell: ({
        row: {
          original: { branchIds: userBranchIds },
        },
      }) => {
        const userBranchesSet = new Set(userBranchIds);

        const filterdBranches = branches.filter((branch) =>
          userBranchesSet.has(branch.id)
        );

        const branchNames = filterdBranches.map((branch) => branch.value);

        return (
          <p className="line-clamp-2 max-w-[200px] whitespace-normal text-start">
            {branchNames.join(", ")}
          </p>
        );
      },
      filterFn: (row, id, value) => selectorFilterFn(row, id, value, "branch"),
      enableSorting: false,
    },
    {
      header: "USER TYPE",
      accessorKey: "type",
      cell: ({
        row: {
          original: { permissions },
        },
      }) => {
        let displayString = "";
        const isWebUser = permissions.includes("web:assetmap");
        const isMobileUser = mobileAccess.some((value) =>
          permissions.includes(value)
        );

        if (isMobileUser && isWebUser) {
          displayString = "Mobile + Web";
        } else if (isMobileUser) {
          displayString = "Mobile";
        } else if (isWebUser) {
          displayString = "Web";
        }

        return <span>{displayString}</span>;
      },
      filterFn: () => true,
      enableSorting: false,
    },
  ];
};
