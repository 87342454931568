import { BackArrow } from "@/assets";
import { classNames } from "@/utils/helpers/classNames";
import { handleNavigation } from "@/utils/helpers/handleNavigation";
import { useLocation, useNavigate } from "react-router-dom";

const UsersExpandedContent = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start gap-x-2 rounded-md pl-3"
        )}
        onClick={(event) => {
          handleNavigation({ event, href: "users", navigate, pathname });
        }}
      >
        <BackArrow />

        <span className="text-xs font-medium text-palette-secondaryBlack">
          All Users
        </span>
      </button>

      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/users/user-detail" || pathname === "/users/create"
            ? "bg-palette-secondaryGreen"
            : ""
        )}
      >
        <span
          className={classNames(
            "text-sm font-medium",
            pathname === "/users/user-detail" || pathname === "/users/create"
              ? "text-palette-green"
              : ""
          )}
        >
          Account Details
        </span>
      </button>
    </>
  );
};

export { UsersExpandedContent };
