import { handleNavigation, HandleNavigationParams } from "./handleNavigation";
import { SetStateAction } from "react";

type HandleExpandedNavigationProps = {
  navParams: HandleNavigationParams;
  setDangerModalOpen: (value: SetStateAction<boolean>) => void;
  setHrefToHighlight?: (href: string) => void;
  setNavigationParams: (
    value: SetStateAction<HandleNavigationParams | undefined>
  ) => void;
  showUnsavedDataWarning: boolean;
};

export const handleExpandedNavigation = ({
  navParams,
  setDangerModalOpen,
  setHrefToHighlight,
  setNavigationParams,
  showUnsavedDataWarning,
}: HandleExpandedNavigationProps) => {
  navParams.event.preventDefault();

  if (showUnsavedDataWarning) {
    setNavigationParams(navParams);
    setDangerModalOpen(true);

    return;
  }

  if (setHrefToHighlight) {
    setHrefToHighlight(navParams.href);
  }

  handleNavigation(navParams);
};
