import { AMPLITUDE_EVENTS } from "./amplitudeEvents";
import { LocalStorageCache, LogoutOptions } from "@auth0/auth0-react";
import * as amplitude from "@amplitude/analytics-browser";

export const signOut = (logout: (options?: LogoutOptions) => Promise<void>) => {
  const localStorageCache = new LocalStorageCache();
  sessionStorage.clear();
  localStorage.clear();
  amplitude.setTransport("beacon");
  // Sets https transport to use `sendBeacon` API
  amplitude.track(AMPLITUDE_EVENTS.LOGOUT);
  amplitude.flush();
  localStorageCache.remove("login");
  logout({
    logoutParams: { returnTo: window.location.origin },
  });
};
