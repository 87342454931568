import { RowField } from "@/types/forms/formEngine";
import * as React from "react";

interface NullFieldProps {
  fieldItem: RowField;
}

export const NullField: React.FC<NullFieldProps> = ({ fieldItem }) => {
  return (
    <div
      className={`space-y-1`}
      style={{
        gridColumnStart: fieldItem?.columnStart,
        gridColumnEnd: fieldItem?.columnEnd,
      }}
    >
      —
    </div>
  );
};
