import { classNames } from "@/utils/helpers/classNames";
import { FC, ReactNode, useEffect } from "react";
import { Sidebar } from "../components/Sidebar";
import { useLocation } from "react-router-dom";
import { Fallback } from "@/UI/Fallback";
import { useMobileStoreSelectors } from "@/lib/zustand/mobileStore";

type Props = {
  children: ReactNode;
};

const DefaultLayout: FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const hideSidebar = pathname === "/";
  const map = pathname === "/map";

  const isMobile = useMobileStoreSelectors.use.isMobile();
  const setIsMobile = useMobileStoreSelectors.use.setIsMobile();

  useEffect(() => {
    const mql = window.matchMedia("(max-width: 600px)");
    mql.onchange = (ev) => setIsMobile(ev.matches);

    setIsMobile(mql.matches);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (pathname === "/error") {
    return <Fallback signOut={false} />;
  }

  return (
    <main
      className={classNames(
        "flex h-[100vh] w-[100vw]",
        isMobile ? "" : "min-w-[1140px]"
      )}
    >
      {!hideSidebar && !isMobile && <Sidebar />}

      <div
        id="default-layout"
        className={classNames(
          "box-border flex h-[100%] w-[100%] flex-col items-center",
          map || isMobile ? "" : "px-16 py-4",
          isMobile ? "" : "min-w-[1140px] overflow-auto"
        )}
      >
        {children}
      </div>
    </main>
  );
};

export { DefaultLayout };
