/* eslint-disable */
import * as types from './graphql';



/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation CreatePriceBookItem($input: CreatePriceBookItemInput!) {\n    createPriceBookItem(input: $input) {\n      success\n    }\n  }\n": types.CreatePriceBookItemDocument,
    "\n  mutation DeletePriceBookItem($deletePriceBookItemId: ID!) {\n    deletePriceBookItem(id: $deletePriceBookItemId) {\n      success\n    }\n  }\n": types.DeletePriceBookItemDocument,
    "\n  mutation CreateFormPDFConfig($input: GqlFormPDFConfigInput!) {\n    createFormPDFConfig(input: $input) {\n      code\n      success\n      message\n    }\n  }\n": types.CreateFormPdfConfigDocument,
    "\n  mutation CreateJob($input: CreateJobInput!) {\n    createJob(input: $input) {\n      code\n      success\n      message\n      job {\n        id\n        accountId\n        createdAt\n        updatedAt\n        divisionId\n        branchId\n        address {\n          name\n          formattedAddress\n          lat\n          lng\n        }\n        customer {\n          name\n          email\n          phone\n          type\n          contactName\n        }\n        number\n        notes\n        status\n        name\n        projectOwner\n        salesPerson\n        startDate\n        endDate\n        tasks {\n          id\n          createdAt\n          updatedAt\n          accountId\n          jobId\n          date\n          assets\n          crew\n          active\n        }\n        jobType\n        color {\n          id\n          accountId\n          name\n          code\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n": types.CreateJobDocument,
    "\n  mutation UpdateJob($input: UpdateJobInput!) {\n    updateJob(input: $input) {\n      code\n      success\n      message\n      job {\n        id\n        accountId\n        createdAt\n        updatedAt\n        divisionId\n        branchId\n        address {\n          name\n          formattedAddress\n          lat\n          lng\n        }\n        customer {\n          name\n          email\n          phone\n          type\n          contactName\n        }\n        number\n        notes\n        status\n        name\n        projectOwner\n        salesPerson\n        startDate\n        endDate\n        tasks {\n          id\n          createdAt\n          updatedAt\n          accountId\n          jobId\n          date\n          assets\n          crew\n          active\n        }\n        jobType\n        color {\n          id\n          accountId\n          name\n          code\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n": types.UpdateJobDocument,
    "\n  query Colors($filters: ListColorsInput!) {\n    colors(filters: $filters) {\n      id\n      accountId\n      name\n      code\n      createdAt\n      updatedAt\n    }\n  }\n": types.ColorsDocument,
    "\n  query FormPDFConfig($formId: ID!) {\n    formPDFConfig(formId: $formId) {\n      cssTemplate\n      hbsTemplate\n      fields {\n        fieldId\n        format\n        label\n        fields {\n          fieldId\n          format\n          label\n        }\n      }\n    }\n  }\n": types.FormPdfConfigDocument,
    "\n  query Job($jobId: ID!) {\n    job(id: $jobId) {\n      id\n      accountId\n      createdAt\n      updatedAt\n      divisionId\n      branchId\n      address {\n        name\n        formattedAddress\n        lat\n        lng\n      }\n      customer {\n        name\n        email\n        phone\n        type\n        contactName\n      }\n      number\n      notes\n      status\n      name\n      projectOwner\n      salesPerson\n      startDate\n      endDate\n      tasks {\n        id\n        createdAt\n        updatedAt\n        accountId\n        jobId\n        date\n        assets\n        crew\n        active\n      }\n      jobType\n      color {\n        id\n        accountId\n        name\n        code\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.JobDocument,
    "\n  query Jobs($filters: ListJobsInput!) {\n    jobs(filters: $filters) {\n      id\n      accountId\n      createdAt\n      updatedAt\n      divisionId\n      branchId\n      address {\n        name\n        formattedAddress\n        lat\n        lng\n      }\n      customer {\n        name\n        email\n        phone\n        type\n        contactName\n      }\n      number\n      notes\n      status\n      name\n      projectOwner\n      salesPerson\n      startDate\n      endDate\n      tasks {\n        id\n        createdAt\n        updatedAt\n        accountId\n        jobId\n        date\n        assets\n        crew\n        active\n      }\n      jobType\n      color {\n        id\n        accountId\n        name\n        code\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.JobsDocument,
    "\n  query PriceBookItems($filters: ListPriceBookItemsInput!) {\n    priceBookItems(filters: $filters) {\n      id\n      name\n      description\n      branchIds\n      divisionIds\n      rate\n      unit\n      category\n    }\n  }\n": types.PriceBookItemsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreatePriceBookItem($input: CreatePriceBookItemInput!) {\n    createPriceBookItem(input: $input) {\n      success\n    }\n  }\n"): typeof import('./graphql').CreatePriceBookItemDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeletePriceBookItem($deletePriceBookItemId: ID!) {\n    deletePriceBookItem(id: $deletePriceBookItemId) {\n      success\n    }\n  }\n"): typeof import('./graphql').DeletePriceBookItemDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateFormPDFConfig($input: GqlFormPDFConfigInput!) {\n    createFormPDFConfig(input: $input) {\n      code\n      success\n      message\n    }\n  }\n"): typeof import('./graphql').CreateFormPdfConfigDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateJob($input: CreateJobInput!) {\n    createJob(input: $input) {\n      code\n      success\n      message\n      job {\n        id\n        accountId\n        createdAt\n        updatedAt\n        divisionId\n        branchId\n        address {\n          name\n          formattedAddress\n          lat\n          lng\n        }\n        customer {\n          name\n          email\n          phone\n          type\n          contactName\n        }\n        number\n        notes\n        status\n        name\n        projectOwner\n        salesPerson\n        startDate\n        endDate\n        tasks {\n          id\n          createdAt\n          updatedAt\n          accountId\n          jobId\n          date\n          assets\n          crew\n          active\n        }\n        jobType\n        color {\n          id\n          accountId\n          name\n          code\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').CreateJobDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateJob($input: UpdateJobInput!) {\n    updateJob(input: $input) {\n      code\n      success\n      message\n      job {\n        id\n        accountId\n        createdAt\n        updatedAt\n        divisionId\n        branchId\n        address {\n          name\n          formattedAddress\n          lat\n          lng\n        }\n        customer {\n          name\n          email\n          phone\n          type\n          contactName\n        }\n        number\n        notes\n        status\n        name\n        projectOwner\n        salesPerson\n        startDate\n        endDate\n        tasks {\n          id\n          createdAt\n          updatedAt\n          accountId\n          jobId\n          date\n          assets\n          crew\n          active\n        }\n        jobType\n        color {\n          id\n          accountId\n          name\n          code\n          createdAt\n          updatedAt\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').UpdateJobDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Colors($filters: ListColorsInput!) {\n    colors(filters: $filters) {\n      id\n      accountId\n      name\n      code\n      createdAt\n      updatedAt\n    }\n  }\n"): typeof import('./graphql').ColorsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FormPDFConfig($formId: ID!) {\n    formPDFConfig(formId: $formId) {\n      cssTemplate\n      hbsTemplate\n      fields {\n        fieldId\n        format\n        label\n        fields {\n          fieldId\n          format\n          label\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').FormPdfConfigDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Job($jobId: ID!) {\n    job(id: $jobId) {\n      id\n      accountId\n      createdAt\n      updatedAt\n      divisionId\n      branchId\n      address {\n        name\n        formattedAddress\n        lat\n        lng\n      }\n      customer {\n        name\n        email\n        phone\n        type\n        contactName\n      }\n      number\n      notes\n      status\n      name\n      projectOwner\n      salesPerson\n      startDate\n      endDate\n      tasks {\n        id\n        createdAt\n        updatedAt\n        accountId\n        jobId\n        date\n        assets\n        crew\n        active\n      }\n      jobType\n      color {\n        id\n        accountId\n        name\n        code\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): typeof import('./graphql').JobDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Jobs($filters: ListJobsInput!) {\n    jobs(filters: $filters) {\n      id\n      accountId\n      createdAt\n      updatedAt\n      divisionId\n      branchId\n      address {\n        name\n        formattedAddress\n        lat\n        lng\n      }\n      customer {\n        name\n        email\n        phone\n        type\n        contactName\n      }\n      number\n      notes\n      status\n      name\n      projectOwner\n      salesPerson\n      startDate\n      endDate\n      tasks {\n        id\n        createdAt\n        updatedAt\n        accountId\n        jobId\n        date\n        assets\n        crew\n        active\n      }\n      jobType\n      color {\n        id\n        accountId\n        name\n        code\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): typeof import('./graphql').JobsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PriceBookItems($filters: ListPriceBookItemsInput!) {\n    priceBookItems(filters: $filters) {\n      id\n      name\n      description\n      branchIds\n      divisionIds\n      rate\n      unit\n      category\n    }\n  }\n"): typeof import('./graphql').PriceBookItemsDocument;


export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}
