import * as React from "react";

export const ArrowMoveDown: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <g id="arrow move">
        <rect
          id="Rectangle 333"
          x="12"
          y="11"
          width="5"
          height="10"
          rx="1"
          transform="rotate(-180 12 11)"
          fill="#98999E"
        />
        <path
          id="Polygon 41"
          d="M10.2871 17.9954C9.88677 18.5064 9.11323 18.5064 8.71285 17.9954L2.53957 10.1168C2.0253 9.46043 2.49291 8.5 3.32672 8.5L15.6733 8.5C16.5071 8.5 16.9747 9.46044 16.4604 10.1168L10.2871 17.9954Z"
          fill="#98999E"
        />
      </g>
    </svg>
  );
};
