import { AssetsAsset, Attachment } from "@/types/assetInventory/assets/general";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

type Body = Omit<Attachment, "id">;

interface UseCreateAttachmentMutationParams {
  assetId: string;
  body: Body;
  categoryId: string;
  name: string;
}

interface UseCreateAttachmentMutationReturn {
  data: {
    asset: AssetsAsset;
  };
}

export const useCreateAttachment = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<
    UseCreateAttachmentMutationReturn,
    Error,
    UseCreateAttachmentMutationParams
  >({
    mutationFn: async ({ assetId, body, name }) => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/v2/assets/${assetId}/attachment`,
        method: "POST",
        body: JSON.stringify({ ...body, name }),
      });

      if (!apiRes.ok) {
        throw new Error(`Error creating attachment`);
      }

      return apiRes.json();
    },
  });
};
