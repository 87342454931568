import { classNames } from "@/utils/helpers/classNames";
import { FC } from "react";
import {
  evaluateExpression,
  getFieldsObjectById,
  getTitleClassesByAttributes,
} from "@/utils/helpers/forms/formRendererHelpers";
import { useFormContext } from "react-hook-form";
import { RowField } from "@/types/forms/formEngine";

type EditCheckboxFieldProps = {
  fieldItem: RowField;
};
export const EditCheckboxField: FC<EditCheckboxFieldProps> = ({
  fieldItem,
}) => {
  const { register } = useFormContext();
  const hidden = evaluateExpression(fieldItem?.attributes?.hiddenIf);
  const name = getFieldsObjectById(fieldItem?.id)?.name;

  return (
    <div
      style={{
        gridColumnStart: fieldItem?.columnStart,
      }}
    >
      <label
        htmlFor={name}
        className={classNames(
          "space-x-2",
          hidden && "hidden",
          getTitleClassesByAttributes(fieldItem?.attributes)
        )}
      >
        <input
          className="text-theme-green-primary  outline-none focus:ring-0"
          type="checkbox"
          id={name}
          {...register(fieldItem.id ?? "")}
        />
        <span>{name}</span>
      </label>
    </div>
  );
};
