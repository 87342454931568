import { useMutation } from "@tanstack/react-query";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseDeleteFormTemplateMutationParams {
  formId: string;
}

export const useDeleteFormTemplate = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<void, Error, UseDeleteFormTemplateMutationParams>({
    mutationFn: async ({ formId }) => {
      const apiRes = await fetchWithAuth({
        url: `${usersBaseUrl}/accounts/form/${formId}`,
        method: "DELETE",
      });

      if (!apiRes.ok) {
        throw new Error(`Error deleting form template`);
      }
    },
  });
};
