import { Quotes } from "@/components/Quoting/Quotes";
import * as React from "react";

const QuotingPage: React.FC = () => {
  return (
    <div className="w-[100%] pt-14">
      <Quotes />
    </div>
  );
};

export default QuotingPage;
