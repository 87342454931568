export const FilledSquaresIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g id="Group 1000005055">
        <rect id="Rectangle 2953" width="8" height="8" rx="2" fill="white" />
        <rect
          id="Rectangle 2954"
          x="10.5"
          y="0.5"
          width="7"
          height="7"
          rx="1.5"
          stroke="white"
        />
        <rect
          id="Rectangle 2955"
          x="10.5"
          y="10.5"
          width="7"
          height="7"
          rx="1.5"
          stroke="white"
        />
        <rect
          id="Rectangle 2956"
          x="0.5"
          y="10.5"
          width="7"
          height="7"
          rx="1.5"
          stroke="white"
        />
      </g>
    </svg>
  );
};
