/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { Button } from "@/UI/Button";
import { DangerModal } from "@/UI/Modal";
import { getJobDocumentsColumnsDefinitions } from "@/utils/columnDefinitions/jobDocumentsColumns";
import { Table } from "@/components/Table";
import { toast } from "react-toastify";
import { useCreateJobDocument } from "@/lib/react-query/mutationHooks/useCreateJobDocument";
import { useDeleteJobDocument } from "@/lib/react-query/mutationHooks/useDeleteJobDocument";
import { useJobDocuments } from "@/lib/react-query/queryHooks/useJobDocuments";
import { useLocation } from "react-router-dom";
import { useNotFoundStoreSelectors } from "@/lib/zustand/notFoundStore";
import { useEffect, useState } from "react";
import { useUploadJobDocument } from "@/lib/react-query/mutationHooks/useUploadJobDocument";
import * as amplitude from "@amplitude/analytics-browser";

export default function JobDocuments() {
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    document: "",
    name: "",
    type: "",
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const jobId = searchParams.get("jobId") ?? "";
  const setNotFound = useNotFoundStoreSelectors.use.setNotFound();

  const {
    data: jobDocumentsReturn,
    isLoading: jobDocumentsLoading,
    isFetching: jobDocumentsFetching,
    isRefetching: jobDocumentsRefetching,
  } = useJobDocuments({ jobId });

  const {
    mutateAsync: uploadJobDocumentAsync,
    isPending: uploadDocumentLoading,
  } = useUploadJobDocument();

  const {
    mutateAsync: createJobDocumentAsync,
    isPending: createDocumentLoading,
  } = useCreateJobDocument();

  const {
    mutateAsync: deleteJobDocumentAsync,
    isPending: deleteDocumentLoading,
  } = useDeleteJobDocument();

  const uploadDocument = async (document: {
    data: string | ArrayBuffer | null | undefined;
    mime: string;
    name: string;
    size: number;
    type: string;
  }) => {
    try {
      const res = await uploadJobDocumentAsync({
        document: { data: document.data, mime: document.mime },
        jobId,
      });

      const body = {
        active: true,
        contentType: document.type,
        expiresAt: null,
        isMobileViewable: false,
        name: document.name,
        sizeBytes: document.size,
        thumbnailUrl: "",
        url: res.data.Location,
        version: "1.0",
      };

      try {
        await createJobDocumentAsync({
          body,
          jobId,
        });

        toast.success("Document saved to job.");

        amplitude.track(AMPLITUDE_EVENTS.JOB_DOCUMENT_UPLOADED, {
          document_name: document.name,
          file_type: document.type,
        });
      } catch (error) {
        toast.error("Failed to save document to job.");

        amplitude.track(AMPLITUDE_EVENTS.JOB_DOCUMENT_FAILED, {
          failure_reason: "Failed to save document to job.",
        });
      }
    } catch (error) {
      toast.error(
        "Failure uploading document. File size limit exceeded or network error occurred."
      );

      amplitude.track(AMPLITUDE_EVENTS.JOB_DOCUMENT_FAILED, {
        failure_reason: "Failed to upload document to S3.",
      });
    }
  };

  const selectDocument = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "*";
    input.addEventListener("change", (e) => {
      const selection = (e.target as HTMLInputElement).files?.[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (selection) {
          await uploadDocument({
            data: event.target?.result,
            mime: selection.type,
            name: selection.name,
            size: selection.size,
            type: selection.type,
          });
        }
      };
      reader.readAsDataURL(selection!);
    });
    input.click();

    // Clean up
    input.remove();
  };

  const deleteDocument = async () => {
    try {
      await deleteJobDocumentAsync({ documentId: deleteModal.document, jobId });
      toast.success("Document deleted.");

      amplitude.track(AMPLITUDE_EVENTS.JOB_DOCUMENT_DELETED, {
        document_name: deleteModal.name,
        file_type: deleteModal.type,
      });
    } catch (error) {
      toast.error("Failed to delete document.");

      amplitude.track(AMPLITUDE_EVENTS.JOB_DOCUMENT_FAILED, {
        failure_reason: "Failed to delete document.",
      });
    }

    setDeleteModal({ document: "", open: false, name: "", type: "" });
  };

  const loading =
    jobDocumentsLoading ||
    jobDocumentsFetching ||
    jobDocumentsRefetching ||
    uploadDocumentLoading ||
    createDocumentLoading ||
    deleteDocumentLoading;

  useEffect(() => {
    if (!jobId) {
      setNotFound({ notFound: true, message: "No Job Found" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  return (
    <>
      <div className="h-screen w-full pt-14">
        <header className="mb-5 flex w-full flex-row justify-between">
          <h1 className="text-4xl font-semibold">Job Documents</h1>
          <Button
            className="rounded-[50px]"
            label="Upload Document"
            labelClasses="!text-white"
            onClick={selectDocument}
            variant="primary"
          />
        </header>

        <div className="h-[85vh] w-full overflow-auto rounded-md border bg-white">
          <Table
            emptyMessage="No documents found."
            loading={loading}
            tableColumns={getJobDocumentsColumnsDefinitions({ setDeleteModal })}
            tableData={jobDocumentsReturn?.data || []}
            tdClasses="!p-0"
            thClasses="!uppercase !tracking-[.52] !font-medium !text-[13px] !text-palette-black"
            theadClasses="!bg-[#fafafa]"
          />
        </div>
      </div>

      <DangerModal
        cancelAction={() =>
          setDeleteModal({ document: "", open: false, name: "", type: "" })
        }
        cancelButtonDisabled={loading}
        confirmAction={deleteDocument}
        confirmButtonDisabled={loading}
        message="Are you sure you want to delete this document? This action cannot be undone."
        open={deleteModal.open}
        title="Delete Document"
      />
    </>
  );
}
