import { FC } from "react";

type Props = {
  pathClassName?: string;
  gclassName?: string;
};

const RibbiotIcon: FC<Props> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
    >
      <path
        d="M17.1944 5.30574C17.1944 5.87314 16.6267 6.33243 15.9257 6.33243C15.2247 6.33243 14.6565 5.87314 14.6565 5.30574C14.6565 4.73835 15.2247 4.27905 15.9257 4.27905C16.6267 4.27905 17.1944 4.73887 17.1944 5.30574Z"
        fill="#236341"
      />
      <path
        d="M17.3111 9.85599C22.1676 6.42842 25.9602 6.25862 25.9602 6.25862C23.989 4.95404 22.1385 4.24321 20.4182 3.96162C19.8993 2.80152 18.799 2 17.5208 2C16.6897 2.0005 15.8923 2.34414 15.3012 2.95656C15.7973 2.71106 16.3575 2.64625 16.8927 2.77239C17.428 2.89853 17.9079 3.2084 18.2562 3.6529C18.6045 4.0974 18.8014 4.65109 18.8157 5.22622C18.8299 5.80136 18.6607 6.36503 18.3349 6.82791C18.0091 7.29079 17.5453 7.62639 17.0169 7.78153C16.4886 7.93667 15.9261 7.90247 15.4184 7.68435C14.9107 7.46623 14.487 7.07667 14.2143 6.57741C13.9417 6.07815 13.8357 5.49775 13.9133 4.9282C9.15174 7.19831 6.42426 12.1678 6.42426 12.1678L7.83225 15.9597L2.90327 9.67248C1.05076 14.8096 2.62167 19.9979 2.62167 19.9979H7.28615C4.9695 18.6026 4.50034 16.3067 4.50034 16.3067C6.63244 18.8636 9.87585 19.8408 9.87585 19.8408C10.3898 15.7635 12.7924 13.6711 13.4104 13.2704C14.301 11.8999 15.4802 11.149 17.3111 9.85599Z"
        fill="#236341"
      />
      <path
        d="M20.7813 14.9794C24.8297 11.9695 26.2649 7.74619 25.9607 6.26073C25.9607 6.26073 21.7518 5.98389 16.863 9.364C10.1011 14.0392 11.5011 20 11.5011 20H16.6302C16.6302 20 13.7061 17.2485 15.1719 13.9691C16.6327 10.7029 20.8572 8.73017 22.6051 8.5551C24.0825 8.40745 22.5277 11.1121 21.4053 12.3739C19.3275 14.71 16.8213 16.148 14.8612 16.9616C15.8286 16.9242 17.0048 16.7095 17.832 16.4754C18.7925 19.1679 20.7486 19.9995 20.7486 19.9995H25.9586C25.9586 19.9995 21.9026 17.5011 20.7813 14.9794Z"
        fill="#3CD39C"
      />
    </svg>
  );
};

export { RibbiotIcon };
