import { RowField } from "@/types/forms/formEngine";
import * as React from "react";
import { Field } from "./FIeld";

interface PickerFieldProps {
  fieldItem: RowField;
  value: string;
}

export const PickerField: React.FC<PickerFieldProps> = ({
  fieldItem,
  value,
}) => {
  return <Field key={fieldItem.id} fieldItem={fieldItem} value={value} />;
};
