/* eslint-disable @typescript-eslint/no-explicit-any */

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { classNames } from "@/utils/helpers/classNames";
import { FieldError } from "react-hook-form";
import { ColorOption } from "@/types/option";
import {
  FC,
  ReactNode,
  SelectHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";

type ColorSelectProps = Omit<
  SelectHTMLAttributes<HTMLSelectElement>,
  "value" | "onChange"
> & {
  containerClassName?: string;
  controlledType?: (value: string) => void;
  error?: FieldError | undefined;
  inputClasses?: string;
  label?: string;
  onChange: (...event: any[]) => void;
  option: ColorOption;
  options: ColorOption[];
  selectorContainerClasses?: string;
  showClear?: ReactNode | string;
};

export const ColorSelect: FC<ColorSelectProps> = ({
  containerClassName = "",
  error = false,
  inputClasses = "",
  label,
  onChange,
  option,
  options,
  placeholder = "Select",
  selectorContainerClasses = "",
}) => {
  const [showSelector, setShowSelector] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const onSelect = (option: ColorOption) => {
    if (onChange) {
      onChange(option);
    }
  };

  useEffect(() => {
    const clickListener = (click: MouseEvent) => {
      if (containerRef.current?.contains(click.target as HTMLElement)) {
        setShowSelector((prev) => !prev);
      } else {
        setShowSelector(false);
      }
    };

    const escListener = (keyPressed: KeyboardEvent) => {
      if (keyPressed.key === "Escape") {
        setShowSelector(false);
      }
    };

    window.addEventListener("click", clickListener);
    window.addEventListener("keydown", escListener);
    return () => {
      window.removeEventListener("click", clickListener);
      window.removeEventListener("keydown", escListener);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={classNames(
        "group relative flex w-full flex-col",
        containerClassName
      )}
    >
      {label && (
        <label className={"text-sm font-medium text-[#575757]"}>{label}</label>
      )}

      <div className="relative">
        <div className="absolute left-2 top-[11px]">
          <div
            className="h-[18px] w-[18px] rounded-full"
            style={{ backgroundColor: option.code }}
          />
        </div>

        <input
          id={label}
          aria-label={label}
          className={classNames(
            "h-10 w-full rounded-md border border-[#949494] bg-[#fafdfe] text-[#141c22] placeholder:leading-6 placeholder:text-[#97989e] hover:border-[#393d41]",
            option.name ? "px-[30px]" : "px-[12px]",
            error ? "border-[#D65D59]" : "",
            inputClasses
          )}
          placeholder={placeholder}
          type="text"
          value={option.name}
          readOnly
        />

        <div className="absolute right-1 top-2 flex items-center">
          <div className="flex items-center">
            {showSelector ? (
              <ChevronUpIcon className="h-6 w-6" />
            ) : (
              <ChevronDownIcon className="top-2 h-6 w-6" />
            )}
          </div>
        </div>
      </div>

      {showSelector && (
        <div
          className={classNames(
            "roup absolute top-[4rem] z-10 max-h-[300px] w-full overflow-y-auto rounded-md border border-palette-tertiaryBlack bg-palette-paleBlue",
            selectorContainerClasses
          )}
        >
          <ul>
            {options.map((option) => {
              return (
                <li
                  key={option.id}
                  className="px-1 hover:bg-palette-tertiaryGrey"
                >
                  <button
                    type="button"
                    onClick={() => onSelect(option)}
                    className="flex h-10 w-full cursor-pointer items-center gap-x-1"
                  >
                    <div
                      className="h-[18px] w-[18px] rounded-full"
                      style={{ backgroundColor: option.code }}
                    />

                    <p>{option.name}</p>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
