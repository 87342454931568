/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import "./styles.css";
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { ChangesModal, EditedField } from "./ChangesModal";
import { DangerIcon } from "@/assets";
import { Divider } from "@/UI/Divider";
import { EditFormSubmissionBody } from "@/lib/react-query/mutationHooks/useEditFormSubmission";
import { EditMetadataField } from "./Fields/EditMetadataField";
import { Field } from "./Fields/FIeld";
import { Modal } from "@/UI/Modal";
import { RowField } from "@/types/forms/formEngine";
import { RowItemsContainer } from "./RowItemsContainer";
import { TextArea } from "@/UI/TextArea";
import { useForm, FormProvider } from "react-hook-form";
import { useFormRendererStoreSelectors } from "@/lib/zustand/formRendererStore";
import { useUser } from "@/lib/react-query/queryHooks/useUser";
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  documentDetailQueryToFormMapper,
  formatEditedFieldsForQuery,
  formatTableTypes,
  getDisplayName,
  getLayoutClassesByAttributes,
  getObjectDifferences,
  renderEditFormComponent,
} from "@/utils/helpers/forms/formRendererHelpers";
import * as amplitude from "@amplitude/analytics-browser";

interface EditFormRendererProps {
  submitRef: MutableRefObject<HTMLButtonElement | null>;
  setIsValidFormSubmit: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (params: {
    editFormSubmissionBody: EditFormSubmissionBody;
    changeNotes?: string;
    localDateTime?: string;
  }) => Promise<void>;
  create?: boolean;
  defaultValues?: Record<string, any> | undefined;
}

export const EditFormRenderer: FC<EditFormRendererProps> = ({
  submitRef,
  handleSubmit,
  setIsValidFormSubmit,
  create = false,
  defaultValues,
}) => {
  const [editedFields, setEditedFields] = useState<EditedField[]>([]);
  const [editNotesModalOpen, setEditNotesModalOpen] = useState(false);
  const [editNotesText, setEditNotesText] = useState("");
  const [saveEditDetailsModalOpen, setSaveEditDetailsModalOpen] =
    useState(false);

  const formSubmission = useFormRendererStoreSelectors.use.formSubmission();
  const formObject = useFormRendererStoreSelectors.use.formObject();
  const { data: userData } = useUser({ options: { staleTime: 5 * 1000 * 60 } });

  const formMethods = useForm<Record<string, any>>({
    defaultValues: documentDetailQueryToFormMapper(),
    ...(defaultValues && { values: defaultValues }),
  });

  const valuesWatchTrigger = JSON.stringify(formMethods.watch());

  const getEditedFields = () => {
    const [beforeObject, afterObject] = getObjectDifferences(
      documentDetailQueryToFormMapper(),
      formMethods.getValues()
    );

    const editedFields: EditedField[] = [];

    Object.keys(beforeObject).map((beforeId) => {
      editedFields.push({
        id: beforeId,
        name: getDisplayName(beforeId) ?? "",
        beforeValue: beforeObject[beforeId],
        afterValue: afterObject[beforeId],
      });
    });

    return editedFields;
  };

  const onSubmit = () => {
    if (create) {
      const { fields, localDateTime } = formatEditedFieldsForQuery(
        getEditedFields(),
        formMethods
      );
      const formattedFields = formatTableTypes({
        formValues: formMethods.getValues(),
        queryFields: fields,
      });
      handleSubmit({
        editFormSubmissionBody: { fields: formattedFields },
        localDateTime,
      });
      return;
    }
    setEditedFields(getEditedFields());
    setEditNotesModalOpen(true);
  };

  const onError = (errors: any) => {
    console.error(errors);
  };

  const handleSaveEditDetails = () => {
    setSaveEditDetailsModalOpen(false);

    const { fields, localDateTime } = formatEditedFieldsForQuery(
      editedFields,
      formMethods
    );

    handleSubmit({
      editFormSubmissionBody: { fields },
      changeNotes: editNotesText,
      localDateTime,
    });

    amplitude.track(AMPLITUDE_EVENTS.FORM_EDITED, {
      fields_edited: editedFields.map((editedField) => ({
        name: editedField.name,
        value: editedField.afterValue,
      })),
      form: formSubmission?.data.number,
      edit_notes: editNotesText,
    });
  };

  useEffect(() => {
    if (Object.keys(formMethods.formState.errors).length > 0) {
      setIsValidFormSubmit(false);
      return;
    }
    const dirtyFields = getEditedFields();
    setIsValidFormSubmit(dirtyFields.length > 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    valuesWatchTrigger,
    formMethods.formState.errors,
    formMethods.formState.isDirty,
  ]);

  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(onSubmit, onError)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          {formObject.layouts.web.fields.map(
            (layoutField: any, layoutFieldIndex: number) => {
              switch (layoutField.type) {
                case "Divider":
                  return (
                    <Divider
                      additionalClasses={getLayoutClassesByAttributes(
                        layoutField.attributes
                      )}
                      key={layoutFieldIndex}
                    />
                  );
                case "Row": {
                  return (
                    <RowItemsContainer
                      key={layoutFieldIndex}
                      layoutField={layoutField}
                    >
                      {layoutField.fields.map(
                        (fieldItem: RowField, index: number) => {
                          if (fieldItem?.text) {
                            return (
                              <Field
                                key={"textField" + fieldItem.text + index}
                                fieldItem={fieldItem}
                              />
                            );
                          }

                          if (fieldItem?.source === "metadata") {
                            return (
                              <EditMetadataField
                                key={"metadata" + fieldItem.id + index}
                                fieldItem={fieldItem}
                              />
                            );
                          }

                          if (!fieldItem.id) return null;

                          const EditComponent = renderEditFormComponent(
                            fieldItem.type
                          );

                          return (
                            <EditComponent
                              key={fieldItem.id}
                              fieldItem={fieldItem}
                            />
                          );
                        }
                      )}
                    </RowItemsContainer>
                  );
                }
                default:
              }
            }
          )}
          <button type="submit" className="hidden" ref={submitRef} />
        </form>
      </FormProvider>
      <Modal
        open={editNotesModalOpen}
        innerContainerClasses="!w-[700px] text-2xl font-semibold"
        onCancel={() => setEditNotesModalOpen(false)}
        disableConfirm={editNotesText.length < 1}
        confirmButtonText="Confirm"
        onConfirm={() => {
          setEditNotesModalOpen(false);
          setTimeout(() => {
            setSaveEditDetailsModalOpen(true);
          }, 200);
        }}
      >
        <h1 className="text-3xl font-medium">Reason for Change</h1>

        <div className="my-4 flex w-full content-center justify-center space-x-3 text-theme-black-secondary">
          <i>
            <DangerIcon warning />
          </i>
          <p className="text-base font-normal">
            A description is required for edits - this description will be
            visible to users who have access to this submitted form.
          </p>
        </div>

        <TextArea
          containerClassNames="mt-8 w-full"
          textareaStyles="h-32 font-normal placeholder:text-gray-400 placeholder:font-normal"
          placeholder="Add a description of the changes made..."
          onChange={(e) => setEditNotesText(e.target.value)}
          value={editNotesText}
        />
      </Modal>

      <ChangesModal
        open={saveEditDetailsModalOpen}
        changeText={editNotesText}
        editedFields={editedFields}
        editDate={new Date()}
        username={`${userData?.data?.firstName} ${userData?.data?.lastName[0]}.`}
        onConfirm={handleSaveEditDetails}
        onCancel={() => setSaveEditDetailsModalOpen(false)}
      />
    </>
  );
};
