import { Button } from "@/UI/Button";
import { ControlledSelect } from "@/UI/Select/ControlledSelect";
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import { filterFormDefaultValues } from "@/utils/formDefinitions/settingsUsers/general";
import { Option } from "@/types/option";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const createUserFilterFormSchema = z.object({
  email: z.object({ id: z.string(), value: z.string() }),
  firstName: z.object({ id: z.string(), value: z.string() }),
  branch: z.object({ id: z.string(), value: z.string() }),
});

export type UsersFilterFormSchema = z.infer<typeof createUserFilterFormSchema>;

type UsersFilterProps = {
  ids: Option[];
  names: Option[];
  emails: Option[];
  usersFiltersFormState: UsersFilterFormSchema | undefined;
  setUsersFiltersFormState: Dispatch<
    SetStateAction<UsersFilterFormSchema | undefined>
  >;
  setFilters: Dispatch<
    SetStateAction<
      | {
          id: string;
          value: string;
        }[]
    >
  >;
};

const SettingsUserFilters: FC<UsersFilterProps> = ({
  emails,
  ids,
  names,
  setUsersFiltersFormState,
  usersFiltersFormState,
  setFilters,
}) => {
  const { handleSubmit, control, reset } = useForm<UsersFilterFormSchema>({
    defaultValues: filterFormDefaultValues,
    resolver: zodResolver(createUserFilterFormSchema),
  });

  const onSubmit = (data: UsersFilterFormSchema) => {
    const {
      email: { value: email },
      firstName: { value: firstName },
      branch: { value: branch },
    } = data;

    const filters = [
      ...(email ? [{ id: "email", value: email }] : []),
      ...(firstName ? [{ id: "firstName", value: firstName }] : []),
      ...(branch ? [{ id: "branch", value: branch }] : []),
    ];

    setFilters([...filters]);
    setUsersFiltersFormState(data);
  };

  const onClear = () => {
    reset(filterFormDefaultValues);
    setFilters([]);
    setUsersFiltersFormState(undefined);
  };

  useEffect(() => {
    if (usersFiltersFormState) {
      reset(usersFiltersFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersFiltersFormState]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4 grid grid-cols-1 gap-6">
        <ControlledSelect<UsersFilterFormSchema>
          control={control}
          name="branch"
          options={ids}
          label="Branch"
        />
        <ControlledSelect<UsersFilterFormSchema>
          control={control}
          name="firstName"
          options={names}
          label="Name"
        />
        <ControlledSelect<UsersFilterFormSchema>
          control={control}
          name="email"
          options={emails}
          label="Email"
        />
      </div>
      <div className="flex justify-between">
        <Button
          type="button"
          variant="secondary"
          label="Clear"
          onClick={onClear}
          className="rounded-md"
        />
        <Button
          type="submit"
          variant="primary"
          label="Apply"
          className="rounded-md"
        />
      </div>
    </form>
  );
};

export { SettingsUserFilters };
