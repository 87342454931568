import { BranchSettingsForm } from "@/components/AccountSettings";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const CreateBranchPage = () => {
  return (
    <div className="w-full bg-transparent pt-14">
      <div className="h-screen items-center overflow-y-auto">
        <div>
          <Link
            className="my-4 flex w-fit items-center gap-x-1 text-theme-black-secondary"
            to="/settings"
          >
            <ArrowLeftIcon className="h-6 w-6" />
            <span className="text-xs font-medium">Back to Account</span>
          </Link>
          <h1 className="mb-12 text-4xl font-semibold">Branch Settings</h1>
        </div>
        <BranchSettingsForm branchData={undefined} edit={false} />
      </div>
    </div>
  );
};

export default CreateBranchPage;
