import { useMutation } from "@tanstack/react-query";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

interface UseUploadAssetDocumentMutationParams {
  document: {
    mime: string;
    document: string | ArrayBuffer | null | undefined;
  };
}

interface UseUploadAssetDocumentMutationReturn {
  data: {
    $metadata: {
      httpStatusCode: number;
      requestId: string;
      extendedRequestId: string;
      attempts: number;
      totalRetryDelay: number;
    };
    ETag: string;
    ServerSideEncryption: string;
    Location: string;
    Extension: string;
  };
}

export const useUploadAssetDocument = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<
    UseUploadAssetDocumentMutationReturn,
    Error,
    UseUploadAssetDocumentMutationParams
  >({
    mutationFn: async ({ document }) => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/documents/upload`,
        method: "POST",
        body: JSON.stringify(document),
      });

      if (!apiRes.ok) {
        throw new Error(`Error while uploading Asset Document`);
      }

      return apiRes.json();
    },
  });
};
