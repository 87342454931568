import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { Division } from "@/types/users/general";
import { QUERY_KEYS } from "../constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseEditDivisionMutationParams {
  body: Omit<Division, "branches" | "accountId">;
}

export const useEditDivision = () => {
  const { fetchWithAuth } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<Division, Error, UseEditDivisionMutationParams>({
    mutationFn: async ({ body }) => {
      const apiRes = await fetchWithAuth({
        url: `${usersBaseUrl}/division/${body.id}`,
        method: "PATCH",
        body: JSON.stringify(body),
      });

      if (!apiRes.ok) {
        throw new Error(`Error editing branch`);
      }

      return apiRes.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.DIVISIONS],
        refetchType: "all",
        stale: true,
      });
    },
  });
};
