import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { queryClient } from "../general";
import { QUERY_KEYS } from "../constants";
import { useMutation } from "@tanstack/react-query";

const jobsBaseUrl = import.meta.env.VITE_JOBS_BASE_URL;

export interface UseDeleteDocumentMutationParams {
  documentId: string;
  jobId: string;
}

export const useDeleteJobDocument = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<void, Error, UseDeleteDocumentMutationParams>({
    mutationFn: async ({ documentId, jobId }) => {
      const apiRes = await fetchWithAuth({
        url: `${jobsBaseUrl}/job/${jobId}/document/${documentId}`,
        method: "DELETE",
      });

      if (!apiRes.ok) {
        throw new Error(`Error updating document`);
      }
    },
    onSuccess: (_, variables) => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.JOB_DOCUMENTS, { jobId: variables.jobId }],
        });
      }, 1000);
    },
  });
};
