/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC } from "react";
import {
  SidebarCalendarIcon,
  NotebookIcon,
  QuadrilateralsIcon,
  UserIcon,
  RibbiotIcon,
  MapIcon,
  SettingsIcon,
  QuoteIcon,
} from "../assets";

export type Nav = {
  name: string;
  icon: FC<any>;
  current: boolean;
  href: string;
  expandedRoutes: string[];
};

export const extraSidebarRoutes = [
  "/assets/asset-detail",
  "/assets/asset-documents",
  "/assets/asset-images",
  "/assets/browser",
  "/assets/overview",
  "/assets/search",
  "/jobs/dashboard",
  "/jobs/job-detail",
  "/jobs/job-documents",
  "/settings",
  "/settings/branch/create",
  "/settings/division/create",
  "/settings/forms",
  "/settings/forms/create",
  "/settings/forms/edit-csv-config",
  "/settings/forms/edit-dynamic-document",
  "/settings/forms/edit-mobile-layout",
  "/settings/forms/edit-raw-form",
  "/settings/forms/edit-web-layout",
  "/settings/icons",
  "/settings/location",
  "/settings/users",
  "/settings/users/create",
  "/settings/users/user-detail",
  "/users/create",
  "/users/user-detail",
];

export const navBase = [
  {
    name: "Home",
    href: "",
    icon: RibbiotIcon,
    current: false,
    expandedRoutes: [],
  },
];

export const webAssetMapNavigation = [
  {
    name: "Map",
    icon: MapIcon,
    current: false,
    href: "map",
    expandedRoutes: [
      "/assets/browser",
      "/assets/search",
      "/assets/overview",
      "/assets/asset-images",
      "/assets/asset-documents",
    ],
  },
];

export const webScheduleNavigation = [
  {
    name: "Schedule",
    icon: SidebarCalendarIcon,
    current: false,
    href: "schedule",

    expandedRoutes: [
      "/jobs/job-detail",
      "/jobs/dashboard",
      "/jobs/job-documents",
    ],
  },
];

export const webAssetCrudNavigation = [
  {
    name: "Assets",
    icon: QuadrilateralsIcon,
    current: false,
    href: "assets/browser",

    expandedRoutes: [
      "/assets/browser",
      "/assets/search",
      "/assets/overview",
      "/assets/asset-detail",
      "/assets/asset-images",
      "/assets/asset-documents",
    ],
  },
];

export const webUserCrudNavigation = [
  {
    name: "Users",
    icon: UserIcon,
    current: false,
    href: "users",
    expandedRoutes: ["/users/create", "/users/user-detail"],
  },
];

export const timecardAdminNavigation = [
  {
    name: "Documents",
    icon: NotebookIcon,
    current: false,
    href: "documents",
    expandedRoutes: [],
  },
];

export const webQuotingNavigation = [
  {
    name: "Quoting",
    icon: QuoteIcon,
    current: false,
    href: "quoting",
    expandedRoutes: [],
  },
];

export const settingsNavigation = [
  {
    name: "Settings",
    icon: SettingsIcon,
    current: false,
    href: "settings",
    expandedRoutes: [
      "/settings",
      "/settings/branch/create",
      "/settings/division/create",
      "/settings/forms",
      "/settings/forms/create",
      "/settings/forms/edit-csv-config",
      "/settings/forms/edit-dynamic-document",
      "/settings/forms/edit-mobile-layout",
      "/settings/forms/edit-raw-form",
      "/settings/forms/edit-web-layout",
      "/settings/icons",
      "/settings/location",
      "/settings/pricebook",
      "/settings/pricebook/create",
      "/settings/users",
      "/settings/users/create",
      "/settings/users/user-detail",
    ],
  },
];
