import { NORTH_AMERICA_BOUNDS } from "./constants";

export const isValidLatitude = (latString: string | number) => {
  // Latitude should always be between -90 and 90
  if (!Number(latString)) return false;
  if (Number(latString) < -90 || Number(latString) > 90) {
    return false;
  }
  return true;
};
export const isValidLongitude = (lngString: string | number) => {
  // Longitude should always be between -180 and 180
  if (!Number(lngString)) return false;
  if (Number(lngString) < -180 || Number(lngString) > 180) {
    return false;
  }
  return true;
};

interface LatLngString {
  lat: string | number;
  lng: string | number;
}
export const isValidLatLng = (latLng: LatLngString) => {
  return isValidLatitude(latLng.lat) && isValidLongitude(latLng.lng);
};

export const isWithinNorthAmericaBounds = ({ lat, lng }: LatLngString) => {
  const numberLat = Number(lat);
  const numberLng = Number(lng);
  if (!numberLat || !numberLng) return false;
  const withinLat =
    numberLat >= NORTH_AMERICA_BOUNDS.south &&
    numberLat <= NORTH_AMERICA_BOUNDS.north;
  let withinLng = false;

  if (NORTH_AMERICA_BOUNDS.west < NORTH_AMERICA_BOUNDS.east) {
    withinLng =
      numberLng >= NORTH_AMERICA_BOUNDS.west &&
      numberLng <= NORTH_AMERICA_BOUNDS.east;
  } else {
    withinLng =
      numberLng >= NORTH_AMERICA_BOUNDS.west ||
      numberLng <= NORTH_AMERICA_BOUNDS.east;
  }

  return withinLat && withinLng;
};
