import { ChangeEvent, MouseEventHandler, SelectHTMLAttributes } from "react";
import { Option } from "../../types/option";
import { capitalizeFirstLetter } from "../../utils/helpers/capitalize";
import { FieldError } from "react-hook-form";
import { classNames } from "@/utils/helpers/classNames";

interface SelectProps
  extends Omit<
    SelectHTMLAttributes<HTMLSelectElement | HTMLSelectElement>,
    "onChange" | "value"
  > {
  capitalize?: boolean;
  containerClassNames?: string;
  error?: FieldError;
  label?: string;
  onChange?: (_selectedOption: Option | undefined) => void;
  optionClassNames?: string;
  options: Option[];
  placeholder?: string;
  selectClassNames?: string;
  value?: Option;
  onClick?: MouseEventHandler<HTMLSelectElement> | undefined;
  hidePlaceholder?: boolean;
}

function Select({
  capitalize = false,
  containerClassNames,
  className = "",
  disabled,
  error,
  label,
  onBlur,
  onChange,
  optionClassNames,
  options,
  placeholder = "Select",
  selectClassNames,
  value,
  onClick,
  hidePlaceholder,
}: SelectProps) {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    let selectedOption;
    const value = event.target.value;

    if (value === placeholder) {
      selectedOption = { id: "", value: "" };
    } else {
      selectedOption =
        options.find((option) => option.id === value) || undefined;
    }

    if (onChange) {
      onChange(selectedOption);
    }
  };

  return (
    <div
      className={classNames("relative flex min-w-[152px] flex-col", className)}
    >
      {label && (
        <label
          className={classNames(
            "text-sm font-medium",
            disabled ? "text-[#97989e]" : "text-[#575757]"
          )}
          htmlFor={label}
        >
          {label}
        </label>
      )}

      <div className={classNames("relative", containerClassNames)}>
        <select
          id={label}
          aria-label={label}
          className={classNames(
            "h-10 w-full rounded-md border border-[#949494] bg-[#fafdfe] text-[#141c22] placeholder:leading-6 placeholder:text-[#97989e] hover:border-[#393d41]",
            disabled
              ? "border-[#ebebeb] bg-[#ecf2f2] text-[#97999f] hover:!border-[#ebebeb]"
              : "",
            error ? "border-[#D65D59]" : "",
            selectClassNames,
            !value?.id && !hidePlaceholder ? "!text-[#919399]" : ""
          )}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          value={value?.id || (value as unknown as string)}
          onClick={onClick}
        >
          {!hidePlaceholder && (
            <option
              value={placeholder}
              className={classNames(
                "text-md hidden font-medium !text-[#919399]"
              )}
            >
              {placeholder}
            </option>
          )}

          {options.map((option) => (
            <option
              value={option.id}
              key={option.id}
              className={classNames(optionClassNames)}
            >
              {capitalize ? capitalizeFirstLetter(option.value) : option.value}
            </option>
          ))}
        </select>
      </div>

      {error && (
        <p className="text-xs font-medium text-[#D65D59]">
          {error.message || "Error"}
        </p>
      )}
    </div>
  );
}

export { Select };
