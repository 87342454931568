export const RibbiotMobileLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="136"
      height="42"
      viewBox="0 0 136 42"
      fill="none"
    >
      <path
        d="M34.5792 9.1696C34.5792 10.3389 33.3473 11.288 31.8299 11.288C30.3125 11.288 29.0807 10.3389 29.0807 9.1696C29.0807 8.00029 30.3098 7.04852 31.8299 7.04852C33.35 7.04852 34.5792 7.99757 34.5792 9.1696Z"
        fill="#236341"
      />
      <path
        d="M34.832 18.5703C45.353 11.481 53.5708 11.1356 53.5708 11.1356C49.2988 8.44079 45.2878 6.97236 41.5623 6.39042C41.0107 5.18744 40.1269 4.16702 39.0151 3.44923C37.9032 2.73143 36.6095 2.34612 35.2861 2.33862C33.4869 2.33972 31.7603 3.04777 30.4783 4.31014C31.5143 3.83012 32.678 3.699 33.7949 3.93642C34.9117 4.17385 35.9214 4.76703 36.6725 5.62696C37.4237 6.48689 37.8757 7.56721 37.9608 8.70582C38.0459 9.84443 37.7595 10.9799 37.1446 11.942C36.5296 12.904 35.6193 13.6407 34.5502 14.0416C33.4811 14.4424 32.3108 14.4857 31.215 14.1651C30.1191 13.8444 29.1568 13.177 28.4724 12.2631C27.788 11.3492 27.4184 10.2379 27.4191 9.09615C27.4211 8.85781 27.4384 8.61983 27.4707 8.38369C17.1563 13.08 11.2445 23.34 11.2445 23.34L14.301 31.1771L3.61409 18.1923C-0.399636 28.7977 3.00224 39.5254 3.00224 39.5254H13.11C8.0901 36.6429 7.07036 31.895 7.07036 31.895C11.6932 37.1814 18.7254 39.1964 18.7254 39.1964C19.8512 30.7665 25.0424 26.4509 26.3776 25.6242C28.3137 22.7934 30.8645 21.2488 34.832 18.5703Z"
        fill="#236341"
      />
      <path
        d="M42.3509 29.1512C51.1234 22.9321 54.2316 14.2058 53.5708 11.1357C53.5708 11.1357 44.4502 10.5646 33.8584 17.5478C19.2067 27.2096 22.2415 39.5254 22.2415 39.5254H33.3499C33.3499 39.5254 27.0112 33.8393 30.1846 27.0628C33.3581 20.2862 42.5032 16.2398 46.2912 15.8782C49.4918 15.5736 46.1226 21.1618 43.6915 23.7642C39.1883 28.591 33.7578 31.5633 29.5102 33.2465C31.6855 33.133 33.8433 32.7958 35.9496 32.2404C38.0299 37.8041 42.2693 39.5227 42.2693 39.5227H53.5708C53.5708 39.5227 44.7792 34.3669 42.3509 29.1512Z"
        fill="#3CD39C"
      />
      <path
        d="M55.1697 24.0851C54.9332 23.8791 54.7469 23.6219 54.6248 23.333C54.5028 23.0442 54.4483 22.7313 54.4654 22.4181C54.4473 22.1082 54.5001 21.7982 54.6197 21.5117C54.7394 21.2252 54.9229 20.9697 55.1561 20.7648C55.6114 20.3833 56.1946 20.1889 56.7877 20.2209C57.3573 20.234 57.9037 20.4489 58.3296 20.8273C58.5515 21.0281 58.726 21.2757 58.8403 21.5523C58.9546 21.8289 59.0059 22.1274 58.9904 22.4263C59.009 22.7291 58.9607 23.0323 58.8488 23.3143C58.737 23.5963 58.5643 23.8502 58.3432 24.0579C57.9072 24.4423 57.3479 24.6578 56.7667 24.6652C56.1855 24.6727 55.6209 24.4717 55.1751 24.0987L55.1697 24.0851ZM58.6559 26.4156V39.5309H54.7972V26.4156H58.6559Z"
        fill="#3CD39C"
      />
      <path
        d="M62.2101 38.9245V20.2427H66.0416V26.7446C67.0189 26.3329 68.0676 26.1176 69.1281 26.111C69.9831 26.0757 70.8355 26.2279 71.6256 26.5567C72.4157 26.8855 73.1244 27.383 73.702 28.0145C74.8966 29.3107 75.494 30.9967 75.494 33.0725C75.5224 34.1922 75.3101 35.305 74.8713 36.3357C74.5082 37.1939 73.9177 37.9367 73.1635 38.4839C72.4342 39.0014 71.6183 39.3845 70.7542 39.6152C69.7999 39.8634 68.8169 39.9841 67.8309 39.9741C65.9113 39.9461 64.0105 39.5912 62.2101 38.9245ZM65.9573 29.9153V36.5287C66.7097 36.745 67.4886 36.8558 68.2715 36.8578C70.5158 36.8233 71.6371 35.5652 71.6353 33.0833C71.6353 31.9249 71.3361 31.0021 70.7379 30.3151C70.4585 29.982 70.1111 29.7126 69.719 29.525C69.3269 29.3374 68.8991 29.2359 68.4645 29.2273C67.58 29.2109 66.7095 29.4497 65.9573 29.9153Z"
        fill="#3CD39C"
      />
      <path
        d="M78.276 38.9245V20.2427H82.1075V26.7446C83.0848 26.3329 84.1335 26.1176 85.194 26.111C86.049 26.0757 86.9014 26.2279 87.6915 26.5567C88.4816 26.8855 89.1903 27.383 89.7679 28.0145C90.9608 29.3107 91.5581 30.9967 91.5599 33.0725C91.5878 34.1919 91.3764 35.3044 90.9399 36.3357C90.5749 37.1935 89.9837 37.936 89.2295 38.4839C88.5001 39.0014 87.6842 39.3845 86.8201 39.6152C85.8658 39.8634 84.8828 39.9841 83.8968 39.9741C81.9772 39.9461 80.0764 39.5912 78.276 38.9245ZM82.0232 29.9153V36.5287C82.7766 36.7452 83.5563 36.8559 84.3401 36.8578C86.5808 36.8233 87.7012 35.5652 87.7012 33.0833C87.7012 31.9249 87.4021 31.0021 86.8038 30.3151C86.5244 29.982 86.177 29.7126 85.7849 29.525C85.3928 29.3374 84.9651 29.2359 84.5305 29.2273C83.646 29.2109 82.7755 29.4497 82.0232 29.9153Z"
        fill="#3CD39C"
      />
      <path
        d="M100.213 36.2514H102.932V39.5309H93.3493V36.2514H96.0686V23.4379H93.5587V20.2427H102.709V23.4379H100.202L100.213 36.2514Z"
        fill="#3CD39C"
      />
      <path
        d="M117.081 27.6828C118.484 28.9155 119.186 30.6378 119.188 32.8495C119.19 35.0612 118.497 36.7744 117.108 37.989C115.757 39.1963 114 39.8506 112.189 39.8218C110.36 39.8515 108.587 39.198 107.215 37.989C105.81 36.7689 105.107 35.0558 105.105 32.8495C105.105 30.674 105.794 28.9745 107.171 27.7508C108.541 26.5522 110.296 25.8871 112.116 25.8771C113.936 25.8671 115.698 26.5129 117.081 27.6964V27.6828ZM109.902 30.19C109.256 30.8154 108.935 31.6974 108.936 32.8359C108.938 33.9744 109.251 34.8609 109.874 35.4954C110.169 35.7947 110.521 36.0327 110.908 36.1955C111.295 36.3584 111.711 36.4429 112.131 36.4441C112.552 36.4454 112.968 36.3634 113.356 36.2029C113.745 36.0424 114.097 35.8066 114.394 35.509C115.036 34.8636 115.357 33.9726 115.357 32.8359C115.357 31.6992 115.045 30.8218 114.421 30.2036C114.135 29.8997 113.788 29.6584 113.404 29.4946C113.02 29.3308 112.606 29.2482 112.189 29.2518C111.764 29.2466 111.343 29.327 110.95 29.4882C110.557 29.6494 110.2 29.8881 109.902 30.19Z"
        fill="#236341"
      />
      <path
        d="M125.19 23.824H120.023V20.2427H134.3V23.824H129.185V39.5309H125.19V23.824Z"
        fill="#3CD39C"
      />
      <path
        d="M115.572 24.6181C114.662 23.7092 113.429 23.1987 112.144 23.1987C110.858 23.1987 109.625 23.7092 108.716 24.6181L107.745 23.65C108.323 23.0722 109.009 22.6139 109.763 22.3012C110.518 21.9885 111.327 21.8275 112.144 21.8275C112.961 21.8275 113.77 21.9885 114.524 22.3012C115.279 22.6139 115.965 23.0722 116.542 23.65L115.572 24.6181Z"
        fill="#236341"
      />
      <path
        d="M117.603 22.6085C116.156 21.1616 114.193 20.3488 112.147 20.3488C110.1 20.3488 108.138 21.1616 106.69 22.6085L105.722 21.6404C106.566 20.7964 107.567 20.1268 108.67 19.67C109.772 19.2132 110.953 18.9781 112.147 18.9781C113.34 18.9781 114.521 19.2132 115.624 19.67C116.726 20.1268 117.728 20.7964 118.571 21.6404L117.603 22.6085Z"
        fill="#236341"
      />
    </svg>
  );
};
