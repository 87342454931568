import * as React from "react";

export const ArrowMoveUp: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <g id="arrow move">
        <rect
          id="Rectangle 333"
          x="7"
          y="8"
          width="5"
          height="10"
          rx="1"
          fill="#98999E"
        />
        <path
          id="Polygon 41"
          d="M8.71285 1.00459C9.11323 0.493615 9.88677 0.493616 10.2871 1.00459L16.4604 8.88323C16.9747 9.53957 16.5071 10.5 15.6733 10.5H3.32672C2.49291 10.5 2.02531 9.53956 2.53957 8.88323L8.71285 1.00459Z"
          fill="#98999E"
        />
      </g>
    </svg>
  );
};
