/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button } from "@/UI/Button";
import { CircledXIcon, PinIcon } from "@/assets";
import * as amplitude from "@amplitude/analytics-browser";
import { DangerModal } from "@/UI/Modal";
import { QUERY_KEYS } from "@/lib/react-query/constants";
import { queryClient } from "@/lib/react-query/general";
import { useDeleteAttachment } from "@/lib/react-query/mutationHooks/useDeleteAttachment";
import { useAsset } from "@/lib/react-query/queryHooks/useAsset";
import { useNotFoundStoreSelectors } from "@/lib/zustand/notFoundStore";
import { AssetsAsset } from "@/types/assetInventory/assets/general";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { useCreateAttachment } from "@/lib/react-query/mutationHooks/useCreateAttachment";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { classNames } from "@/utils/helpers/classNames";
import { useUpdateAttachment } from "@/lib/react-query/mutationHooks/useUpdateAttachment";
import { resizeFile } from "@/utils/helpers/imageResizer";
import { useUploadAssetImg } from "@/lib/react-query/mutationHooks/useUploadAssetImg";
import { AssetImageModal } from "@/components/AssetInventory/Assets/AssetOverview/AssetImageModal";
import { LoadingSpinner } from "@/UI/Loading";

export const AssetImagesPage = () => {
  const navigate = useNavigate();
  const { hasAssetCrud, hasRibbiotAdmin } = useAuthContext();
  const location = useLocation();
  const [imageModal, setImageModal] = useState<{
    open: boolean;
    initialIndex?: number;
  }>({ open: false, initialIndex: 0 });
  const searchParams = new URLSearchParams(location.search);
  const assetId = searchParams.get("assetId") ?? "";
  const setNotFound = useNotFoundStoreSelectors.use.setNotFound();

  const [deleteImgModal, setDeleteImgModal] = useState<{
    open: boolean;
    id: string;
    index: number;
  }>({ open: false, id: "", index: 0 });

  const {
    data: useAssetData,
    isError,
    isLoading: assetLoading,
  } = useAsset({
    assetId,
    options: { staleTime: 5 * 1000 * 60 },
  });
  if (!assetId) navigate("/assets/browser");

  useEffect(() => {
    if (isError) {
      setNotFound({ notFound: true, message: "No Asset Found" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const assetData = useAssetData?.asset;
  const {
    mutateAsync: createAttachmentAsync,
    isPending: createAttachmentLoading,
  } = useCreateAttachment();

  const {
    mutateAsync: deleteAttachmentAsync,
    isPending: deleteAttachmentLoading,
  } = useDeleteAttachment();

  const deleteAttachment = async (attachmentId: string, index?: number) => {
    if (!assetData) return;
    setDeleteImgModal({ id: "", index: 0, open: false });
    try {
      const {
        data: { asset: updatedAsset },
      } = await deleteAttachmentAsync({
        assetId: assetData.id,
        attachmentId,
        attachmentIndex: index,
        categoryId: assetData.categoryId,
      });

      toast.success("Attachment deleted.");

      await handleCacheProcess(updatedAsset);
    } catch (error) {
      toast.error("Failed to delete attachment.");
    }
  };

  const handleCacheProcess = async (asset: AssetsAsset) => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.ASSET, { assetId: asset.id }],
      type: "all",
    });
  };
  const images = assetData?.attachments.filter((attachment) =>
    attachment.url.split("/").includes("images")
  );

  const {
    mutateAsync: updateAttachmentAsync,
    isPending: updateAttachmentLoading,
  } = useUpdateAttachment();

  const updateAttachment = async (attachmentId: string, index: number) => {
    if (!assetData) return;
    const updatedAttachments = assetData.attachments.map((attachment) => {
      if (attachment.id === attachmentId) {
        return {
          ...attachment,
          primary: true,
        };
      }
      return {
        ...attachment,
        primary: false,
      };
    });

    try {
      const {
        data: { asset: updatedAsset },
      } = await updateAttachmentAsync({
        assetId: assetData.id,
        attachmentIndex: index,
        body: { attachments: updatedAttachments },
        categoryId: assetData.categoryId,
      });

      toast.success("Attachment updated.");

      await handleCacheProcess(updatedAsset);
    } catch (error) {
      toast.error("Failed to update attachment.");
    }
  };

  const { mutateAsync: uploadAssetImgAsync, isPending: uploadAssetImgLoading } =
    useUploadAssetImg();

  const uploadImage = async (img: {
    mime: string;
    image: string | ArrayBuffer | null | undefined;
    name: string;
  }) => {
    try {
      const data = await uploadAssetImgAsync({ img });

      if (assetData) {
        const {
          data: { asset: updatedAsset },
        } = await createAttachmentAsync({
          assetId: assetData.id,
          body: {
            url: data.Location,
            contentType: data.Location.split(".").at(-1) || "",
            primary: false,
          },
          categoryId: assetData.categoryId,
          name: img.name,
        });

        toast.success("Image added to attachments.");

        await handleCacheProcess(updatedAsset);

        amplitude.track(AMPLITUDE_EVENTS.IMAGE_ADDED, {
          asset_name: assetData.assetName,
        });
      }
    } catch (error) {
      toast.error("Failed to upload image.");
    }
  };

  const selectImg = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.addEventListener("change", async (e) => {
      const selection = (e.target as HTMLInputElement).files?.[0];
      if (!selection) return;
      const reader = new FileReader();
      const twoMegabytes = 2000000;
      const imageURL = await resizeFile({
        file: selection,
        maxSize: twoMegabytes,
      });
      reader.readAsDataURL(selection!);
      reader.onload = async () => {
        await uploadImage({
          mime: selection?.type || "",
          image: imageURL,
          name: selection?.name ?? "",
        });
      };
    });
    input.click();
    // Clean up
    input.remove();
  };

  const loading =
    uploadAssetImgLoading ||
    createAttachmentLoading ||
    deleteAttachmentLoading ||
    updateAttachmentLoading;

  return (
    <div className="h-screen w-full pt-14">
      <header className="mb-8 flex w-full justify-between">
        <h1 className="text-4xl font-semibold">Asset Images</h1>
        {(hasAssetCrud || hasRibbiotAdmin) && (
          <Button
            className="!rounded-full "
            label="+ Add New Image"
            onClick={selectImg}
            disabled={loading}
            loading={loading}
            variant="primary"
          />
        )}
      </header>

      <div className="flex h-[calc(100%-72px)] w-full gap-6 overflow-auto rounded-md border bg-white p-10">
        {assetLoading && <LoadingSpinner />}
        {(images?.length ?? 0) < 1 && !assetLoading && (
          <p className="mt-10 w-full text-center">No asset images found.</p>
        )}
        {images?.map((attachment, index) => {
          return (
            <div
              key={attachment.id}
              className={classNames(
                "group relative !h-[180px] !w-[180px] rounded-xl bg-theme-blue-primary "
              )}
            >
              <button
                type="button"
                onClick={() =>
                  setImageModal({ open: true, initialIndex: index })
                }
                className={classNames(
                  "flex !h-[180px] !w-[180px] items-center justify-center rounded-xl bg-[#EDF1F3]"
                )}
                id="img-container"
                style={{
                  backgroundImage: `url(${attachment.url})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></button>

              {(hasAssetCrud || hasRibbiotAdmin) && (
                <div className="">
                  <button
                    className="absolute left-2 top-2 h-7 w-7 opacity-0 group-hover:opacity-100"
                    onClick={() => {
                      updateAttachment(attachment.id, index);
                    }}
                    type="button"
                  >
                    <PinIcon fill={attachment.primary ? "#3CD38B" : "none"} />
                  </button>

                  <button
                    className="absolute right-2 top-2 opacity-0 group-hover:opacity-100"
                    type="button"
                    onClick={() =>
                      setDeleteImgModal({
                        id: attachment.id,
                        index,
                        open: true,
                      })
                    }
                  >
                    <CircledXIcon />
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <DangerModal
        cancelAction={() =>
          setDeleteImgModal({ id: "", index: 0, open: false })
        }
        cancelButtonDisabled={deleteAttachmentLoading}
        confirmAction={() =>
          deleteAttachment(deleteImgModal.id, deleteImgModal.index)
        }
        confirmButtonDisabled={deleteAttachmentLoading}
        message="Are you sure you want to delete this image? This action cannot be undone."
        open={deleteImgModal.open}
        title="Delete Image"
      />
      <AssetImageModal
        imageModal={imageModal}
        setImageModal={setImageModal}
        images={images as any}
      />
    </div>
  );
};
