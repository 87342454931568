import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { JobForm } from "@/components/JobManagement/JobForm";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useColors } from "@/lib/react-query/queryHooks/useColors";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { useUsers } from "@/lib/react-query/queryHooks/useUsers";

export default function JobPage() {
  const { accountId } = useAuthContext();
  const { data: useBranchesData } = useBranches();
  const { data: useDivisionsData } = useDivisions();
  const { data: useUsersData } = useUsers();
  const { data: useColorsData } = useColors({ accountId });

  return (
    <JobForm
      branches={useBranchesData?.data}
      colorsData={useColorsData?.data}
      divisions={useDivisionsData?.data}
      edit={false}
      job={undefined}
      users={useUsersData?.data}
    />
  );
}
