import { FC, ReactNode } from "react";
import { useAuthContext } from "../Auth/AuthWrapper";
import { LDProvider } from "launchdarkly-react-client-sdk";

interface LDWrapperProps {
  children: ReactNode;
}

export const LDWrapper: FC<LDWrapperProps> = ({ children }) => {
  const { accountId, ribbiotId } = useAuthContext();
  if (!accountId || !ribbiotId) return children;

  return (
    <>
      <LDProvider
        clientSideID={import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID}
        context={{
          kind: "user",
          key: ribbiotId,
          account_id: accountId,
        }}
      >
        {children}
      </LDProvider>
    </>
  );
};
