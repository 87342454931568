import { FC } from "react";
import { classNames } from "../../utils/helpers/classNames";

type DividerProps = {
  additionalClasses?: string;
};

export const Divider: FC<DividerProps> = ({ additionalClasses }) => {
  return <div className={classNames(additionalClasses ?? "", "border-t")} />;
};
