import { useMutation } from "@tanstack/react-query";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { AssetsAsset } from "@/types/assetInventory/assets/general";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

interface UseDeleteAttachmentMutationParams {
  assetId: string;
  attachmentId: string;
  attachmentIndex?: number;
  categoryId: string;
}

interface UseDeletettachmentMutationReturn {
  data: {
    asset: AssetsAsset;
  };
}

export const useDeleteAttachment = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<
    UseDeletettachmentMutationReturn,
    Error,
    UseDeleteAttachmentMutationParams
  >({
    mutationFn: async ({ assetId, attachmentId }) => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/v2/assets/${assetId}/attachment/${attachmentId}`,
        method: "DELETE",
      });

      if (!apiRes.ok) {
        throw new Error(`Error deleting attachment.`);
      }

      return apiRes.json();
    },
  });
};
