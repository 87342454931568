import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";

export const timeDifference = (lastSeen: Date | string | null | undefined) => {
  if (!lastSeen) return "";

  const currDate = new Date();

  const daysDifference = differenceInDays(currDate, new Date(lastSeen));
  const hoursDifference = differenceInHours(currDate, new Date(lastSeen));
  const minutesDifference = differenceInMinutes(currDate, new Date(lastSeen));

  if (daysDifference) {
    if (daysDifference === 1) {
      return `${daysDifference} day ago`;
    } else {
      return `${daysDifference} days ago`;
    }
  }

  if (hoursDifference) {
    if (hoursDifference === 1) {
      return `${hoursDifference} hour ago`;
    } else {
      return `${hoursDifference} hours ago`;
    }
  }

  if (minutesDifference) {
    if (minutesDifference === 1) {
      return `${minutesDifference} minute ago`;
    } else {
      return `${minutesDifference} minutes ago`;
    }
  }

  return "Less than a minute ago";
};
