import * as React from "react";

export const LoadingSpinner: React.FC = () => {
  return (
    <div className="absolute bottom-1/2 right-1/2 z-10 translate-x-1/2 translate-y-1/2 transform ">
      <svg className="spinner">
        <clipPath id="clip">
          <path
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 39C30.9411 39 39 30.9411 39 21C39 11.0589 30.9411 3 21 3C11.0589 3 3 11.0589 3 21C3 30.9411 11.0589 39 21 39ZM21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
            fill="white"
          />
        </clipPath>
        <foreignObject width="42" height="42" clipPath="url(#clip)">
          <div />
        </foreignObject>
      </svg>
    </div>
  );
};
