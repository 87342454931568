import { BackArrow } from "@/assets";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useAsset } from "@/lib/react-query/queryHooks/useAsset";
import { classNames } from "@/utils/helpers/classNames";
import { useLocation, useNavigate } from "react-router-dom";

const AssetsExpandedContent = () => {
  const navigate = useNavigate();
  const { hasAssetCrud, hasRibbiotAdmin } = useAuthContext();
  const { pathname } = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const assetId = searchParams.get("assetId") ?? "";
  const { data: useAssetData } = useAsset({
    assetId,
    options: { staleTime: 5 * 1000 * 60 },
  });

  if (assetId) {
    return (
      <>
        <button
          className={classNames(
            "flex h-10 w-[149px] items-center justify-start gap-x-2 rounded-md pl-3"
          )}
          onClick={() =>
            navigate("/assets/browser", {
              state: { asset: useAssetData?.asset },
            })
          }
        >
          <BackArrow />
          <span className="text-xs font-medium text-palette-secondaryBlack">
            All Assets
          </span>
        </button>
        <button
          className={classNames(
            "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
            pathname === "/assets/overview" ? "bg-palette-secondaryGreen" : ""
          )}
          onClick={() => navigate(`/assets/overview?assetId=${assetId}`)}
        >
          <span className="text-sm font-medium">Overview</span>
        </button>
        {(hasAssetCrud || hasRibbiotAdmin) && (
          <button
            className={classNames(
              "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
              pathname === "/assets/asset-detail"
                ? "bg-palette-secondaryGreen"
                : ""
            )}
            onClick={() => navigate(`/assets/asset-detail?assetId=${assetId}`)}
          >
            <span className="text-sm font-medium">Asset Detail</span>
          </button>
        )}
        <button
          className={classNames(
            "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
            pathname === "/assets/asset-images"
              ? "bg-palette-secondaryGreen"
              : ""
          )}
          onClick={() => navigate(`/assets/asset-images?assetId=${assetId}`)}
        >
          <span className="text-sm font-medium">Asset Images</span>
        </button>
        <button
          className={classNames(
            "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
            pathname === "/assets/asset-documents"
              ? "bg-palette-secondaryGreen"
              : ""
          )}
          onClick={() => navigate(`/assets/asset-documents?assetId=${assetId}`)}
        >
          <span className="text-sm font-medium">Asset Documents</span>
        </button>
      </>
    );
  }
  return (
    <>
      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/assets/browser" ? "bg-palette-secondaryGreen" : ""
        )}
        onClick={() => navigate("/assets/browser")}
      >
        <span className="text-sm font-medium">Asset Browser</span>
      </button>
      <button
        className={classNames(
          "flex h-10 w-[149px] items-center justify-start rounded-md pl-3",
          pathname === "/assets/search" ? "bg-palette-secondaryGreen" : ""
        )}
        onClick={() => navigate("/assets/search")}
      >
        <span className="text-sm font-medium">Asset Search</span>
      </button>
    </>
  );
};

export { AssetsExpandedContent };
