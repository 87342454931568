const RightArrow = () => {
  return (
    <svg
      id="right-arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
    >
      <path d="M1 17L9 9L1 0.999999" stroke="#2E3A59" strokeWidth="2" />
    </svg>
  );
};

export { RightArrow };
