import { classNames } from "@/utils/helpers/classNames";
import { FC } from "react";
import {
  evaluateExpression,
  getFieldsObjectById,
  getTitleClassesByAttributes,
} from "@/utils/helpers/forms/formRendererHelpers";
import { RowField } from "@/types/forms/formEngine";

type CheckboxFieldProps = {
  fieldItem: RowField;
  value: string;
};
export const CheckboxField: FC<CheckboxFieldProps> = ({ fieldItem, value }) => {
  const convertedValue = !value || value === "false" ? false : true;
  const hidden = evaluateExpression(fieldItem?.attributes?.hiddenIf);
  const name = getFieldsObjectById(fieldItem?.id)?.name;
  return (
    <div
      style={{
        gridColumnStart: fieldItem?.columnStart,
      }}
    >
      <label
        htmlFor={name}
        className={classNames(
          "space-x-2",
          hidden && "hidden",
          getTitleClassesByAttributes(fieldItem?.attributes)
        )}
      >
        <input
          className={` bg-inherit hover:text-theme-green-primary ${
            convertedValue
              ? "checked:bg-theme-green-primary "
              : "border-2 border-theme-black-secondary"
          }`}
          type="checkbox"
          id={name}
          name={name}
          checked={convertedValue}
          disabled
        />
        <span>{name}</span>
      </label>
    </div>
  );
};
