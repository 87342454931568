import { ColumnDef } from "@tanstack/react-table";
import { Division } from "../../types/users/general";
import { NavigateFunction } from "react-router-dom";

export const getAccountDivisionSettingsTableColumns = (props: {
  navigate: NavigateFunction;
}): ColumnDef<Division>[] => {
  const { navigate } = props;
  return [
    {
      header: "Division Name",
      accessorKey: "divisionName",
      cell: ({
        row: {
          original: { divisionName, id },
        },
      }) => {
        return (
          <button
            onClick={() =>
              navigate(`/settings/division/detail?divisionId=${id}`)
            }
          >
            <span>{divisionName}</span>
          </button>
        );
      },
      enableSorting: false,
    },
    {
      header: "ID",
      accessorKey: "id",
      cell: ({
        row: {
          original: { id },
        },
      }) => <span>{id}</span>,
      enableSorting: false,
    },
  ];
};
