/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";
import { createSelectors } from "./createSelectors";
import { FormSubmission } from "@/types/forms/general";
import { getDisplayMap } from "@/utils/helpers/forms/formRendererHelpers";
export type DisplayMap = {
  id: string;
  displayName: string;
  endText?: string;
}[];
type FormRendererStore = {
  formSubmission: FormSubmission | undefined;
  setFormSubmission: (formSubmission: FormSubmission | undefined) => void;
  formObject: any;
  setFormObject: (formObject: any) => void;
  displayMap: DisplayMap | undefined;
  clear: () => void;
};
export const useFormRendererStore = create<FormRendererStore>((set) => ({
  formSubmission: undefined,
  setFormSubmission: (formSubmission) => {
    set({ formSubmission });
    set({ displayMap: getDisplayMap() });
  },
  formObject: undefined,
  setFormObject: (formObject) => {
    set({ formObject });
  },
  displayMap: undefined,
  clear: () =>
    set({
      formSubmission: undefined,
      formObject: undefined,
      displayMap: undefined,
    }),
}));
export const useFormRendererStoreSelectors =
  createSelectors(useFormRendererStore);
