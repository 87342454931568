/* eslint-disable @typescript-eslint/no-explicit-any */

import { Categories } from "@/types/assetInventory/categories/general";
import { QUERY_KEYS } from "../constants";
import { useQuery } from "@tanstack/react-query";
import {
  FetchWithAuthProps,
  useAuthContext,
} from "../../../components/Auth/AuthWrapper";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

export const getCategories = async (
  fetchFn: (params: FetchWithAuthProps) => Promise<any>
) => {
  const apiRes = await fetchFn({
    url: `${assetsInventoryBaseUrl}/categories/""`,
  });

  if (!apiRes.ok) {
    throw new Error("Error fetching categories");
  }

  return apiRes.json() as Categories;
};

export const useCategories = () => {
  const { fetchWithAuth } = useAuthContext();

  return useQuery<Categories, Error>({
    queryKey: [QUERY_KEYS.CATEGORIES],
    queryFn: () => getCategories(fetchWithAuth),
    gcTime: 720 * (60 * 1000),
    staleTime: 720 * (60 * 1000),
  });
};
