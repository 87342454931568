import { useEffect } from "react";
import { useAsset } from "../lib/react-query/queryHooks/useAsset";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotFoundStoreSelectors } from "@/lib/zustand/notFoundStore";
import { LoadingSpinner } from "@/UI/Loading";
import { AssetOverview } from "@/components/AssetInventory/Assets/AssetOverview";

export default function AssetOverviewPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const assetId = searchParams.get("assetId") ?? "";
  const setNotFound = useNotFoundStoreSelectors.use.setNotFound();
  const { data: useAssetData, isError } = useAsset({
    assetId,
    options: { staleTime: 5 * 1000 * 60 },
  });
  if (!assetId) navigate("/assets/browser");

  useEffect(() => {
    if (isError) {
      setNotFound({ notFound: true, message: "No Asset Found" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const assetData = useAssetData?.asset;

  return (
    <>
      {assetData && assetId ? (
        <AssetOverview assetData={assetData} />
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
}
