export const HamburgerMenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M7 9.5H35"
        stroke="#686868"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M7 19.5H35"
        stroke="#686868"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M7 29.5H35"
        stroke="#686868"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};
