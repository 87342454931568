/* eslint-disable @typescript-eslint/no-explicit-any */
import { MouseEvent as ReactMouseEvent } from "react";
import { NavigateFunction } from "react-router-dom";

export type HandleNavigationParams = {
  event: ReactMouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>;
  href: string;
  navigate: NavigateFunction;
  pathname: string;
  skipValidation?: boolean;
  state?: any;
};

export const handleNavigation = (params: HandleNavigationParams) => {
  const { event, href, navigate, pathname, skipValidation, state } = params;
  event.preventDefault();

  const path = `${window.location.origin}/${href}`;

  if (
    !skipValidation &&
    (pathname === "/map" ||
      pathname === "/jobs/dashboard" ||
      pathname === "/jobs/job-detail")
  ) {
    window.location.assign(path);
  } else {
    navigate(href, { state });
  }
};
