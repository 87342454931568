/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { CameraIcon, CircledXIcon } from "@/assets";
import { FC, useEffect, useRef, useState } from "react";
import { getFormSubmissionValueById } from "@/utils/helpers/forms/formRendererHelpers";
import { Modal } from "@/UI/Modal";
import { MultiImageFieldValue, RowField } from "@/types/forms/formEngine";
import { resizeFile } from "@/utils/helpers/imageResizer";
import { toast } from "react-toastify";
import { useFormContext } from "react-hook-form";
import { useUploadFormImg } from "@/lib/react-query/mutationHooks/useUploadFormImg";
import { downloadFile } from "@/utils/helpers/downloadFile";

type Props = {
  fieldItem: RowField;
};

export const EditMultiImageField: FC<Props> = ({ fieldItem }) => {
  const [modal, setModal] = useState({
    open: false,
    url: "",
  });
  const [value, setValue] = useState<MultiImageFieldValue[] | undefined | []>(
    []
  );

  const imageRef = useRef<HTMLImageElement>(null);

  const formContext = useFormContext();

  const { mutateAsync } = useUploadFormImg();

  const uploadImage = async (file: {
    mime: string;
    image: string | ArrayBuffer | null | undefined;
    name: string;
  }) => {
    try {
      const res = await mutateAsync({ image: file.image, mime: file.mime });
      const newFormImgValue = { url: res.data, contentType: file.mime };

      setValue((prev) => {
        if (prev) {
          return [...prev, newFormImgValue];
        }

        return [newFormImgValue];
      });

      if (fieldItem.id) {
        const oldValue = formContext.getValues(fieldItem.id);

        formContext.setValue(fieldItem.id, [...oldValue, newFormImgValue], {
          shouldDirty: true,
        });
      }
    } catch (error) {
      toast.error("Failed to upload image.");
    }
  };

  const selectImg = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.addEventListener("change", async (e) => {
      const selection = (e.target as HTMLInputElement).files?.[0];

      if (!selection) return;

      const reader = new FileReader();
      const twoMegabytes = 2000000;
      const imageURL = await resizeFile({
        file: selection,
        maxSize: twoMegabytes,
      });

      reader.readAsDataURL(selection!);

      reader.onload = async () => {
        await uploadImage({
          mime: selection?.type || "",
          image: imageURL,
          name: selection?.name ?? "",
        });
      };
    });

    input.click();

    // Clean up
    input.remove();
  };

  const removeImg = (idx: number) => {
    const clonedValue = structuredClone(value);

    if (clonedValue) {
      const remainingValue = clonedValue.toSpliced(idx, 1);
      setValue(remainingValue);

      if (fieldItem.id) {
        formContext.setValue(fieldItem.id, remainingValue, {
          shouldDirty: true,
        });
      }
    }
  };

  useEffect(() => {
    const currValue = getFormSubmissionValueById(fieldItem?.id) as
      | MultiImageFieldValue[]
      | undefined
      | [];

    setValue(currValue);
  }, [fieldItem?.id]);

  if (!value || !value.length) {
    return <></>;
  }

  return (
    <>
      <div className="flex flex-wrap gap-6">
        <button
          className={
            "flex h-[152px] w-[152px] flex-col items-center justify-center gap-y-1 rounded-md border bg-[#fafdfe] visited:border-red-400"
          }
          onClick={selectImg}
          type="button"
        >
          <CameraIcon />
          <span>Add Photo</span>
        </button>

        {value.map((item, idx) => (
          <div key={idx} className="group relative">
            <button
              className={
                "flex h-[152px] w-[152px] items-center justify-center rounded-md border bg-[#fafdfe] visited:border-red-400"
              }
              onClick={() =>
                setModal({
                  open: true,
                  url: item.url,
                })
              }
              style={{
                backgroundImage: `url(${item.url})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
              type="button"
            ></button>

            <button
              type="button"
              className="absolute right-0 top-0 hidden group-hover:block"
              onClick={() => removeImg(idx)}
            >
              <CircledXIcon />
            </button>
          </div>
        ))}
      </div>

      <Modal
        download
        downloadAction={() => {
          if (!imageRef.current) return;

          downloadFile({
            extension: imageRef.current.src.split(".").at(-1) || "jpeg",
            file: imageRef.current.src,
            fileName: "quote-image",
          });
        }}
        hideButtons
        innerContainerClasses="!h-fit !w-fit !max-w-none !p-0 !bg-[#181818] !shadow-none border"
        onCancel={() => {
          setModal({ open: false, url: "" });
        }}
        open={modal.open}
        topClose
        topCloseButtonClasses="!bg-black !h-9 !w-9 !border-4 !text-base"
        topCloseContainerClasses="!-top-[10px] !-right-[17px]"
      >
        <img
          ref={imageRef}
          src={modal.url}
          alt="formImage"
          style={{
            width: "50vw",
            height: "50vw",
            borderRadius: "8px",
            objectFit: "contain",
          }}
        />
      </Modal>
    </>
  );
};
