export enum QUERY_KEYS {
  ACCOUNT = "account",
  ACCOUNTS = "accounts",
  ASSET = "asset",
  ASSET_HISTORY = "assetHistory",
  ASSETS = "assets",
  BASE_CATEGORIES = "baseCategories",
  BRANCH = "branch",
  BRANCHES = "branches",
  BULK_EXPORT_TIMECARDS_PDFS = "bulkExportTimecardsPDFs",
  CATEGORIES = "categories",
  CATEGORY = "category",
  CATEGORY_CHILDREN = "categoryChildren",
  COLORS = "colors",
  CSV_CONFIG = "csvConfig",
  DIVISION = "division",
  DIVISIONS = "divisions",
  DIVISIONS_FOR_ACCOUNT = "divisionsForAccount",
  DOCUMENT_PDF = "documentPDF",
  EDIT_FORM_SUBMISSION_HISTORY = "editFormSubmissionHistory",
  EXPORT_TIMECARDS_CSV = "exportTimecardsCSV",
  FORM = "form",
  FORM_PDF_CONFIG = "formPDFConfig",
  FORM_SUBMISSION = "formSubmission",
  FORM_SUBMISSIONS = "formSubmissions",
  GET_FILTERED_ASSETS = "getFilteredAssets",
  GOOGLE_MAP_KEY = "googleMapKey",
  GQL_JOB = "GQLJob",
  GQL_JOBS = "GQLJobs",
  JOB = "job",
  JOB_DOCUMENTS = "jobDocuments",
  JOBS = "jobs",
  MANUFACTURERS = "manufacturers",
  PRICEBOOK_ITEMS = "pricebookItems",
  SCHEDULE_ASSETS_FOR_JOB = "scheduleAssetsForJob",
  USE_FILTERED_ASSETS = "useFilteredAssets",
  USER = "user",
  USERS = "users",
}

export enum MUTATION_KEYS {
  CREATE_JOB = "createJob",
  UPDATE_JOB = "updateJob",
  UPDATE_JOB_DOCUMENT = "updateJobDocument",
  UPDATE_PDF_CONFIG = "updatePDFConfig",
}
