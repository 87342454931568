import * as React from "react";
import { classNames } from "@/utils/helpers/classNames";
import {
  getTitleClassesByAttributes,
  transformTitleByFieldItem,
} from "@/utils/helpers/forms/formRendererHelpers";
import { MultiUserFieldValue, RowField } from "@/types/forms/formEngine";
import { useDocumentDetailHelpers } from "@/utils/hooks/useDocumentDetailHelpers";

interface MultiUserFieldProps {
  value: MultiUserFieldValue[];
  fieldItem: RowField;
}

export const MultiUserField: React.FC<MultiUserFieldProps> = ({
  value,
  fieldItem,
}) => {
  const title = transformTitleByFieldItem(fieldItem);
  const { getUserNameById, getOccupationNameById } = useDocumentDetailHelpers();
  const usersList: string[] = value?.map((user: MultiUserFieldValue) => {
    const userName = getUserNameById(user.userId);
    const occupationName = getOccupationNameById(user.occupationId);
    if (!userName || occupationName === "") return "";
    if (userName && !occupationName) return userName;
    return `${userName} (${occupationName})`;
  });
  if (usersList.length < 1) return null;
  return (
    <div>
      {title && (
        <h4
          className={classNames(
            "text-sm font-medium text-theme-black-secondary",
            getTitleClassesByAttributes(fieldItem?.attributes)
          )}
        >
          {title}
        </h4>
      )}
      {usersList?.map((user, index) => (
        <p key={index} className={classNames("flex space-x-2 text-base")}>
          {user}
        </p>
      ))}
    </div>
  );
};
