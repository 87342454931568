import { RowField } from "@/types/forms/formEngine";
import * as React from "react";
import { Field } from "./FIeld";
import { useDocumentDetailHelpers } from "@/utils/hooks/useDocumentDetailHelpers";
interface DivisionFieldProps {
  fieldItem: RowField;
  value: string;
}

export const DivisionField: React.FC<DivisionFieldProps> = ({
  fieldItem,
  value,
}) => {
  const { getDivisionNameById } = useDocumentDetailHelpers();
  const divisionName = getDivisionNameById(value);

  return <Field fieldItem={fieldItem} value={divisionName} />;
};
