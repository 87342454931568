import { JobDocument } from "@/types/jobManagementService/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useQuery } from "@tanstack/react-query";

const jobsBaseUrl = import.meta.env.VITE_JOBS_BASE_URL;

interface UseJobDocumentsQueryParams {
  jobId?: string;
}

export interface UseJobDocumentsQueryReturn {
  data: JobDocument[];
}

export const useJobDocuments = (params: UseJobDocumentsQueryParams) => {
  const { fetchWithAuth } = useAuthContext();
  const { jobId } = params;

  return useQuery<UseJobDocumentsQueryReturn, Error>({
    queryKey: [QUERY_KEYS.JOB_DOCUMENTS, { ...params }],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${jobsBaseUrl}/job/${jobId}/documents`,
      });

      if (!apiRes.ok) {
        throw new Error("Error getting Job Documents");
      }

      return apiRes.json();
    },
  });
};
