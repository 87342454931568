import * as React from "react";

interface PagesIconProps {
  fill?: string;
}

export const PagesIcon: React.FC<PagesIconProps> = ({ fill = "#3B84DC" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g id="Frame 925">
        <path
          id="Vector"
          d="M17.5 13.8438H11.5C11.225 13.8438 11 13.6328 11 13.375V5.875C11 5.61719 11.225 5.40625 11.5 5.40625H15.8781L18 7.39551V13.375C18 13.6328 17.775 13.8438 17.5 13.8438ZM11.5 15.25H17.5C18.6031 15.25 19.5 14.4092 19.5 13.375V7.39551C19.5 7.02344 19.3406 6.66602 19.0594 6.40234L16.9406 4.41309C16.6594 4.14941 16.2781 4 15.8813 4H11.5C10.3969 4 9.5 4.84082 9.5 5.875V13.375C9.5 14.4092 10.3969 15.25 11.5 15.25ZM7.5 7.75C6.39688 7.75 5.5 8.59082 5.5 9.625V17.125C5.5 18.1592 6.39688 19 7.5 19H13.5C14.6031 19 15.5 18.1592 15.5 17.125V16.1875H14V17.125C14 17.3828 13.775 17.5938 13.5 17.5938H7.5C7.225 17.5938 7 17.3828 7 17.125V9.625C7 9.36719 7.225 9.15625 7.5 9.15625H8.5V7.75H7.5Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
