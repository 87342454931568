import { classNames } from "@/utils/helpers/classNames";
import { InputHTMLAttributes } from "react";
import { FieldError } from "react-hook-form";

interface CheckBoxProps<_T extends object>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "required"> {
  error?: FieldError | undefined;
  id?: string;
  label?: string;
  sideText?: string;
}

function CheckBox<T extends object>({
  checked,
  className,
  disabled,
  error,
  id,
  label,
  onChange,
  sideText = "",
}: CheckBoxProps<T>) {
  return (
    <div className="flex flex-col">
      {label && (
        <label
          className="text-sm font-semibold text-theme-black-secondary"
          htmlFor={label}
        >
          {label}
        </label>
      )}

      <div className="flex items-center gap-x-3">
        <input
          id={label ? label : id}
          checked={checked}
          onChange={onChange}
          className={classNames(
            `h-4 w-4 rounded-sm border-2 border-palette-secondaryBlack bg-white
            placeholder:text-base placeholder:font-medium placeholder:leading-5
            checked:bg-theme-green-primary hover:cursor-pointer
            hover:text-theme-green-primary focus:text-theme-green-primary
            focus:ring-transparent disabled:bg-[#c3c5c6] disabled:hover:cursor-default disabled:hover:bg-[#c3c5c6]`,
            error ? "border-theme-red-primary" : "",
            className
          )}
          disabled={disabled}
          type="checkbox"
        />

        {sideText && (
          <label htmlFor={id} className="text-sm font-medium text-[#575757]">
            {sideText}
          </label>
        )}
      </div>

      {error && (
        <p className="text-[12px] font-medium text-theme-red-primary">
          {error.message || "Error"}
        </p>
      )}
    </div>
  );
}

export { CheckBox };
