import { classNames } from "@/utils/helpers/classNames";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ControlledTextArea } from "@/UI/TextArea";
import { RowField } from "@/types/forms/formEngine";
import {
  evaluateExpression,
  transformTitleByFieldItem,
} from "@/utils/helpers/forms/formRendererHelpers";

type RowEditFieldProps = {
  fieldItem: RowField;
};
export const EditTextAreaField: FC<RowEditFieldProps> = ({ fieldItem }) => {
  const addButton = fieldItem?.attributes?.addButton;
  const { control, getValues } = useFormContext();
  const [textAreaOpen, setTextAreaOpen] = useState<boolean>(
    getValues(`${fieldItem?.id}`) !== ""
  );

  const title = transformTitleByFieldItem(fieldItem);

  const hidden = evaluateExpression(fieldItem?.attributes?.hiddenIf);

  return (
    <div
      className={`space-y-1 ${hidden ? "hidden" : ""}`}
      style={{
        gridColumnStart: fieldItem?.columnStart,
      }}
    >
      {textAreaOpen || !addButton ? (
        <>
          <h4
            className={classNames(
              "text-sm font-medium text-theme-black-secondary"
            )}
          >
            {title}
          </h4>
          <ControlledTextArea
            textareaStyles="h-20"
            control={control}
            name={`${fieldItem?.id}`}
          />
        </>
      ) : (
        <button
          type="button"
          onClick={() => setTextAreaOpen(true)}
          className="text-theme-green-primary"
        >
          + Add {title}
        </button>
      )}
    </div>
  );
};
