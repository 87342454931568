/* eslint-disable @typescript-eslint/no-explicit-any */
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";

const timecardsBaseUrl = import.meta.env.VITE_TIMECARDS_BASE_URL;

export interface UseCSVConfigQueryResult {
  data: Record<string, any>;
}

interface UseCSVConfigQueryParams {
  formId: string;
  options?: Partial<DefinedInitialDataOptions<UseCSVConfigQueryResult>>;
}

export const useCSVConfig = (params: UseCSVConfigQueryParams) => {
  const { fetchWithAuth } = useAuthContext();
  const { formId, options } = params;

  return useQuery<UseCSVConfigQueryResult, Error>({
    queryKey: [QUERY_KEYS.CSV_CONFIG, { formId }],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${timecardsBaseUrl}/forms/${formId}/csvconfig`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching csv config");
      }

      return apiRes.json();
    },
    ...(options && { ...options }),
  });
};
