import { create } from "zustand";
import { createSelectors } from "./createSelectors";
import { ColumnFiltersState } from "@tanstack/react-table";
import { sub } from "date-fns";

type DateRange = [Date | null, Date | null];
type DocumentStore = {
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  selectedDocumentId: string | undefined;
  setSelectedDocumentId: (selectedDocumentId?: string) => void;
  clientFormColumnFiltersState: ColumnFiltersState;
  setClientFormColumnFiltersState: (
    clientFormColumnFiltersState: ColumnFiltersState
  ) => void;
  serverFormColumnFiltersState: Record<string, string | undefined>;
  setServerFormColumnFiltersState: (
    serverFormColumnFiltersState: Record<string, string | undefined>
  ) => void;
  serverFormColumnOrderState: Record<string, string | undefined>;
  setServerFormColumnOrderState: (
    serverFormColumnOrderState: Record<string, string | undefined>
  ) => void;
};
export const useDocumentStore = create<DocumentStore>((set) => ({
  dateRange: [sub(new Date(), { days: 7 }), new Date()],
  setDateRange: (dateRange) => {
    set({ dateRange });
  },
  selectedDocumentId: undefined,
  setSelectedDocumentId: (selectedDocumentId) => {
    set({ selectedDocumentId });
  },
  clientFormColumnFiltersState: [],
  setClientFormColumnFiltersState: (clientFormColumnFiltersState) => {
    set({ clientFormColumnFiltersState });
  },
  serverFormColumnFiltersState: {},
  setServerFormColumnFiltersState: (serverFormColumnFiltersState) => {
    set({ serverFormColumnFiltersState });
  },
  serverFormColumnOrderState: {},
  setServerFormColumnOrderState: (serverFormColumnOrderState) => {
    set({ serverFormColumnOrderState });
  },
}));
export const useDocumentStoreSelectors = createSelectors(useDocumentStore);
