import { FC } from "react";
import { classNames } from "@/utils/helpers/classNames";
import { getTitleClassesByAttributes } from "@/utils/helpers/forms/formRendererHelpers";
import { RowField } from "@/types/forms/formEngine";

type AdderFieldProps = {
  value: string;
  fieldItem: RowField;
};
export const AdderField: FC<AdderFieldProps> = ({ fieldItem, value }) => {
  return (
    <div
      className={classNames(
        `flex space-x-3 `,
        getTitleClassesByAttributes(fieldItem.attributes)
      )}
      style={{
        gridColumnStart: fieldItem.columnStart,
      }}
    >
      <p>{fieldItem.attributes?.title}</p>
      <p>{value}</p>
    </div>
  );
};
