import { Account } from "../../../types/users/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

export interface UseAccountQueryResult {
  data: Account;
}

interface UseAccountQueryParams {
  accountId?: string;
  options?: Partial<DefinedInitialDataOptions<UseAccountQueryResult>>;
}

export const useAccount = (params?: UseAccountQueryParams) => {
  const { fetchWithAuth, accountId: contextAccountId } = useAuthContext();
  const accountId = params?.accountId ?? contextAccountId;
  const options = params?.options;

  return useQuery<UseAccountQueryResult, Error>({
    queryKey: [QUERY_KEYS.ACCOUNT, { accountId }],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${usersBaseUrl}/accounts/${accountId}`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching account");
      }

      return apiRes.json();
    },
    ...(options && { ...options }),
  });
};
