import { ControlledDatePicker } from "@/UI/DatePicker";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { classNames } from "@/utils/helpers/classNames";
import { CalendarIcon } from "@heroicons/react/20/solid";
import { RowField } from "@/types/forms/formEngine";
import { transformTitleByFieldItem } from "@/utils/helpers/forms/formRendererHelpers";

type EditDateFieldProps = {
  fieldItem: RowField;
};

export const EditDateField: React.FC<EditDateFieldProps> = ({ fieldItem }) => {
  const { control } = useFormContext();
  const title = transformTitleByFieldItem(fieldItem);
  return (
    <div
      className="relative mr-4 space-y-1"
      style={{
        gridColumnStart: fieldItem?.columnStart,
      }}
    >
      <h4
        className={classNames("text-sm font-medium text-theme-black-secondary")}
      >
        {title}
      </h4>
      <ControlledDatePicker
        name={fieldItem?.path ?? fieldItem.id ?? ""}
        control={control}
      />
      <div className="pointer-events-none absolute right-2 top-7">
        <CalendarIcon />
      </div>
    </div>
  );
};
