import { useQuoteStoreSelectors } from "@/lib/zustand/quoteStore";
import { DynamicPageField } from "@/types/forms/general";
import * as React from "react";
import { useEffect, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { Button } from "@/UI/Button";
import { quoteStatusOptions } from "@/UI/StatusSelector";
import { Select } from "@/UI/Select";
import { Input } from "@/UI/Input";

interface ServerQuotingFiltersProps {
  filters: DynamicPageField[];
}

export const ServerQuotingFilters: React.FC<ServerQuotingFiltersProps> = ({
  filters,
}) => {
  const serverQuoteFormColumnFiltersState =
    useQuoteStoreSelectors.use.serverQuoteFormColumnFiltersState();
  const setServerQuoteFormColumnFiltersState =
    useQuoteStoreSelectors.use.setServerQuoteFormColumnFiltersState();

  const [localFiltersState, setLocalFiltersState] = useState<
    Record<string, any>
  >({});

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const cleanedFilters = Object.entries(localFiltersState)
      .filter(([_key, value]) => !!value)
      .reduce((obj: Record<string, any>, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
    setServerQuoteFormColumnFiltersState(cleanedFilters);
    amplitude.track(AMPLITUDE_EVENTS.FORM_FILTERED, {
      filters: Object.keys(cleanedFilters),
    });
  };

  useEffect(() => {
    setLocalFiltersState(serverQuoteFormColumnFiltersState);
    return () => setLocalFiltersState({});
  }, []);

  return (
    <form onSubmit={onSubmit} className="w-[500px]">
      <div id="quote-filters" className="grid grid-cols-2 gap-4">
        {filters.map((filterItem, index) => {
          if (filterItem.type === "TextField") {
            return (
              <Input
                key={filterItem.id ?? index}
                label={filterItem.name}
                value={localFiltersState[filterItem.id as string] ?? ""}
                onChange={(newValue) => {
                  setLocalFiltersState({
                    ...localFiltersState,
                    [filterItem.id as string]: newValue.target.value,
                  });
                }}
              />
            );
          }
          if (filterItem.type === "StatusSelector") {
            const options = structuredClone(quoteStatusOptions);
            options.unshift({ id: "5", value: "All" });
            const value = options.find((option) => {
              return localFiltersState["status"]?.includes(
                option.value.toLocaleLowerCase()
              );
            });
            return (
              <Select
                key={filterItem.id ?? index}
                label={filterItem.name}
                capitalize
                options={options}
                hidePlaceholder
                value={value ?? options[0]}
                onChange={(newValue) => {
                  if (!newValue?.value) return;
                  if (newValue.value == "All") {
                    setLocalFiltersState({
                      ...localFiltersState,
                      status: undefined,
                    });
                    return;
                  }
                  setLocalFiltersState({
                    ...localFiltersState,
                    status: newValue.value.toLocaleLowerCase(),
                  });
                }}
              />
            );
          }
        })}
      </div>
      <div className="flex w-full justify-between">
        <Button
          label="Clear"
          type="button"
          variant="secondary"
          className="mt-4 rounded-md"
          onClick={() => {
            setLocalFiltersState({});
            setServerQuoteFormColumnFiltersState({});
          }}
        />
        <Button
          label="Apply"
          variant="primary"
          type="submit"
          className="mt-4 rounded-md"
        />
      </div>
    </form>
  );
};
