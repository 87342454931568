import { Branch, Division } from "@/types/users/general";
import { getAccountSettingsTableColumns } from "@/utils/columnDefinitions/settingsBranchesColumns";
import { Table } from "@/components/Table";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useNavigate } from "react-router-dom";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { getAccountDivisionSettingsTableColumns } from "@/utils/columnDefinitions/settingsDivisionsColumns";
import { LoadingSpinner } from "@/UI/Loading";
import { useEffect, useState } from "react";

const AccountSettingsPage = () => {
  const { data: branches, isLoading: branchesLoading } = useBranches();
  const { data: divisions, isLoading: divisionsLoading } = useDivisions();
  const [throwError] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (throwError) {
      throw new Error("Test datadog error.");
    }
  }, [throwError]);

  if (branchesLoading || divisionsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="w-full bg-transparent">
      <div className="flex h-[100vh] flex-col gap-y-12">
        <span className="absolute right-1 top-0">
          APP VERSION={import.meta.env.VITE_VERSION}
        </span>
        <header className="mt-12 flex flex-col gap-y-4 text-4xl font-semibold">
          <h1 className="text-4xl font-semibold">Account Settings</h1>
        </header>

        <div className="flex h-[70%] flex-col gap-y-6 2xl:h-[80%]">
          <div className="h-1/2 bg-white">
            <Table<Division>
              emptyMessage="No Divisions found."
              globalFilter={""}
              tableColumns={getAccountDivisionSettingsTableColumns({
                navigate,
              })}
              tableData={divisions?.data || []}
            />
          </div>

          <div className="h-1/2 overflow-auto bg-white">
            <Table<Branch>
              emptyMessage="No Branch found."
              globalFilter={""}
              tableColumns={getAccountSettingsTableColumns({ navigate })}
              tableData={branches?.data || []}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsPage;
