import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";

const testEnv = import.meta.env.VITE_TESTING ?? "";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <>
    {testEnv ? (
      <App />
    ) : (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    )}
  </>
);
