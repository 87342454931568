import { downloadFile } from "./downloadFile";

export const downloadImage = async (prop: {
  extension: string;
  fileName: string;
  url: string;
}) => {
  const res = await fetch(prop.url, {
    method: "GET",
    headers: {
      "Cache-Control": "no-cache, no-store, must-revalidate",
    },
  });

  const blob = await res.blob();

  downloadFile({
    extension: prop.extension,
    file: blob,
    fileName: prop.fileName,
  });
};
