/* eslint-disable @typescript-eslint/no-explicit-any */
import { isAfter, isBefore, sub } from "date-fns";
import { ColorOption, Option } from "@/types/option";
import { UseFormSetValue } from "react-hook-form";
import { DEFAULT_COLOR_OPTION } from "@/utils/formDefinitions/jobManagementService/general";

export const parseUser = (options: Option[], id?: string | null) => {
  if (!id) return { id: "", value: "" };

  const user = options.find((user) => user.id === id);
  return user ? user : { id: "", value: "" };
};

export const parseBranch = (
  id: string | null | undefined,
  options: Option[]
) => {
  if (!id) return { id: "", value: "" };

  const branch = options.find((branch) => branch.id === id);
  return branch ? branch : { id: "", value: "" };
};

export const parseDivision = (
  id: string | null | undefined,
  options: Option[]
) => {
  if (!id) return { id: "", value: "" };

  const division = options.find((division) => division.id === id);
  return division ? division : { id: "", value: "" };
};

export const parseDate = (date: Date | string | null | undefined) => {
  if (!date) {
    const dateString = new Date().toLocaleDateString(); // '4/23/2024'
    // the dateString needs to be parsed into a value with no timestamp in order to create a mid night one
    return new Date(`${dateString} 00:00:00`).toString();
  }

  // the API date format here is 2024-04-09
  return new Date(`${date} 00:00:00`).toString();
};

export const onStartDateChange = (
  startDate: string | Date | null,
  endDate: string | Date | null,
  setValue: UseFormSetValue<any>
) => {
  if (!startDate || !endDate) return;

  const parsedStartDate =
    typeof startDate === "string" ? new Date(startDate) : startDate;

  const parsedEndDate =
    typeof endDate === "string" ? new Date(endDate) : endDate;

  if (isAfter(parsedStartDate, parsedEndDate)) {
    setValue("endDate", "");
    return;
  }

  const subtractedEndDate = sub(parsedEndDate, { days: 100 });

  if (isBefore(parsedStartDate, subtractedEndDate)) {
    setValue("endDate", "");
    return;
  }
};

export const parseColor = (colorId: string, colors: ColorOption[]) => {
  if (!colors.length) return DEFAULT_COLOR_OPTION;

  if (!colorId) {
    const slate = colors.find((color) => color.name === "Slate") as ColorOption;
    return slate;
  }

  const selectedColor =
    colors.find((color) => color.id === colorId) ||
    colors.find((color) => color.name === "Slate") ||
    DEFAULT_COLOR_OPTION;

  return selectedColor;
};
