import * as React from "react";

export const ConvertIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="26"
      viewBox="0 0 21 26"
      fill="none"
    >
      <g id="Group 1000004926">
        <path
          id="Vector"
          d="M11.8934 8.8997L11.7464 8.75H11.5366H7.95455C7.44479 8.75 7.04545 9.17005 7.04545 9.66667V16.3333C7.04545 16.83 7.44479 17.25 7.95455 17.25H12.8636C13.3734 17.25 13.7727 16.83 13.7727 16.3333V11.0182V10.8138L13.6295 10.6679L11.8934 8.8997ZM13.7824 10.4854L13.7827 10.4857C13.9211 10.6266 14 10.8186 14 11.0182V16.3333C14 16.9852 13.4814 17.5 12.8636 17.5H7.95455C7.33674 17.5 6.81818 16.9852 6.81818 16.3333V9.66667C6.81818 9.01485 7.33674 8.5 7.95455 8.5H11.5392C11.7269 8.5 11.911 8.5768 12.0491 8.71736C12.0491 8.7174 12.0491 8.71744 12.0492 8.71748L13.7824 10.4854Z"
          fill="#3B84DC"
          stroke="#3CD39C"
        />
        <path
          id="Ellipse 4145"
          d="M19.5 13C19.5 17.9706 15.4706 22 10.5 22M1.5 13C1.5 8.02944 5.52944 4 10.5 4"
          stroke="#3CD39C"
          strokeWidth="1.5"
        />
        <path
          id="Polygon 43"
          d="M9.81707 5.18302L9.81707 2.81707L11.866 4.00004L9.81707 5.18302Z"
          fill="#3B84DC"
          stroke="#3CD39C"
        />
        <path
          id="Polygon 44"
          d="M11.1829 20.817L11.1829 23.1829L9.13396 22L11.1829 20.817Z"
          fill="#3B84DC"
          stroke="#3CD39C"
        />
      </g>
    </svg>
  );
};
