const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <circle cx="7" cy="7.5" r="6.5" fill="#3CA6D3" stroke="#3CA6D3" />
      <path
        d="M6.36244 9.11222V9.01989C6.36481 8.59138 6.40506 8.25047 6.48318 7.99716C6.56367 7.74384 6.67731 7.54025 6.82409 7.38636C6.97087 7.23011 7.14961 7.08688 7.36031 6.95668C7.50473 6.86671 7.63375 6.76847 7.74739 6.66193C7.86339 6.55303 7.95454 6.43229 8.02082 6.29972C8.08711 6.16477 8.12026 6.01444 8.12026 5.84872C8.12026 5.6617 8.07646 5.49953 7.98886 5.36222C7.90127 5.22491 7.7829 5.11837 7.63375 5.04261C7.48697 4.96686 7.32243 4.92898 7.14014 4.92898C6.97206 4.92898 6.81225 4.96567 6.66074 5.03906C6.51159 5.11009 6.3873 5.21899 6.28787 5.36577C6.19081 5.51018 6.13635 5.69366 6.12452 5.91619H4.86031C4.87215 5.46638 4.98105 5.08996 5.18702 4.78693C5.39535 4.4839 5.66997 4.25663 6.01088 4.10511C6.35416 3.9536 6.73295 3.87784 7.14724 3.87784C7.59942 3.87784 7.99715 3.95715 8.34043 4.11577C8.68607 4.27438 8.95477 4.50047 9.14653 4.79403C9.34066 5.08523 9.43773 5.43087 9.43773 5.83097C9.43773 6.10085 9.39393 6.34233 9.30634 6.5554C9.22111 6.76847 9.09919 6.95786 8.94057 7.12358C8.78195 7.2893 8.59374 7.43726 8.37594 7.56747C8.18418 7.68584 8.02674 7.80895 7.90364 7.93679C7.7829 8.06463 7.69294 8.21496 7.63375 8.38778C7.57693 8.55824 7.54734 8.76894 7.54497 9.01989V9.11222H6.36244ZM6.98034 11.3281C6.76727 11.3281 6.5838 11.2524 6.42992 11.1009C6.27603 10.9493 6.19909 10.7647 6.19909 10.5469C6.19909 10.3338 6.27603 10.1515 6.42992 10C6.5838 9.84848 6.76727 9.77273 6.98034 9.77273C7.19104 9.77273 7.37333 9.84848 7.52722 10C7.68347 10.1515 7.76159 10.3338 7.76159 10.5469C7.76159 10.6913 7.7249 10.8227 7.65151 10.9411C7.58048 11.0594 7.48579 11.1541 7.36742 11.2251C7.25141 11.2938 7.12239 11.3281 6.98034 11.3281Z"
        fill="white"
      />
    </svg>
  );
};

export { InfoIcon };
