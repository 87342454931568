import { LeftArrow, RightArrow } from "@/assets";
import { classNames } from "@/utils/helpers/classNames";
import { FC, useState } from "react";

interface CarouselProps {
  slides: { url: string }[];
  onSlideChange: (slideIndex: number) => void;
  initialIndex?: number;
}
export const Carousel: FC<CarouselProps> = ({
  slides,
  onSlideChange,
  initialIndex,
}) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex ?? 0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    onSlideChange(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    onSlideChange(newIndex);
  };

  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
    onSlideChange(slideIndex);
  };

  return (
    <div className="group relative m-auto flex h-[900px] w-full max-w-[1400px] flex-col">
      <img
        src={slides[currentIndex].url}
        alt="carouselImage"
        style={{
          height: "100%",
          borderRadius: "16px",
          objectFit: "contain",
          transitionDuration: "500ms",
        }}
      />

      <div className="absolute -left-20 top-[50%]   block -translate-x-0 translate-y-[-50%] cursor-pointer rounded-full bg-black/20 text-2xl text-white">
        <button
          onClick={prevSlide}
          className="rounded-full bg-[#e3e3e3] px-4 py-3"
        >
          <LeftArrow />
        </button>
      </div>

      <div className="absolute -right-20 top-[50%]  block -translate-x-0 translate-y-[-50%] cursor-pointer rounded-full bg-black/20 text-2xl text-white">
        <button
          onClick={nextSlide}
          className="rounded-full bg-[#e3e3e3] px-4 py-3"
        >
          <RightArrow />
        </button>
      </div>

      <div className="top-4 mt-5 flex justify-center space-x-4 py-2">
        {slides.map((_, slideIndex) => (
          <button
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className={classNames("h-4 w-4 cursor-pointer")}
          >
            <svg
              viewBox="0 0 100 100"
              fill={slideIndex === currentIndex ? "#3CD38B" : "#d9d9d9"}
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="50" cy="50" r="50" />
            </svg>
          </button>
        ))}
      </div>
    </div>
  );
};
