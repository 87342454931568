import { QUERY_KEYS } from "../constants";
import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

type ProxySchema = {
  platform: string;
  hardwareVer: string;
  hardwareName: string;
  os: string;
  hardwareAddr: string;
};

type SourceSchema = {
  type: string;
  rssi: string;
  distance: string;
  horizontalAngle: string;
};

type DeviceSchema = {
  hardwareVer: string;
  firmwareVer: string;
  customerId: string;
  motionDetected: boolean;
};

export type HistorySchema = {
  dateTime: Date;
  localDateTime: Date;
  macAddress: string;
  lat: string;
  lng: string;
  horizontalAccuracy: number;
  verticalAccuracy: number;
  provider: string;
  proxy: ProxySchema;
  source: SourceSchema;
  device: DeviceSchema;
  ackId: string;
  locationName: string;
};

type ReturnSchema = {
  data: HistorySchema[];
  nextPageKey: string;
};

export const useInfiniteAssetHistory = (params: { assetId: string }) => {
  const { fetchWithAuth } = useAuthContext();
  const { assetId } = params;

  return useInfiniteQuery<
    ReturnSchema,
    Error,
    InfiniteData<ReturnSchema>,
    QueryKey,
    { nextPageKey: string }
  >({
    queryKey: [QUERY_KEYS.ASSET_HISTORY, { assetId }],
    queryFn: async (params) => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/assets/locations?assetId=${assetId}${
          params.pageParam?.nextPageKey
            ? `&nextPageKey=${params.pageParam?.nextPageKey}`
            : ""
        }`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching asset history");
      }

      return apiRes.json();
    },
    initialPageParam: { nextPageKey: "" },
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.nextPageKey) {
        return {
          nextPageKey: lastPage.nextPageKey,
        };
      }
    },
    enabled: Boolean(assetId),
  });
};
