import { Job } from "@/types/jobManagementService/general";
import { JobForm } from "@/components/JobManagement/JobForm";
import { LoadingSpinner } from "@/UI/Loading";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useColors } from "@/lib/react-query/queryHooks/useColors";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { useGQLJob } from "@/lib/react-query/queryHooks/useGQLJob";
import { useLocation, useNavigate } from "react-router-dom";
import { useUsers } from "@/lib/react-query/queryHooks/useUsers";

export default function JobDetailPage() {
  const navigate = useNavigate();
  const { accountId } = useAuthContext();

  const { data: useBranchesData } = useBranches({
    options: { staleTime: 5 * 1000 * 60 },
  });

  const { data: useDivisionsData } = useDivisions({
    options: { staleTime: 5 * 1000 * 60 },
  });

  const { data: useUsersData } = useUsers({
    options: { staleTime: 5 * 1000 * 60 },
  });

  const { data: useColorsData } = useColors({ accountId });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobId = searchParams.get("jobId") ?? "";

  const { data: useJobData, isLoading } = useGQLJob(jobId);

  if (!jobId) navigate("/schedule");

  if (isLoading) return <LoadingSpinner />;

  return (
    <JobForm
      branches={useBranchesData?.data}
      colorsData={useColorsData?.data}
      divisions={useDivisionsData?.data}
      edit
      job={useJobData?.data.job as unknown as Job}
      users={useUsersData?.data}
    />
  );
}
