export const MAP_CENTER = {
  lat: 43.195297,
  lng: -101.996554,
};

export const NORTH_AMERICA_BOUNDS = {
  north: 65.12129531234014,
  south: 10.25953808934957,
  west: -176.25394431909305,
  east: -17.63089744409306,
};

export const MAP_OPTIONS = {
  clickableIcons: false,
  defaultCenter: MAP_CENTER,
  defaultZoom: 3,
  fullscreenControl: false,
  mapId: "465a375eb871e19c",
  mapTypeControl: false,
  mapTypeId: "hybrid",
  restriction: { latLngBounds: NORTH_AMERICA_BOUNDS, strictBounds: true },
  streetViewControl: false,
  zoomControl: false,
};
