import { Branch } from "@/types/users/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseCreateBranchMutationParams {
  body: Omit<Branch, "accountId" | "id">;
}

export const useCreateBranch = () => {
  const { fetchWithAuth } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<Branch, Error, UseCreateBranchMutationParams>({
    mutationFn: async ({ body }) => {
      const apiRes = await fetchWithAuth({
        url: `${usersBaseUrl}/branch`,
        method: "POST",
        body: JSON.stringify(body),
      });

      if (!apiRes.ok) {
        throw new Error(`Error creating branch`);
      }

      return apiRes.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BRANCHES] });
    },
  });
};
