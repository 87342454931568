import { FC, useEffect } from "react";
import { classNames } from "@/utils/helpers/classNames";
import { useFormContext } from "react-hook-form";
import { getTitleClassesByAttributes } from "@/utils/helpers/forms/formRendererHelpers";
import { RowField } from "@/types/forms/formEngine";

type EditAdderFieldProps = {
  fieldItem: RowField;
};
export const EditAdderField: FC<EditAdderFieldProps> = ({ fieldItem }) => {
  const { register, watch, setValue } = useFormContext();

  const totalValue = watch(fieldItem?.attributes?.adderFieldIds ?? "")?.reduce(
    (acc: number, curr: string) => {
      if (!Number(curr)) return acc;
      return acc + Number(curr);
    },
    0
  );

  useEffect(() => {
    setValue(`${fieldItem?.id}`, Number(Number(totalValue ?? 0).toFixed(2)));
  }, [fieldItem?.id, setValue, totalValue]);

  return (
    <div
      className={classNames(
        `flex space-x-3 `,
        getTitleClassesByAttributes(fieldItem?.attributes)
      )}
      style={{
        gridColumnStart: fieldItem?.columnStart,
      }}
    >
      <p>{fieldItem?.attributes?.title}</p>
      <input
        disabled
        className="bg-transparent"
        {...register(`${fieldItem.id}`)}
      />
    </div>
  );
};
