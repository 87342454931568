import { FC, TextareaHTMLAttributes } from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { TextArea } from ".";

interface ControlledTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  error?: FieldError;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  required?: boolean;
  textareaStyles?: string;
  containerClassNames?: string;
}

export const ControlledTextArea: FC<ControlledTextAreaProps> = ({
  name,
  error,
  label,
  control,
  required,
  defaultValue,
  textareaStyles,
  containerClassNames,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
      }}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <TextArea
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          error={error}
          containerClassNames={containerClassNames}
          label={label}
          textareaStyles={textareaStyles}
          id={name}
          {...rest}
        />
      )}
    />
  );
};
