import { formatInTimeZone } from "date-fns-tz";
import { isSameDay, isValid, parseISO } from "date-fns";
import {
  formatDateWithinTimezoneOffset,
  usaTimeFormat,
} from "@/utils/helpers/dateFormat";
import { Field } from "./FIeld";
import { FC, useMemo } from "react";
import { RowField } from "@/types/forms/formEngine";
import { useFormRendererStoreSelectors } from "@/lib/zustand/formRendererStore";
import { get } from "@/utils/helpers/lodashCopies";
type TimeFieldProps = {
  fieldItem: RowField;
  value?: string;
};

export const TimeField: FC<TimeFieldProps> = ({ fieldItem, value = "" }) => {
  const formSubmission = useFormRendererStoreSelectors.use.formSubmission();
  const timezoneOffset = get(formSubmission, "timezoneOffset");
  const timecardDate = get(formSubmission, "localDateTime");
  const parsedValue = parseISO(value);
  const getFormattedValue = () => {
    if (!isValid(parsedValue)) {
      return "";
    }
    return formatInTimeZone(parsedValue, timezoneOffset, usaTimeFormat);
  };
  const isNextDay = useMemo(() => {
    if (!timecardDate || !value) return false;
    const referenceDayFieldValue = formatDateWithinTimezoneOffset(
      new Date(timecardDate),
      timezoneOffset
    );
    return !isSameDay(
      formatDateWithinTimezoneOffset(parsedValue, timezoneOffset),
      referenceDayFieldValue
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedValue]);
  return (
    <Field
      value={getFormattedValue()}
      fieldItem={fieldItem}
      endAdornment={
        isNextDay && (
          <span>
            <span className="ml-1 rounded-xl bg-blue-500 p-1  text-[9px] font-bold text-white">
              +1
            </span>
          </span>
        )
      }
    />
  );
};
