import { graphql } from "@/lib/graphql";
import { ListColorsInput } from "@/lib/graphql/graphql";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useQuery } from "@tanstack/react-query";

const colors = graphql(`
  query Colors($filters: ListColorsInput!) {
    colors(filters: $filters) {
      id
      accountId
      name
      code
      createdAt
      updatedAt
    }
  }
`);

export const useColors = (filters: ListColorsInput) => {
  const { graphqlRequest } = useAuthContext();

  return useQuery({
    queryKey: [QUERY_KEYS.COLORS, { filters }],
    queryFn: async () => graphqlRequest(colors, { filters }),
  });
};
