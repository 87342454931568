import { Branch } from "@/types/users/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useQuery } from "@tanstack/react-query";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseBranchQueryReturn {
  data: Branch[];
}

interface UseBranchQueryParams {
  branchId: string;
}

export const useBranch = (params: UseBranchQueryParams) => {
  const { fetchWithAuth } = useAuthContext();
  const { branchId } = params;

  return useQuery<UseBranchQueryReturn, Error, Branch>({
    queryKey: [QUERY_KEYS.BRANCH, { branchId }],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${usersBaseUrl}/branch`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching branch");
      }

      return apiRes.json();
    },
    select: (branch) => {
      const item = branch.data.find((branch) => branch.id === branchId);
      return item ?? ({} as Branch);
    },
    enabled: Boolean(branchId),
  });
};
