/* eslint-disable @typescript-eslint/no-explicit-any */

import { classNames } from "@/utils/helpers/classNames";
import { FC, useCallback, useEffect, useRef } from "react";
import { FieldError } from "react-hook-form";
import { Location } from "@/types/jobManagementService/general";
import { MapIcon } from "@/assets";
import { NORTH_AMERICA_BOUNDS } from "@/utils/helpers/map";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

type Props = {
  error?: FieldError;
  onChange: (...event: any[]) => void;
  onClickEndAdorment?: () => void;
  value: Location | null | string;
};

const GoogleAutocomplete: FC<Props> = ({
  error,
  onChange,
  onClickEndAdorment,
  value,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  const EndAdornment = useCallback(() => {
    return (
      <button
        className="flex h-10 w-10 items-center justify-center rounded-r-md bg-palette-companyGreen"
        onClick={onClickEndAdorment}
        type="button"
      >
        <MapIcon pathClassName="stroke-white" />
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!inputRef.current || !places) return;

    const googleAutocomplete = new places.Autocomplete(inputRef.current, {
      bounds: { ...NORTH_AMERICA_BOUNDS },
      strictBounds: true,
      fields: ["formatted_address", "geometry"],
    });

    googleAutocomplete.addListener("place_changed", () => {
      const place = googleAutocomplete.getPlace();

      if (place.geometry?.location) {
        onChange({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          name: place.formatted_address || "",
        });
      } else {
        onChange(undefined);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places]);

  const inputValue =
    typeof value === "string"
      ? value
      : value?.name
      ? value.name
      : value?.formattedAddress
      ? value.formattedAddress
      : "";

  return (
    <div className={classNames("relative col-span-2 flex w-full flex-col")}>
      <label
        className={classNames("text-sm font-medium text-[#575757]")}
        htmlFor="custom-google-autocomplete-1"
      >
        Location
      </label>

      <div className="relative">
        <input
          className={classNames(
            "h-10 w-full rounded-md border bg-[#fafdfe] px-3 text-[#141c22] placeholder:leading-6 placeholder:text-[#97989e] hover:border-[#393d41]",
            error ? "border-[#D65D59]" : "border-[#949494]"
          )}
          id="custom-google-autocomplete-1"
          onChange={(ev) => {
            if (ev.target.value) {
              onChange(ev);
            } else {
              onChange("");
            }
          }}
          placeholder="Select a Location"
          ref={inputRef}
          value={inputValue}
        />

        {onClickEndAdorment && (
          <div
            className={classNames(
              "absolute inset-y-0 right-0 flex items-center"
            )}
          >
            <EndAdornment />
          </div>
        )}
      </div>

      {error && (
        <p className="text-xs font-medium text-[#D65D59]">
          {error.message || "Error"}
        </p>
      )}
    </div>
  );
};

export { GoogleAutocomplete };
