import { ColumnDef } from "@tanstack/react-table";
import { Branch } from "../../types/users/general";
import { NavigateFunction } from "react-router-dom";

export const getAccountSettingsTableColumns = (props: {
  navigate: NavigateFunction;
}): ColumnDef<Branch>[] => {
  const { navigate } = props;
  return [
    {
      header: "",
      accessorKey: "branchLogo",
      cell: ({
        row: {
          original: { logo },
        },
      }) => {
        const source = logo.split("?")[0];
        // Prevent these images from being cached, therefore insert etag
        return (
          <img
            src={`${source}?etag=${new Date().getTime().toString()}`}
            className="h-12 w-12"
            alt="Branch Logo"
          />
        );
      },
      enableSorting: false,
    },

    {
      header: "Branch Name",
      accessorKey: "branchName",
      cell: ({
        row: {
          original: { name, id },
        },
      }) => {
        return (
          <button
            onClick={() => navigate(`/settings/branch/detail?branchId=${id}`)}
          >
            <span>{name}</span>
          </button>
        );
      },
      enableSorting: false,
    },
    {
      header: "Address",
      accessorKey: "address",
      cell: ({
        row: {
          original: { address },
        },
      }) => <span>{address}</span>,
      enableSorting: false,
    },
    {
      header: "Email",
      accessorKey: "email",
      cell: ({
        row: {
          original: { email },
        },
      }) => {
        return <span>{email}</span>;
      },
      enableSorting: false,
    },
    {
      header: "Phone Number",
      accessorKey: "phoneNumber",
      cell: ({
        row: {
          original: { phoneNumber },
        },
      }) => {
        return <span>{phoneNumber}</span>;
      },
      enableSorting: false,
    },
  ];
};
