import { FC } from "react";
import Select, {
  ActionMeta,
  GroupBase,
  MenuPlacement,
  MultiValue,
  OptionsOrGroups,
} from "react-select";
import { FieldError } from "react-hook-form";
import { classNames } from "@/utils/helpers/classNames";
import "./styles.css";

export type MultiSelectOption =
  | OptionsOrGroups<unknown, GroupBase<unknown>>
  | undefined;

type MultiSelectProps = {
  disabled?: boolean;
  error?: FieldError;
  label?: string;
  menuPlacement?: MenuPlacement;
  menuStyle?: Record<string, string | number>;
  onChange?:
    | ((newValue: MultiValue<unknown>, actionMeta: ActionMeta<unknown>) => void)
    | undefined;
  options?: MultiSelectOption;
  placeholder?: string;
  selectStyle?: Record<string, string | number>;
  value?: MultiValue<unknown>;
};

const MultiSelect: FC<MultiSelectProps> = ({
  disabled = false,
  error,
  label,
  menuPlacement,
  menuStyle = {},
  onChange,
  options,
  placeholder = "",
  selectStyle = {},
  value,
}) => {
  return (
    <div className="relative flex flex-col">
      {label && (
        <label
          className={classNames(
            "text-sm font-medium",
            disabled ? "text-[#97989e]" : "text-[#575757]"
          )}
          htmlFor={label}
        >
          {label}
        </label>
      )}
      <Select
        isDisabled={disabled}
        id={label}
        value={value}
        onChange={onChange}
        options={options}
        isSearchable={false}
        placeholder={placeholder}
        isMulti
        closeMenuOnSelect={false}
        menuPlacement={menuPlacement}
        classNamePrefix="rb-multi-select"
        styles={{
          control: (base) => ({
            ...base,
            borderWidth: "1px",
            borderRadius: "6px",
            borderColor: disabled ? "#ebebeb" : error ? "#D65D59" : "#949494",
            backgroundColor: disabled ? "#ecf2f2" : "#fafdfe",
            color: disabled ? "#97999f" : "#141c22",
            minHeight: "40px",
            ...selectStyle,
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: "#EDF1F3",
            ...menuStyle,
          }),
        }}
      />

      {error && (
        <p className="text-[12px] font-medium text-[#D65D59]">
          {error.message || "Error"}
        </p>
      )}
    </div>
  );
};

export { MultiSelect };
