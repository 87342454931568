/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@/UI/Button";
import { Select } from "@/UI/Select";
import * as React from "react";
import { FC, useMemo, useState } from "react";
import { quoteStatusOptions } from "../../UI/StatusSelector/StatusSelector";
import { DynamicPageField, FormSubmission } from "@/types/forms/general";
import { useQuoteStoreSelectors } from "@/lib/zustand/quoteStore";

interface ClientQuotingFiltersProps {
  filters: DynamicPageField[];
  quotingData: FormSubmission[] | undefined;
}
type DataLookup = Record<string, Set<any>>;
export const ClientQuotingFilters: FC<ClientQuotingFiltersProps> = ({
  filters,
  quotingData,
}) => {
  const clientQuoteFormColumnFiltersState =
    useQuoteStoreSelectors.use.clientQuoteFormColumnFiltersState();
  const setClientQuoteFormColumnFiltersState =
    useQuoteStoreSelectors.use.setClientQuoteFormColumnFiltersState();

  const getFormStateMap = () => {
    return new Map(
      filters.map((filterItem) => [
        filterItem.name,
        (clientQuoteFormColumnFiltersState.find(
          (column) => column.id === filterItem.name
        )?.value as string) ?? "",
      ])
    );
  };

  const [formState, setFormState] = useState<Map<string, string>>(
    getFormStateMap()
  );
  const dataLookup = useMemo(() => {
    return (
      quotingData?.reduce((acc: DataLookup, curr: Record<string, any>) => {
        const names = Object.keys(curr);
        names.map((name) => {
          if (!curr[name]) return;
          if (!acc[name]) {
            acc[name] = new Set([curr[name]]);
            return;
          }
          acc[name].add(curr[name]);
        });
        return acc;
      }, {} as DataLookup) ?? ([] as unknown as DataLookup)
    );
  }, [quotingData]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const filters = [];
    for (const [key, value] of formState.entries()) {
      if (value !== "") {
        filters.push({ id: key, value });
      }
    }
    setClientQuoteFormColumnFiltersState(filters);
  };

  return (
    <form onSubmit={onSubmit} className="w-[500px]">
      <div id="quote-filters" className="grid grid-cols-2 gap-4">
        {filters.map((filterItem, index) => {
          if (
            filterItem.type === "TextField" ||
            filterItem.type === "UserField" ||
            filterItem.type === "BranchField"
          ) {
            const data = [...(dataLookup[filterItem.name] ?? [])].map(
              (item) => ({
                id: item,
                value: item,
              })
            );
            const value = formState.get(filterItem.name) ?? "";
            return (
              <Select
                key={filterItem.id ?? index}
                label={filterItem.name}
                disabled={data.length < 1}
                options={data}
                value={{ id: value, value }}
                onChange={(newValue) => {
                  if (!newValue?.value) return;
                  setFormState(
                    new Map(formState.set(filterItem.name, newValue?.value))
                  );
                }}
              />
            );
          }
          if (filterItem.type === "StatusSelector") {
            const value = formState.get(filterItem.name) ?? "";
            const options = structuredClone(quoteStatusOptions);
            options.unshift({ id: "5", value: "All" });
            return (
              <Select
                key={filterItem.id ?? index}
                label={filterItem.name}
                capitalize
                options={options}
                hidePlaceholder
                value={options.find(
                  (option) => option.value.toLocaleLowerCase() === value
                )}
                onChange={(newValue) => {
                  if (!newValue?.value) return;
                  if (newValue.value == "All") {
                    const allOptions = quoteStatusOptions
                      .map((option) => option.value.toLocaleLowerCase())
                      .join(" ");
                    setFormState(
                      new Map(formState.set(filterItem.name, allOptions))
                    );
                    return;
                  }
                  setFormState(
                    new Map(
                      formState.set(
                        filterItem.name,
                        newValue?.value.toLocaleLowerCase()
                      )
                    )
                  );
                }}
              />
            );
          }
        })}
      </div>
      <div className="flex w-full justify-between">
        <Button
          label="Clear"
          type="button"
          variant="secondary"
          className="mt-4 rounded-md"
          onClick={() => {
            setClientQuoteFormColumnFiltersState([]);
            formState.forEach((_value, key, map) => map.set(key, ""));
          }}
        />
        <Button
          label="Apply"
          variant="primary"
          type="submit"
          className="mt-4 rounded-md"
        />
      </div>
    </form>
  );
};
