import { FormSubmissionHistoryAuditRecord } from "@/types/forms/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";

const timecardsBaseUrl = import.meta.env.VITE_TIMECARDS_BASE_URL;

export type UseEditFormSubmissionHistoryReturn = {
  data: FormSubmissionHistoryAuditRecord[];
};

type UseEditFormSubmissionProps = {
  formSubmissionId: string | null;
  options?: Partial<
    DefinedInitialDataOptions<UseEditFormSubmissionHistoryReturn>
  >;
};

export const useEditFormSubmissionHistory = (
  params: UseEditFormSubmissionProps
) => {
  const { fetchWithAuth } = useAuthContext();
  const { formSubmissionId, options } = params;

  return useQuery<UseEditFormSubmissionHistoryReturn, Error>({
    queryKey: [QUERY_KEYS.EDIT_FORM_SUBMISSION_HISTORY, { formSubmissionId }],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${timecardsBaseUrl}/forms/${formSubmissionId}/audit/logs`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching edit form submission history");
      }

      return apiRes.json();
    },
    ...(options && { ...options }),
  });
};
