import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

// null and undefined need to be possible options in case of date ranges

export const formatToDateBeginISO = (date: Date | null | undefined) => {
  if (date) {
    const formattedDate = format(date, "yyy-MM-dd");
    return formattedDate.concat("T00:00:00.000Z");
  }
  return null;
};

export const formatToDateEndISO = (date: Date | null | undefined) => {
  if (date) {
    const formattedDate = format(date, "yyy-MM-dd");
    return formattedDate.concat("T23:59:59.999Z");
  }
  return null;
};

export const formatDateToDateOnly = (date: Date | null) => {
  if (!date) return null;
  const userTimezoneOffsetInMilliSeconds = date.getTimezoneOffset() * 60 * 1000;
  return new Date(date.getTime() - userTimezoneOffsetInMilliSeconds)
    .toISOString()
    .split("T")[0];
};

export const formatDateOnlyTimezone = (
  date: Date,
  timezone?: string
): string => {
  return timezone
    ? formatInTimeZone(date, timezone, usaDateFormat)
    : format(date, usaDateFormat);
};

export const formatTimeOnlyTimezone = (
  date: Date,
  timezone?: string
): string => {
  return timezone
    ? formatInTimeZone(date, timezone, usaTimeFormat)
    : format(date, usaTimeFormat);
};

export const formatDateWithinTimezoneOffset = (
  date: Date,
  offset: string
): Date => {
  const millisecondsFromTimezoneHours =
    Number(offset?.slice(1, 3)) * 60 * 60 * 1000;
  const millisecondsFromTimezoneMinutes =
    Number(offset?.slice(5, 6)) * 60 * 1000;
  const timezoneOffsetInMilliseconds =
    millisecondsFromTimezoneHours + millisecondsFromTimezoneMinutes;
  const userTimezoneOffsetInMilliSeconds = date.getTimezoneOffset() * 60 * 1000;
  return new Date(
    date.getTime() +
      userTimezoneOffsetInMilliSeconds -
      timezoneOffsetInMilliseconds
  );
};
/**
 *
 * @param dateOnlyStr Ex. 2024-07-23
 * @returns Ex. 2024-07-23T00:00:00:000.Z as date object
 */
export const formatDateOnlyToCurrentTimezoneDate = (dateOnlyStr: string) => {
  const parts = dateOnlyStr.split("-").map((part) => Number(part));
  return new Date(parts[0], parts[1] - 1, parts[2]);
};

export const isValidISOString = (dateString?: string) => {
  if (!dateString) return false;
  // Regular expression to match the UTC format (ISO 8601)
  // This is a simplified version and might need adjustments to fit specific requirements
  const ISORegex1 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;
  const ISORegex2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
  const ISORegex3 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}.\d{3}Z/;
  // Check if the string matches the UTC format
  if (
    !ISORegex1.test(dateString) &&
    !ISORegex2.test(dateString) &&
    !ISORegex3.test(dateString)
  ) {
    return false;
  }
  // Try parsing the date string
  const date = new Date(dateString);
  // Check if the date is valid
  return !isNaN(date.getTime());
};
export const getFormattedTimezoneOffset = () => {
  const date = new Date();
  let offset = date.getTimezoneOffset();
  const isNegative = offset > 0;

  // Convert offset to absolute value
  offset = Math.abs(offset);

  // Calculate hours and minutes
  const hours = Math.floor(offset / 60);
  const minutes = offset % 60;

  // Format hours and minutes as two digits
  const hoursFormatted = String(hours).padStart(2, "0");
  const minutesFormatted = String(minutes).padStart(2, "0");

  // Construct the formatted timezone string with the correct sign
  return (isNegative ? "-" : "+") + hoursFormatted + ":" + minutesFormatted;
};

export const usaDateFormat = "eee MMM d yyyy";
export const usaWeekDayDateFormat = "eeee MM/dd/yyyy";
export const usaDateTimeFormat = "MM/dd/yyyy, p";
export const usaTimeFormat = "h:mmaaa";
