const OpenBlankIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="12" fill="#3CD39C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7H9C7.89543 7 7 7.89543 7 9V15C7 16.1046 7.89543 17 9 17H15C16.1046 17 17 16.1046 17 15V12H15.5V15C15.5 15.2761 15.2761 15.5 15 15.5H9C8.72386 15.5 8.5 15.2761 8.5 15V9C8.5 8.72386 8.72386 8.5 9 8.5H12V7Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0001 6V11H17.5001V7.62109L12.5607 12.5605L11.5 11.4999L16.4999 6.5H13.0001V5H18.0001C18.5524 5 19.0001 5.44772 19.0001 6Z"
        fill="white"
      />
    </svg>
  );
};
export { OpenBlankIcon };
