import { QUERY_KEYS } from "@/lib/react-query/constants";
import { AdvancedMarker, useMap, Map } from "@vis.gl/react-google-maps";
import { queryClient } from "@/lib/react-query/general";
import { AssetsAsset } from "@/types/assetInventory/assets/general";
import { isWithinNorthAmericaBounds } from "@/utils/helpers/map/validateLatLng";
import * as React from "react";
import { useEffect } from "react";
import { MAP_OPTIONS, timeDifference } from "@/utils/helpers/map";
import { GooglePin, MapIcon } from "@/assets";

interface AssetOverviewMapProps {
  assetData: AssetsAsset;
}

export const AssetOverviewMap: React.FC<AssetOverviewMapProps> = ({
  assetData,
}) => {
  const assetLatLng =
    !!assetData?.address?.lat &&
    !!assetData.address.lng &&
    isWithinNorthAmericaBounds({
      lat: assetData?.address?.lat,
      lng: assetData?.address?.lng,
    })
      ? ({
          lat: Number(assetData?.address?.lat),
          lng: Number(assetData?.address?.lng),
        } as google.maps.LatLngLiteral)
      : undefined;

  const mapAPILoaded = !!queryClient.getQueryData([QUERY_KEYS.GOOGLE_MAP_KEY]);
  const googleMap = useMap();
  useEffect(() => {
    if (!mapAPILoaded || !assetLatLng || !googleMap) return;
    googleMap.setZoom(15);
    googleMap.panTo(assetLatLng);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetData, mapAPILoaded, googleMap]);
  return (
    <>
      {assetLatLng && mapAPILoaded ? (
        <div className="relative h-[400px] overflow-hidden !rounded-xl border-2">
          <Map {...MAP_OPTIONS}>
            <AdvancedMarker
              position={{ lat: assetLatLng.lat, lng: assetLatLng.lng }}
            >
              <GooglePin fill="#3CD38B" />
            </AdvancedMarker>
          </Map>
          <div className="absolute bottom-0 left-0 right-0 flex h-28 flex-col bg-gradient-to-t from-black/90 to-transparent font-semibold">
            <div className="mb-2 ml-2 mt-auto text-white">
              <span className="text-sm">
                Last Location{" "}
                <span className="ml-2">
                  {timeDifference(assetData.lastSeen)}
                </span>
              </span>
              <p>{assetData.address?.formattedAddress}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative flex h-[400px] w-full flex-col items-center justify-center space-y-2 overflow-hidden !rounded-xl border-2 bg-[#f2f7f9]">
          <MapIcon height="50" width="50" stroke="#9b9b9b" />
          <p className="text-lg font-semibold text-[#9b9b9b]">
            Untracked Asset
          </p>
          <div className="max-w-[300px] text-center">
            <p className="text-base  text-[#9b9b9b]">
              To view Asset Location add a tracker to this Asset
            </p>
            <a
              href="mailto:sales@ribbiot.com?subject=Ribbiot Tracker Order Inquiry"
              target="_blank"
              className="w-full text-base text-[#9b9b9b]  underline"
              rel="noreferrer"
            >
              Get in touch to order more Trackers »
            </a>
          </div>
        </div>
      )}
    </>
  );
};
