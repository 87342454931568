import { useMutation } from "@tanstack/react-query";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";

const timcardInventoryBaseUrl = import.meta.env.VITE_TIMECARDS_BASE_URL;

interface Params {
  mime: string;
  image: string | ArrayBuffer | null | undefined;
}

interface Return {
  data: string;
}

export const useUploadFormImg = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<Return, Error, Params>({
    mutationFn: async ({ image, mime }) => {
      const apiRes = await fetchWithAuth({
        url: `${timcardInventoryBaseUrl}/image/upload`,
        method: "POST",
        body: JSON.stringify({ image, mime }),
      });

      if (!apiRes.ok) {
        throw new Error(`Error while uploading Form Image`);
      }

      return apiRes.json();
    },
  });
};
