const BackArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_873_17370)">
        <mask
          id="mask0_873_17370"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="black" />
        </mask>
        <g mask="url(#mask0_873_17370)">
          <path
            d="M20 11L7.83 11L11.41 7.41L10 6L4 12L10 18L11.41 16.59L7.83 13L20 13V11Z"
            fill="#575757"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_873_17370">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { BackArrow };
