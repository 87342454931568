import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";
import { graphql } from "@/lib/graphql";
import { DeletePriceBookItemMutationVariables } from "@/lib/graphql/graphql";

const DeletePriceBookItem = graphql(`
  mutation DeletePriceBookItem($deletePriceBookItemId: ID!) {
    deletePriceBookItem(id: $deletePriceBookItemId) {
      success
    }
  }
`);

export const useDeletePricebookItem = () => {
  const { graphqlRequest } = useAuthContext();
  return useMutation({
    mutationFn: async (
      deletePricebookItemVariables: DeletePriceBookItemMutationVariables
    ) => graphqlRequest(DeletePriceBookItem, deletePricebookItemVariables),
  });
};
