import { RowField } from "@/types/forms/formEngine";
import * as React from "react";
import { Field } from "./FIeld";
import { useDocumentDetailHelpers } from "@/utils/hooks/useDocumentDetailHelpers";

interface BranchFieldProps {
  fieldItem: RowField;
  value: string;
}

export const BranchField: React.FC<BranchFieldProps> = ({
  fieldItem,
  value,
}) => {
  const { getBranchNameById } = useDocumentDetailHelpers();
  const branchName = getBranchNameById(value);
  return <Field fieldItem={fieldItem} value={branchName} />;
};
