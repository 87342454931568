import { FormSubmission } from "@/types/forms/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";

const timecardsBaseUrl = import.meta.env.VITE_TIMECARDS_BASE_URL;

type UseFormSubmissionReturn = {
  data: FormSubmission;
};

type UseFormSubmissionProps = {
  formSubmissionId: string | null;
  formId: string | null;
  options?: Partial<DefinedInitialDataOptions<UseFormSubmissionReturn>>;
};

export const useFormSubmission = (params: UseFormSubmissionProps) => {
  const { fetchWithAuth } = useAuthContext();
  const { formId, formSubmissionId, options } = params;

  return useQuery<UseFormSubmissionReturn, Error>({
    queryKey: [QUERY_KEYS.FORM_SUBMISSION, { formSubmissionId }],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${timecardsBaseUrl}/forms/${formId}/submission/${formSubmissionId}`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching form submission.");
      }

      return apiRes.json();
    },
    ...(options && { ...options }),
  });
};
