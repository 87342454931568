import { graphql } from "@/lib/graphql";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useQuery } from "@tanstack/react-query";

const job = graphql(`
  query Job($jobId: ID!) {
    job(id: $jobId) {
      id
      accountId
      createdAt
      updatedAt
      divisionId
      branchId
      address {
        name
        formattedAddress
        lat
        lng
      }
      customer {
        name
        email
        phone
        type
        contactName
      }
      number
      notes
      status
      name
      projectOwner
      salesPerson
      startDate
      endDate
      tasks {
        id
        createdAt
        updatedAt
        accountId
        jobId
        date
        assets
        crew
        active
      }
      jobType
      color {
        id
        accountId
        name
        code
        createdAt
        updatedAt
      }
    }
  }
`);

export const useGQLJob = (jobId: string) => {
  const { graphqlRequest } = useAuthContext();

  return useQuery({
    queryKey: [QUERY_KEYS.GQL_JOB, { jobId }],
    queryFn: async () => graphqlRequest(job, { jobId }),
  });
};
