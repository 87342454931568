import { FC } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { PricebookItemForm } from "@/components/Pricebook/PricebookItemForm";
import { LoadingSpinner } from "@/UI/Loading";

export const pricebookDetailDefaultValues = {
  name: "My Pricebook item",
  description: "Description for My Pricebook item",
  rate: 100,
  unit: "",
};
const PricebookItemPage: FC = () => {
  const { data: divisionsData, isLoading: divisionsLoading } = useDivisions({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: branchesData, isLoading: branchesLoading } = useBranches({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const divisionsOptions =
    divisionsData?.data?.map((division) => ({
      value: division.id,
      label: division.divisionName,
    })) ?? [];
  const branchesOptions =
    branchesData?.data?.map((branch) => ({
      value: branch.id,
      label: branch.name,
    })) ?? [];

  const pricebookItemFormLoading = divisionsLoading || branchesLoading;

  return (
    <div className="w-full max-w-[1600px] pt-14">
      <header className="mb-5 flex justify-between">
        <div>
          <Link
            className="my-4 flex w-fit items-center gap-x-1 text-theme-black-secondary"
            to="/settings/pricebook"
          >
            <ArrowLeftIcon className="h-6 w-6" />
            <span className="text-xs font-medium">Back to Pricebook</span>
          </Link>
          <h1 className="text-4xl font-semibold">Create Pricebook Item</h1>
        </div>
      </header>
      {pricebookItemFormLoading && <LoadingSpinner />}
      {!pricebookItemFormLoading && (
        <PricebookItemForm
          divisionsOptions={divisionsOptions}
          branchesOptions={branchesOptions}
        />
      )}
    </div>
  );
};

export default PricebookItemPage;
