import { get } from "@/utils/helpers/lodashCopies";
import { formatInTimeZone } from "date-fns-tz";
import {
  usaDateTimeFormat,
  usaWeekDayDateFormat,
} from "@/utils/helpers/dateFormat";
import { Field } from "./FIeld";
import { FC } from "react";
import { RowField } from "@/types/forms/formEngine";
import { useFormRendererStoreSelectors } from "@/lib/zustand/formRendererStore";

type MetadataFieldProps = {
  fieldItem: RowField;
};
export const MetadataField: FC<MetadataFieldProps> = ({ fieldItem }) => {
  const formSubmission = useFormRendererStoreSelectors.use.formSubmission();
  if (fieldItem.type === "WeekDateField") {
    const timezone = get(formSubmission, "timezoneOffset");
    const metadataField = get(formSubmission, fieldItem.path ?? "");
    const value = formatInTimeZone(
      new Date(metadataField),
      timezone,
      usaWeekDayDateFormat
    );
    return (
      <Field
        key={`WeekDateField:${value}`}
        value={value}
        fieldItem={fieldItem}
      />
    );
  }
  if (fieldItem.type === "DateTimeField") {
    const timezone = get(formSubmission, "timezoneOffset");
    const metadataField = get(formSubmission, fieldItem.path ?? "");
    const value = formatInTimeZone(
      new Date(metadataField),
      timezone,
      usaDateTimeFormat
    );
    return (
      <Field
        key={`DateTimeField:${value}`}
        value={value}
        fieldItem={fieldItem}
      />
    );
  }
  if (fieldItem.type === "UserField" || fieldItem.type === "TextField") {
    const metadataField = get(formSubmission, fieldItem.path ?? "");
    return (
      <Field
        key={`UserFieldTextField:${metadataField}`}
        fieldItem={fieldItem}
        value={metadataField}
      />
    );
  }
  return <></>;
};
