import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";
import {
  CreateJobDocument,
  JobDocument,
} from "@/types/jobManagementService/general";

const jobsBaseUrl = import.meta.env.VITE_JOBS_BASE_URL;

interface UseCreateDocumentMutationParams {
  body: CreateJobDocument;
  jobId: string;
}

interface UseCreateDocumentMutationReturn {
  data: JobDocument;
}

export const useCreateJobDocument = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<
    UseCreateDocumentMutationReturn,
    Error,
    UseCreateDocumentMutationParams
  >({
    mutationFn: async ({ jobId, body }) => {
      const apiRes = await fetchWithAuth({
        url: `${jobsBaseUrl}/job/${jobId}/document`,
        method: "POST",
        body: JSON.stringify(body),
      });

      if (!apiRes.ok) {
        throw new Error(`Error creating document`);
      }

      return apiRes.json();
    },
  });
};
