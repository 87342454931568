import { classNames } from "@/utils/helpers/classNames";
import { CloseIcon, MobileLogOutIcon, RibbiotMobileLogo } from "@/assets";
import { Dispatch, FC, RefObject, SetStateAction } from "react";
import { Divider } from "../Divider";
import { signOut } from "@/utils/helpers/signOut";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  sectionRef: RefObject<HTMLDivElement>;
};

export const MobileMenu: FC<Props> = ({ open, setOpen, sectionRef }) => {
  const { logout, user } = useAuth0();

  return (
    <section
      ref={sectionRef}
      className={classNames(
        "fixed top-0 z-10 flex h-[100vh] w-full flex-col gap-y-5 bg-[#F2F7F9] px-2 transition-all duration-[1000ms]",
        `${open ? "visible right-0" : "invisible -right-full"}`
      )}
    >
      <header className="flex items-center justify-between">
        <RibbiotMobileLogo />

        <button onClick={() => setOpen(false)}>
          <CloseIcon />
        </button>
      </header>

      <span className="text-[15px] font-normal">Logged in as:</span>

      <div className="flex items-center gap-x-2">
        <div
          className={classNames(
            "flex h-[50px] w-[50px] shrink-0 items-center justify-center rounded-full border bg-palette-companyGreen"
          )}
        >
          <span className="text-lg font-medium">
            {user?.email?.[0]?.toUpperCase() || ""}
            {user?.email?.[1]?.toUpperCase() || ""}
          </span>
        </div>

        <span className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-base font-medium">
          {user?.email || ""}
        </span>
      </div>

      <Divider additionalClasses="!bg-[#949796] !h-[1px] !border-none" />

      <button
        className="flex items-center gap-x-3 text-[18px] font-semibold"
        type="button"
        onClick={() => signOut(logout)}
      >
        <MobileLogOutIcon />
        <span>Log Out</span>
      </button>

      <Divider additionalClasses="!bg-[#949796] !h-[1px] !border-none" />
    </section>
  );
};
