import { FC } from "react";

type Props = {
  pathFill?: string;
};

const UserPlusIcon: FC<Props> = ({ pathFill = "#28B380" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.46667C9 5.38125 7.38822 6.93333 5.4 6.93333C3.41177 6.93333 1.8 5.38125 1.8 3.46667C1.8 1.55208 3.41177 0 5.4 0C7.38822 0 9 1.55208 9 3.46667ZM7.2 3.46667C7.2 4.42396 6.39411 5.2 5.4 5.2C4.40589 5.2 3.6 4.42396 3.6 3.46667C3.6 2.50937 4.40589 1.73333 5.4 1.73333C6.39411 1.73333 7.2 2.50937 7.2 3.46667Z"
        fill={pathFill}
      />
      <path
        d="M13.5 3.46667H15.3V6.06667H18V7.8H15.3V10.4H13.5V7.8H10.8V6.06667H13.5V3.46667Z"
        fill={pathFill}
      />
      <path
        d="M9 13H10.8C10.8 10.1281 8.38234 7.8 5.4 7.8C2.41766 7.8 0 10.1281 0 13H1.8C1.8 11.0854 3.41177 9.53333 5.4 9.53333C7.38822 9.53333 9 11.0854 9 13Z"
        fill={pathFill}
      />
    </svg>
  );
};

export { UserPlusIcon };
