import { FC, useRef, useState } from "react";
import { MultiImageFieldValue, RowField } from "@/types/forms/formEngine";
import { Modal } from "@/UI/Modal";
import { downloadFile } from "@/utils/helpers/downloadFile";

type Props = {
  fieldItem: RowField;
  value: MultiImageFieldValue[];
};

export const MultiImageField: FC<Props> = ({ value }) => {
  const [modal, setModal] = useState({
    open: false,
    url: "",
  });

  const imageRef = useRef<HTMLImageElement>(null);

  if (!value?.length) return <></>;

  return (
    <>
      <div className="flex flex-wrap gap-6">
        {value.map((item, idx) => (
          <button
            className={
              "flex h-[152px] w-[152px] items-center justify-center rounded-md border bg-[#fafdfe] visited:border-red-400"
            }
            onClick={() =>
              setModal({
                open: true,
                url: item.url,
              })
            }
            style={{
              backgroundImage: `url(${item.url})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
            type="button"
            key={idx}
          ></button>
        ))}
      </div>

      <Modal
        download
        downloadAction={() => {
          if (!imageRef.current) return;

          downloadFile({
            extension: imageRef.current.src.split(".").at(-1) || "jpeg",
            file: imageRef.current.src,
            fileName: "quote-image",
          });
        }}
        hideButtons
        innerContainerClasses="!h-fit !w-fit !max-w-none !p-0 !bg-[#181818] !shadow-none border"
        open={modal.open}
        topClose
        topCloseContainerClasses="!-top-[10px] !-right-[17px]"
        topCloseButtonClasses="!bg-black !h-9 !w-9 !border-4 !text-base"
        onCancel={() => {
          setModal({ open: false, url: "" });
        }}
      >
        <img
          ref={imageRef}
          src={`${modal.url}`}
          alt="formImage"
          style={{
            width: "50vw",
            height: "50vw",
            borderRadius: "8px",
            objectFit: "contain",
          }}
        />
      </Modal>
    </>
  );
};
