import { FC } from "react";

type Props = {
  fill?: string;
};

export const PenIcon: FC<Props> = ({ fill = "white" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M7.79997 2.06673L0.973723 8.89298L0.825954 10.5184C0.79184 10.8937 1.10621 11.2081 1.48146 11.174L3.10693 11.0262L9.93317 4.19994L7.79997 2.06673Z"
        fill={fill}
      />
      <path
        d="M10.3598 3.77329L11.6397 2.49336C11.8754 2.25773 11.8754 1.87571 11.6397 1.64008L10.3598 0.360157C10.1242 0.12453 9.74217 0.12453 9.50654 0.360157L8.22661 1.64009L10.3598 3.77329Z"
        fill={fill}
      />
    </svg>
  );
};
