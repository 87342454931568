import { ControlledSelect } from "@/UI/Select/ControlledSelect";
import { RowField } from "@/types/forms/formEngine";
import { classNames } from "@/utils/helpers/classNames";
import {
  evaluateExpression,
  getTitleClassesByAttributes,
  transformTitleByFieldItem,
} from "@/utils/helpers/forms/formRendererHelpers";
import { useDocumentDetailHelpers } from "@/utils/hooks/useDocumentDetailHelpers";
import * as React from "react";
import { useFormContext } from "react-hook-form";

interface EditBranchFieldProps {
  fieldItem?: RowField;
}

export const EditBranchField: React.FC<EditBranchFieldProps> = ({
  fieldItem,
}) => {
  const { control, setValue } = useFormContext();
  const { getBranchOptions } = useDocumentDetailHelpers();
  const displayTitle = transformTitleByFieldItem(fieldItem);
  const hidden = evaluateExpression(fieldItem?.attributes?.hiddenIf);
  return (
    <div
      className={`space-y-1 ${hidden ? "hidden" : ""}`}
      style={{
        gridColumnStart: fieldItem?.columnStart,
        gridColumnEnd: fieldItem?.columnEnd,
      }}
    >
      {displayTitle && (
        <h4
          className={classNames(
            "text-sm font-medium text-theme-black-secondary",
            getTitleClassesByAttributes(fieldItem?.attributes)
          )}
        >
          {displayTitle}
        </h4>
      )}
      <ControlledSelect
        control={control}
        name={`${fieldItem?.id}`}
        options={getBranchOptions()}
        onChange={(selectedOption) => {
          setValue(`${fieldItem?.id}`, selectedOption?.id);
        }}
        hidePlaceholder
        containerClassNames="mr-4"
      />
    </div>
  );
};
