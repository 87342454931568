import { classNames } from "@/utils/helpers/classNames";
import {
  InputHTMLAttributes,
  LegacyRef,
  MutableRefObject,
  ReactNode,
} from "react";
import { FieldError } from "react-hook-form";

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "required"> {
  containerClasses?: string;
  endAdornment?: ReactNode | string;
  endAdornmentClasses?: string;
  error?: FieldError;
  inputClasses?: string;
  label?: string;
  startAdornment?: ReactNode | string;
  startAdornmentClasses?: string;
  customRef?: MutableRefObject<LegacyRef<HTMLInputElement> | undefined>;
}

function Input({
  containerClasses = "",
  customRef,
  defaultValue,
  disabled,
  endAdornment,
  endAdornmentClasses,
  error,
  id,
  inputClasses,
  label,
  name,
  onBlur,
  onChange,
  onClick,
  onKeyDown,
  placeholder,
  startAdornment,
  startAdornmentClasses,
  step = 1,
  type = "text",
  value,
}: InputProps) {
  return (
    <div className={classNames("relative flex flex-col", containerClasses)}>
      {label && (
        <label
          className={classNames(
            "text-sm font-medium",
            disabled ? "text-[#97989e]" : "text-[#575757]"
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}

      {startAdornment && (
        <div
          className={classNames(
            "absolute inset-y-0 left-1 flex items-center",
            startAdornmentClasses
          )}
        >
          {startAdornment}
        </div>
      )}

      <input
        name={name}
        id={id}
        className={classNames(
          "h-10 w-full rounded-md border border-[#949494] bg-[#fafdfe] text-[#141c22] placeholder:leading-6 placeholder:text-[#97989e] hover:border-[#393d41]",
          disabled
            ? "border-[#ebebeb] bg-[#ecf2f2] text-[#97999f] hover:!border-[#ebebeb]"
            : "",
          error ? "border-[#D65D59]" : "",
          inputClasses
        )}
        disabled={disabled}
        onBlur={onBlur}
        onClick={onClick}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        type={type}
        step={step}
        defaultValue={defaultValue}
        ref={customRef as LegacyRef<HTMLInputElement> | undefined}
        value={
          typeof value === "string" ||
          typeof value === "number" ||
          typeof value === "undefined"
            ? value
            : (value as unknown as { name: string }).name
        }
      />

      {endAdornment && value && (
        <div
          className={classNames(
            "absolute right-1 flex items-center",
            endAdornmentClasses
          )}
        >
          {endAdornment}
        </div>
      )}

      {error && (
        <p className="text-xs font-medium text-[#D65D59]">
          {error.message || "Error"}
        </p>
      )}
    </div>
  );
}

export { Input };
