import { create } from "zustand";
import { createSelectors } from "./createSelectors";

type NotFoundProps = {
  notFound: boolean;
  message: string | undefined;
};

type NotFoundStore = {
  notFound: boolean;
  setNotFound: (notFoundProps: NotFoundProps) => void;
  message: string | undefined;
};
export const useNotFoundStore = create<NotFoundStore>((set) => ({
  notFound: false,
  message: undefined,
  setNotFound({ message, notFound }) {
    set({ message, notFound });
  },
}));
export const useNotFoundStoreSelectors = createSelectors(useNotFoundStore);
