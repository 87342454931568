import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseDeleteUserMutationParams {
  userId: string;
}

export const useDeleteUser = () => {
  const { fetchWithAuth } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation<void, Error, UseDeleteUserMutationParams>({
    mutationFn: async ({ userId }) => {
      const res = await fetchWithAuth({
        url: `${usersBaseUrl}/users/${userId}/disable`,
        method: "PATCH",
      });

      if (!res.ok) {
        throw new Error(`Error deleting User`);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.USERS],
        refetchType: "all",
        stale: true,
      });
    },
  });
};
