import * as React from "react";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { differenceInMinutes, isDate } from "date-fns";
import { RowField } from "@/types/forms/formEngine";

interface EditTotalFieldProps {
  fieldItem?: RowField;
}

export const EditTotalField: React.FC<EditTotalFieldProps> = ({
  fieldItem,
}) => {
  const { register, watch, setValue, setError, clearErrors } = useFormContext();
  const startDateId = `${fieldItem?.attributes?.startId}`;
  const endDateId = `${fieldItem?.attributes?.endId}`;
  const outTimeId = `${fieldItem?.attributes?.outId}`;
  const totalId = `${fieldItem?.id}`;
  const startDateWatch = watch(startDateId);
  const endDateWatch = watch(endDateId);
  const outTimeWatch = watch(outTimeId);

  useEffect(() => {
    if (
      !isDate(startDateWatch) &&
      !isDate(endDateWatch) &&
      outTimeWatch === ""
    ) {
      // Everything is empty. Clear all errors and set total time to 0 and return
      clearErrors([startDateId, endDateId, outTimeId]);
      setValue(totalId, undefined);
      return;
    }
    if (
      !isDate(startDateWatch) &&
      !isDate(endDateWatch) &&
      outTimeWatch !== ""
    ) {
      // No start or end time but out time exists. Set error on out time, clear start and end errors. Set total to 0 Return
      clearErrors([startDateId, endDateId]);
      setValue(totalId, "0.0");
      setError(outTimeId, {
        type: "deps",
        message: "Must have start and stop time.",
      });
      return;
    }
    if (!isDate(startDateWatch) || !isDate(endDateWatch)) {
      if (!isDate(startDateWatch)) {
        setError(startDateId, {
          type: "valueAsDate",
          message: "Invalid Start Time",
        });
      }
      if (!isDate(endDateWatch)) {
        setError(endDateId, {
          type: "valueAsDate",
          message: "Invalid Stop Time",
        });
      }
      setValue(totalId, Number(Number(0).toFixed(2)));
      return;
    }
    const difference =
      Math.ceil(differenceInMinutes(endDateWatch, startDateWatch) / 5) * 5;

    const differenceMinusOut =
      outTimeWatch && !!Number(outTimeWatch)
        ? difference - outTimeWatch
        : difference;

    if (difference < 0) {
      setValue(totalId, Number(Number(0).toFixed(2)));
      clearErrors([endDateId, outTimeId]);
      setError(endDateId, {
        type: "valueAsDate",
        message: "Invalid Stop Time",
      });
      return;
    }
    if (differenceMinusOut < 0) {
      setValue(totalId, Number(Number(0).toFixed(2)));
      clearErrors([startDateId, endDateId]);
      setError(outTimeId, { type: "max", message: "Invalid Out Time" });
      return;
    }
    const total = differenceMinusOut / 60;
    setValue(totalId, Number(total.toFixed(2)));
    clearErrors([startDateId, endDateId, outTimeId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateWatch, endDateWatch, outTimeWatch]);

  return (
    <div
      className="flex space-y-1"
      style={{
        gridColumnStart: fieldItem?.columnStart,
        gridColumnEnd: fieldItem?.columnEnd,
      }}
    >
      <input
        disabled
        className="self-start bg-transparent p-2"
        {...register(totalId)}
      />
    </div>
  );
};
