import { useMutation } from "@tanstack/react-query";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseResendEmailMutationParams {
  id: string;
}

export const useResendInvite = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<void, Error, UseResendEmailMutationParams>({
    mutationFn: async ({ id }) => {
      const resendInviteRes = await fetchWithAuth({
        url: `${usersBaseUrl}/users/resend-invite`,
        method: "POST",
        body: JSON.stringify({ id }),
      });

      if (!resendInviteRes.ok) {
        throw new Error("Error resending invite.");
      }
    },
  });
};
