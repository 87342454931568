import { AssetsExpandedContent, JobsExpandedContent } from "./ExpandedContent";
import { classNames } from "../../utils/helpers/classNames";
import { DangerModal } from "@/UI/Modal";
import { handleExpandedNavigation } from "@/utils/helpers/handleExpandedNavigation";
import { PATHNAME_REGEX } from "@/utils/helpers/pathnameRegex";
import { SettingsExpandedContent } from "./ExpandedContent/SettingsExpandedContent";
import { useAuthContext } from "../Auth/AuthWrapper";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserMenu } from "../UserMenu";
import { UsersExpandedContent } from "./ExpandedContent/UsersExpandedContent";
import { useSidebarStoreSelectors } from "@/lib/zustand/sidebarStore";
import {
  handleNavigation,
  HandleNavigationParams,
} from "@/utils/helpers/handleNavigation";
import {
  Nav,
  navBase,
  settingsNavigation,
  timecardAdminNavigation,
  webAssetCrudNavigation,
  webAssetMapNavigation,
  webQuotingNavigation,
  webScheduleNavigation,
  webUserCrudNavigation,
} from "../../utils/navigation";

const Sidebar = () => {
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [navigationParams, setNavigationParams] =
    useState<HandleNavigationParams>();

  const {
    hasTimecardAdmin,
    hasRibbiotAdmin,
    hasAssetCrud,
    hasUserCrud,
    hasWebAssetMap,
    hasWebSchedule,
    hasWebQuoting,
  } = useAuthContext();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const hrefToHighlight = useSidebarStoreSelectors.use.hrefToHighlight();
  const setHrefToHighlight = useSidebarStoreSelectors.use.setHrefToHighlight();
  const setUnsavedDataWarning =
    useSidebarStoreSelectors.use.setUnsavedDataWarning();
  const showUnsavedDataWarning =
    useSidebarStoreSelectors.use.showUnsavedDataWarning();
  const unsavedDataWarningMessage =
    useSidebarStoreSelectors.use.unsavedDataWarningMessage();

  const adminNav = [
    ...navBase,
    ...webAssetMapNavigation,
    ...webScheduleNavigation,
    ...timecardAdminNavigation,
    ...webQuotingNavigation,
    ...webAssetCrudNavigation,
    ...webUserCrudNavigation,
  ];

  const nav: Nav[] = hasRibbiotAdmin
    ? adminNav
    : [
        ...navBase,
        ...(hasWebSchedule ? [...webScheduleNavigation] : []),
        ...(hasWebAssetMap ? [...webAssetMapNavigation] : []),
        ...(hasTimecardAdmin ? [...timecardAdminNavigation] : []),
        ...(hasAssetCrud || hasWebAssetMap ? [...webAssetCrudNavigation] : []),
        ...(hasUserCrud ? [...webUserCrudNavigation] : []),
        ...(hasWebQuoting ? [...webQuotingNavigation] : []),
      ];

  const bottomNav = hasRibbiotAdmin ? [...settingsNavigation] : [];

  const handleExpand = (pathname: string, nav: Nav[]) => {
    const sanitizedPathname = pathname.replace(PATHNAME_REGEX, "");
    const expandedRoutes = nav.flatMap((nav) => nav.expandedRoutes);
    const match = expandedRoutes.find((route) => sanitizedPathname === route);
    return Boolean(match);
  };

  const handleExpandedContent = (pathname: string, nav: Nav[]) => {
    const sanitizedPathname = pathname.replace(PATHNAME_REGEX, "");
    const currNav = nav.find((item) =>
      item.expandedRoutes.includes(sanitizedPathname)
    );

    if (currNav) {
      switch (currNav.name) {
        case "Schedule":
          return (
            <JobsExpandedContent
              setDangerModalOpen={setDangerModalOpen}
              setNavigationParams={setNavigationParams}
              showUnsavedDataWarning={showUnsavedDataWarning}
            />
          );
        case "Map":
        case "Assets":
          return <AssetsExpandedContent />;
        case "Users":
          return <UsersExpandedContent />;
        case "Settings":
          return <SettingsExpandedContent />;
        default:
          return <></>;
      }
    }
    return <></>;
  };

  useEffect(() => {
    const href = window.location.pathname.split("/")[1];

    switch (href) {
      case "jobs":
        setHrefToHighlight("schedule");
        break;
      case "assets":
        setHrefToHighlight("assets/browser");
        break;
      default:
        setHrefToHighlight(href);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <nav
        className={classNames(
          "z-10 flex h-full w-[60px] shrink-0 flex-col items-center justify-between bg-palette-quarternaryGrey pb-[34px] pt-6"
        )}
      >
        <ul className="flex w-full flex-col gap-y-8">
          {nav.map((item, idx) => {
            return (
              <li
                key={item.name}
                className={classNames(
                  "flex w-full flex-col items-center",
                  hrefToHighlight === item.href && idx > 0
                    ? "border-l-4 border-theme-green-primary"
                    : ""
                )}
              >
                <a
                  className={"group flex gap-x-3"}
                  href={item.href}
                  onClick={(event) =>
                    handleExpandedNavigation({
                      navParams: {
                        event,
                        href: item.href,
                        navigate,
                        pathname,
                      },
                      setDangerModalOpen,
                      setHrefToHighlight,
                      setNavigationParams,
                      showUnsavedDataWarning,
                    })
                  }
                  title={item.name}
                >
                  <item.icon
                    aria-hidden="true"
                    stroke={classNames(
                      hrefToHighlight === item.href && idx > 0
                        ? "black"
                        : "#575757"
                    )}
                    fill={classNames(
                      hrefToHighlight === item.href && idx > 0
                        ? "black"
                        : "#575757"
                    )}
                  />
                </a>
              </li>
            );
          })}
        </ul>

        <ul className="flex w-full flex-col gap-y-[22px]">
          {bottomNav.map((item) => (
            <li
              key={item.name}
              className={classNames(
                "flex w-full flex-col items-center",
                pathname.includes(item.href) &&
                  "border-l-4 border-theme-green-primary"
              )}
            >
              <a
                className={"flex gap-x-3"}
                href={item.href}
                onClick={(event) =>
                  handleExpandedNavigation({
                    navParams: {
                      event,
                      href: item.href,
                      navigate,
                      pathname,
                    },
                    setDangerModalOpen,
                    setHrefToHighlight,
                    setNavigationParams,
                    showUnsavedDataWarning,
                  })
                }
                title={item.name}
              >
                <item.icon />
              </a>
            </li>
          ))}

          <li className={classNames("flex w-full flex-col items-center")}>
            <UserMenu />
          </li>
        </ul>
      </nav>

      <div
        className={classNames(
          "flex h-full w-[164px] shrink-0 flex-col items-center bg-[#f2f7f9] pt-6",
          handleExpand(pathname, [...nav, ...bottomNav]) ? "flex" : "hidden"
        )}
      >
        {handleExpandedContent(pathname, [...nav, ...bottomNav])}
      </div>

      <DangerModal
        cancelAction={() => setDangerModalOpen(false)}
        confirmAction={() => {
          if (navigationParams) {
            setDangerModalOpen(false);
            setUnsavedDataWarning(false);
            handleNavigation(navigationParams);
          }
        }}
        message={unsavedDataWarningMessage}
        open={dangerModalOpen}
        title="Discard Changes?"
      />
    </>
  );
};

export { Sidebar };
