export const PDFIcon = ({ disabled }: { disabled?: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17.5V21.5H7L10 25L13 21.5H11V17.5H9Z"
        fill="black"
      />
      <path d="M3.5 16.5V19H8M16.5 16.5V19H12" stroke="#9B9B9B" />
      <path
        d="M3.5 6H8.5V1M3.5 6L8.5 1M3.5 6V7.5M16.5 7.5V1H8.5"
        stroke="#9B9B9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7.5H0V16.5H20V7.5ZM2.88261 14.5V9.40909H4.89113C5.27726 9.40909 5.60621 9.48284 5.87799 9.63033C6.14977 9.77616 6.35692 9.97917 6.49944 10.2393C6.64362 10.4979 6.71571 10.7962 6.71571 11.1342C6.71571 11.4723 6.64279 11.7706 6.49696 12.0291C6.35112 12.2876 6.13983 12.489 5.86308 12.6332C5.58798 12.7773 5.25489 12.8494 4.86379 12.8494H3.95896V14.5H2.88261ZM4.68978 11.9869H3.95896V10.2891H4.68481C4.89362 10.2891 5.06597 10.3247 5.20186 10.396C5.33775 10.4656 5.43883 10.5642 5.50512 10.6918C5.57307 10.8177 5.60704 10.9652 5.60704 11.1342C5.60704 11.3016 5.57307 11.4499 5.50512 11.5792C5.43883 11.7068 5.33775 11.8071 5.20186 11.88C5.06762 11.9512 4.89693 11.9869 4.68978 11.9869ZM9.91952 14.5H8.11483V9.40909H9.93444C10.4465 9.40909 10.8873 9.51101 11.2569 9.71484C11.6264 9.91702 11.9106 10.2079 12.1095 10.5874C12.31 10.9669 12.4103 11.4209 12.4103 11.9496C12.4103 12.4799 12.31 12.9356 12.1095 13.3168C11.9106 13.6979 11.6248 13.9904 11.2519 14.1942C10.8807 14.3981 10.4366 14.5 9.91952 14.5ZM9.19118 13.5778H9.87478C10.193 13.5778 10.4606 13.5214 10.6777 13.4087C10.8964 13.2944 11.0605 13.1179 11.1699 12.8793C11.2809 12.639 11.3364 12.3291 11.3364 11.9496C11.3364 11.5734 11.2809 11.266 11.1699 11.0273C11.0605 10.7887 10.8973 10.613 10.6802 10.5004C10.4631 10.3877 10.1954 10.3313 9.87726 10.3313H9.19118V13.5778ZM13.9076 9.40909V14.5H14.984V12.397H17.0546V11.5096H14.984V10.2965H17.2783V9.40909H13.9076Z"
        fill={`${disabled ? "#000" : "#A42516"}`}
      />
    </svg>
  );
};
