type Props = {
  extension: string;
  file: Blob | string;
  fileName?: string;
};

export const downloadFile = ({ extension, file, fileName }: Props) => {
  const a = document.createElement("a");
  a.href = typeof file === "string" ? file : URL.createObjectURL(file);
  a.download = `${fileName}.${extension}`;
  a.click();

  // Clean up
  URL.revokeObjectURL(a.href);
  a.remove();
};
