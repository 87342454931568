import { Division } from "@/types/users/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useQuery } from "@tanstack/react-query";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseDivisionQueryReturn {
  data: Division[];
}

interface UseDivisionQueryParams {
  divisionId: string;
}

export const useDivision = (params: UseDivisionQueryParams) => {
  const { fetchWithAuth } = useAuthContext();
  const { divisionId } = params;

  return useQuery<UseDivisionQueryReturn, Error, Division>({
    queryKey: [QUERY_KEYS.DIVISION],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${usersBaseUrl}/division`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching branch");
      }

      return apiRes.json();
    },
    select: (division) => {
      const item = division.data.find((division) => division.id === divisionId);
      return item ?? ({} as Division);
    },
    enabled: Boolean(divisionId),
  });
};
