import { CreateJobInput } from "@/lib/graphql/graphql";
import { graphql } from "@/lib/graphql";
import { MUTATION_KEYS } from "../constants";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const createJob = graphql(`
  mutation CreateJob($input: CreateJobInput!) {
    createJob(input: $input) {
      code
      success
      message
      job {
        id
        accountId
        createdAt
        updatedAt
        divisionId
        branchId
        address {
          name
          formattedAddress
          lat
          lng
        }
        customer {
          name
          email
          phone
          type
          contactName
        }
        number
        notes
        status
        name
        projectOwner
        salesPerson
        startDate
        endDate
        tasks {
          id
          createdAt
          updatedAt
          accountId
          jobId
          date
          assets
          crew
          active
        }
        jobType
        color {
          id
          accountId
          name
          code
          createdAt
          updatedAt
        }
      }
    }
  }
`);

export const useGQLCreateJob = () => {
  const { graphqlRequest } = useAuthContext();

  return useMutation({
    mutationKey: [MUTATION_KEYS.CREATE_JOB],
    mutationFn: async (params: CreateJobInput) =>
      graphqlRequest(createJob, { input: params }),
  });
};
