import { usePricebookItems } from "@/lib/react-query/queryHooks/usePricebookItems";
import * as React from "react";
import { useState } from "react";
import { Option } from "@/types/option";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { Input } from "@/UI/Input";
import { FilterIcon, MagnifyingGlassIcon } from "@/assets";
import { Flyout } from "@/UI/Flyout";
import { Select } from "@/UI/Select";
import { Button } from "@/UI/Button";
import { Table } from "@/components/Table";
import { getPricebookColumns } from "@/utils/columnDefinitions/pricebookColumns";
import { GqlPriceBookItem } from "@/lib/graphql/graphql";
import { useNavigate } from "react-router-dom";
import { DangerModal } from "@/UI/Modal";
import { useDeletePricebookItem } from "@/lib/react-query/mutationHooks/useDeletePricebookItem";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "@/lib/react-query/constants";

export const PricebookPage: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<{ id: string; value: string }[]>([]);
  const [branchFilter, setBranchFilter] = useState<Option>();
  const [divisionFilter, setDivisionFilter] = useState<Option>();
  const { data: branches, isLoading: branchesLoading } = useBranches({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: divisions, isLoading: divisionsLoading } = useDivisions({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const filtersCount = filters.filter((filter) => filter.value);

  const branchOptions = branches?.data.map((item) => ({
    id: item.id,
    value: item.name,
  }));

  const divisionOptions = divisions?.data.map((item, idx) => ({
    id: item.id ?? idx.toString(),
    value: item.divisionName ?? "",
  }));

  const handleFiltering = (filterValue: Option, originalOption?: Option) => {
    const clonedFilters = structuredClone(filters);
    const index = clonedFilters.findIndex((item) => item.id === filterValue.id);

    if (index >= 0) {
      clonedFilters[index] = filterValue;
    } else {
      clonedFilters.push(filterValue);
    }

    setFilters(clonedFilters);

    switch (filterValue.id) {
      case "branch":
        setBranchFilter(originalOption);
        break;
      case "division":
        setDivisionFilter(originalOption);
        break;
      default:
        break;
    }
  };

  const onAddPricebookItem = () => {
    navigate("/settings/pricebook/create");
  };

  const { data: pricebookItems, isLoading: pricebookItemsLoading } =
    usePricebookItems({ options: { staleTime: 5 * 1000 * 60 } });

  const loadingState =
    branchesLoading || divisionsLoading || pricebookItemsLoading;

  const [deletePricebookItemModal, setDeletePricebookItemModal] = useState<{
    open: boolean;
    pricebookItemId?: string;
    name?: string;
  }>({
    open: false,
  });
  const handleDeletePricebookItem = (id: string, name: string) => {
    setDeletePricebookItemModal({ open: true, pricebookItemId: id, name });
  };
  const deletePricebookItem = useDeletePricebookItem();
  const queryClient = useQueryClient();
  const handleDeletePricebookItemConfirm = async () => {
    if (!deletePricebookItemModal.pricebookItemId) return;
    try {
      await deletePricebookItem.mutate({
        deletePriceBookItemId: deletePricebookItemModal.pricebookItemId,
      });
      setDeletePricebookItemModal({ open: false });
      toast.success("Pricebook item deleted");
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PRICEBOOK_ITEMS] });
    } catch (error) {
      toast.error("Failed to delete Pricebook Item");
    }
  };

  return (
    <div className="h-screen w-full pt-14">
      <header className="mb-8 flex w-full items-center justify-between">
        <h1 className="text-4xl font-semibold">Pricebook Items</h1>

        <div className="flex gap-x-7">
          <Input
            inputClasses="!w-[283px] !pl-6"
            onChange={(ev) =>
              handleFiltering({ id: "name", value: ev.target.value })
            }
            placeholder="Keyword Search (Name)"
            startAdornment={<MagnifyingGlassIcon />}
          />

          <Flyout
            popoverButton={
              <div
                className="
                flex h-10 w-full items-center
                justify-center gap-x-2 rounded-[50px] bg-theme-green-primary px-10 text-[14px] font-medium text-white 
                hover:opacity-80 active:opacity-80 disabled:bg-theme-black-quinary disabled:text-theme-gray-octonary"
              >
                {<FilterIcon />}
                {`Filters ${
                  filtersCount.length ? `(${filtersCount.length})` : ""
                }`}
              </div>
            }
            content={
              <div className="flex flex-wrap justify-between gap-y-3">
                <Select
                  options={[
                    { id: "", value: "All Branches" },
                    ...(branchOptions ? [...branchOptions] : []),
                  ]}
                  placeholder="Select Branch"
                  onChange={(option) => {
                    if (option) {
                      handleFiltering(
                        { id: "branch", value: option.id },
                        option
                      );
                    }
                  }}
                  label="Branch"
                  className="shrink-0 basis-[49%]"
                  value={branchFilter}
                />

                <Select
                  options={[
                    { id: "", value: "All Divisions" },
                    ...(divisionOptions ? [...divisionOptions] : []),
                  ]}
                  placeholder="Select Division"
                  onChange={(option) => {
                    if (option) {
                      handleFiltering(
                        { id: "division", value: option.id },
                        option
                      );
                    }
                  }}
                  label="Division"
                  className="shrink-0 basis-[49%]"
                  value={divisionFilter}
                />
              </div>
            }
          />

          <Button
            className="!rounded-full !text-white"
            label="Add Item"
            onClick={onAddPricebookItem}
            variant="primary"
          />
        </div>
      </header>

      <div className="h-[calc(100%-72px)] w-full overflow-auto rounded-md border bg-white">
        <Table
          clickableRow
          emptyMessage="No crew members found. Please check your search and filters and try again."
          filters={filters}
          loading={loadingState}
          tableColumns={getPricebookColumns({
            branches: branchOptions || [],
            divisions: divisionOptions || [],
            handleDeletePricebookItem,
          })}
          tableData={
            (pricebookItems?.data.priceBookItems as GqlPriceBookItem[]) || []
          }
          thClasses="!uppercase !tracking-[.52] !font-medium !text-[13px] !text-palette-black"
          theadClasses="!bg-[#fafafa]"
          trClasses="hover:bg-[#e9f2f4]"
        />
      </div>

      <DangerModal
        cancelAction={() =>
          setDeletePricebookItemModal({
            open: false,
            name: deletePricebookItemModal.name,
          })
        }
        cancelButtonDisabled={false}
        confirmAction={handleDeletePricebookItemConfirm}
        confirmButtonDisabled={false}
        message="Are you sure you want to delete this pricebook item? This action cannot be undone."
        open={deletePricebookItemModal.open}
        title={`Delete Item '${deletePricebookItemModal.name}'`}
      />
    </div>
  );
};
