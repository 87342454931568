/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Branch } from "@/types/users/general";
import { Button } from "@/UI/Button";
import { classNames } from "@/utils/helpers/classNames";
import { FC, useEffect, useState } from "react";
import { branchFormDefaultValues } from "./helpers";
import { PenIcon, TrashIcon } from "@/assets";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEditBranch } from "@/lib/react-query/mutationHooks/useEditBranch";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateBranch } from "@/lib/react-query/mutationHooks/useCreateBranch";
import ribbiotLogo from "@/assets/RibbiotLogo.png";
import { ControlledInput } from "@/UI/Input/ControlledInput";
import { toast } from "react-toastify";
import { Modal } from "@/UI/Modal";
import { LoadingSpinner } from "@/UI/Loading";

type BranchSettingsFormProps = {
  branchData: Branch | undefined;
  edit: boolean;
};

const handleBranchSettingsFormSchema = z.object({
  divisionId: z.string().uuid().optional(),
  address: z.string().min(1, { message: "Required" }),
  city: z.string().min(1, { message: "Required" }),
  email: z.string().min(1, { message: "Required" }),
  logo: z.string().optional(),
  name: z.string().min(1, { message: "Required" }),
  phoneNumber: z
    .string()
    .min(1, {
      message: "Required",
    })
    .regex(
      new RegExp(/^[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$/),
      "Phone number not in correct format. Ex: ###-###-####"
    ),
  postalCode: z.string().min(1, { message: "Required" }),
  state: z.string().min(1, { message: "Required" }),
});

export type BranchSettingsFormSchema = z.infer<
  typeof handleBranchSettingsFormSchema
>;

type ModalProps = {
  open: boolean;
  logo: string;
};

const BranchSettingsForm: FC<BranchSettingsFormProps> = ({
  branchData,
  edit,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [modal, setModal] = useState<ModalProps>({
    open: false,
    logo: ribbiotLogo,
  });

  const {
    handleSubmit,
    formState,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
  } = useForm<BranchSettingsFormSchema>({
    defaultValues: {
      ...branchFormDefaultValues,
      ...(edit && { logo: undefined }),
    },
    resolver: zodResolver(handleBranchSettingsFormSchema),
  });

  const logo = getValues().logo || "";

  const editBranch = useEditBranch();
  const createBranch = useCreateBranch();

  const handleLogoToShow = (img: string) => {
    if (img.startsWith("data")) {
      return img;
    }
    const hasQuestionMark = img.includes("?");
    if (hasQuestionMark) {
      const parsed = img.split("?")[0];
      return `${parsed}?etag=${new Date().getTime().toString()}`;
    }
    return `${img}?etag=${new Date().getTime().toString()}`;
  };

  const handleLogoToSend = (img: string) => {
    if (branchData?.logo === img) return undefined;
    const hasComma = img.includes(",");
    if (hasComma) {
      return img.split(",")[1];
    }
    return img;
  };

  const onSubmit: SubmitHandler<BranchSettingsFormSchema> = (data) => {
    if (data) {
      if (!data.logo) {
        setError("logo", { message: "Required" });
        return;
      }

      if (edit && branchData) {
        editBranch.mutate(
          {
            body: {
              ...data,
              logo: handleLogoToSend(logo),
            },
            branchId: branchData?.id,
          },
          {
            onSuccess: () => {
              toast.success("Branch edited");
            },
            onError: () => {
              toast.error("Error editing branch");
            },
          }
        );
      } else {
        createBranch.mutate(
          {
            body: {
              ...data,
              logo: handleLogoToSend(logo) as string,
            },
          },
          {
            onSuccess: () => {
              toast.success("Branch created");
            },
            onError: () => {
              toast.error("Error creating branch");
            },
          }
        );
      }
    }
  };

  const selectImg = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.addEventListener("change", (e) => {
      const selection = (e.target as HTMLInputElement).files?.[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        setModal({
          open: true,
          logo: event?.target?.result?.toString() || "",
        });
      };
      reader.readAsDataURL(selection!);
    });

    input.click();
    input.remove();
  };

  const handleImg = (clear: boolean) => {
    if (clear) {
      setModal({ open: false, logo: ribbiotLogo });
      setValue("logo", ribbiotLogo, { shouldDirty: true });
    } else {
      setModal((prev) => ({ open: false, logo: prev.logo }));
      setValue("logo", modal.logo as string, { shouldDirty: true });
      clearErrors("logo");
    }
  };

  const loadingState = editBranch.isPending || createBranch.isPending;

  useEffect(() => {
    if (branchData) {
      reset({
        address: branchData.address || "",
        email: branchData.email || "",
        logo: branchData.logo || "",
        name: branchData.name || "",
        phoneNumber: branchData.phoneNumber || "",
        city: branchData.city || "",
        postalCode: branchData.postalCode || "",
        state: branchData.state || "",
        divisionId: branchData.divisionId || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchData]);

  return (
    <>
      {loadingState && (
        <div className="relative z-50 translate-y-[100px]">
          <LoadingSpinner />
        </div>
      )}
      <form
        onSubmit={handleSubmit(onSubmit, (errors) =>
          console.error("errors", errors)
        )}
      >
        <div className="grid grid-cols-1 gap-y-4 min-[920px]:grid-cols-[0.5fr_1.5fr]">
          <div
            className={classNames(
              "group relative h-56 w-56 rounded-xl bg-theme-blue-primary",
              formState.errors.logo && "border-red-600"
            )}
          >
            {logo && (
              <>
                {!imageLoaded && <LoadingSpinner />}
                <img
                  className="h-56 w-56 rounded-xl border-2 bg-contain bg-no-repeat"
                  src={handleLogoToShow(logo)}
                  alt="Branch logo"
                  onLoad={() => setImageLoaded(true)}
                />
              </>
            )}

            <div className="absolute right-2 top-2 flex items-center gap-x-1 opacity-0 group-hover:opacity-100">
              <button
                className="flex h-7 w-7 items-center justify-center rounded-full bg-theme-green-primary"
                type="button"
                onClick={() => {
                  setModal({ open: true, logo: logo });
                }}
              >
                <PenIcon fill="white" />
              </button>
              <button
                onClick={() => handleImg(true)}
                className="flex h-7 w-7 items-center justify-center rounded-full bg-theme-red-primary"
                type="button"
              >
                <TrashIcon />
              </button>
            </div>

            {formState.errors.logo && (
              <p className="absolute bottom-[-22px] text-[12px] font-medium text-theme-red-primary">
                {formState.errors.logo.message}
              </p>
            )}
          </div>

          <div className="grid grid-cols-1 gap-y-5">
            <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
              <ControlledInput
                control={control}
                name="name"
                label="Branch Name"
                disabled={edit}
              />

              <ControlledInput
                control={control}
                name="divisionId"
                label="Division ID"
              />
              <ControlledInput label="Email" control={control} name="email" />
              <ControlledInput
                label="Phone Number"
                control={control}
                name="phoneNumber"
              />
              <ControlledInput
                label="Address"
                control={control}
                name="address"
              />
              <ControlledInput label="City" control={control} name="city" />
              <ControlledInput label="State" control={control} name="state" />
              <ControlledInput
                label="Postal Code"
                control={control}
                name="postalCode"
              />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "mt-6 flex flex-col justify-end gap-5 lg:flex-row"
          )}
        >
          <Button
            label="Save Branch"
            type="submit"
            variant="primary"
            disabled={!formState.isDirty}
            className="rounded-md"
          />
        </div>
      </form>

      {modal.open && (
        <Modal hideButtons open={modal.open} submit>
          <div>
            <div className="flex items-center justify-center">
              {modal.logo ? (
                <img
                  className="h-auto"
                  src={handleLogoToShow(modal.logo)}
                  alt=""
                />
              ) : (
                <div className="flex h-24 w-full items-center justify-center">
                  <span className="text-lg font-bold">Select an Image</span>
                </div>
              )}
            </div>

            <div className="mt-5 flex items-center justify-end gap-x-4">
              <Button
                label="Cancel"
                className="rounded-md"
                variant="secondary"
                onClick={() => {
                  setModal({ open: false, logo: logo });
                }}
              />
              <Button
                label="Select"
                variant="primary"
                onClick={selectImg}
                className="rounded-md"
              />
              <Button
                label="Ok"
                className="rounded-md"
                variant="primary"
                onClick={() => handleImg(false)}
                disabled={modal.logo ? false : true}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export { BranchSettingsForm };
