import { classNames } from "@/utils/helpers/classNames";

export const MultiQuoteItemFieldHeaders = () => {
  return (
    <div className="grid grid-cols-9 gap-4 pb-4">
      <h4
        className={classNames("text-sm font-medium text-theme-black-secondary")}
      >
        Item
      </h4>
      <h4
        className={classNames(
          "col-start-5  self-end text-sm font-medium text-theme-black-secondary"
        )}
      >
        Quantity
      </h4>
      <h4
        className={classNames(
          "col-start-6  self-end text-sm font-medium text-theme-black-secondary"
        )}
      >
        Unit
      </h4>
      <h4
        className={classNames(
          "col-start-7  self-end text-sm font-medium text-theme-black-secondary"
        )}
      >
        Rate
      </h4>
      <h4
        className={classNames(
          "col-start-9  self-end text-sm font-medium text-theme-black-secondary"
        )}
      >
        Amount
      </h4>
    </div>
  );
};
