export function alphaNumericSort(a: string, b: string) {
  // Regular expression to find the number at the end of the string
  const numberPattern = /\d+$/;

  // Extract the base text and the number, if present
  const matchA = a.match(numberPattern);
  const matchB = b.match(numberPattern);
  const baseA = a.replace(numberPattern, "");
  const baseB = b.replace(numberPattern, "");

  // If both strings have numbers at the end
  if (matchA && matchB) {
    const numA = parseInt(matchA[0], 10);
    const numB = parseInt(matchB[0], 10);

    // First, compare the base text
    if (baseA < baseB) return -1;
    if (baseA > baseB) return 1;

    // If the base text is the same, compare the numbers
    return numA - numB;
  }

  // If one string has a number and the other doesn't, or both are purely textual
  return a.localeCompare(b);
}
