import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { FC, useEffect } from "react";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { Button } from "@/UI/Button";

type Page404Props = {
  notFound?: boolean;
  message?: string;
};
const Page404: FC<Page404Props> = ({
  message = "Page Not Found",
  notFound = false,
}) => {
  const navigate = useNavigate();
  const {
    hasTimecardAdmin,
    hasAssetCrud,
    hasUserCrud,
    hasWebAssetMap,
    hasWebSchedule,
    hasRibbiotAdmin,
  } = useAuthContext();

  const isMobileUser =
    !hasRibbiotAdmin &&
    !hasTimecardAdmin &&
    !hasAssetCrud &&
    !hasUserCrud &&
    !hasWebAssetMap &&
    !hasWebSchedule;

  useEffect(() => {
    amplitude.track(AMPLITUDE_EVENTS.OOPS_PAGE_VIEWED, {
      url: location.href,
      error_message: isMobileUser
        ? "Mobile users cannot view webapp."
        : "Invalid Route",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="m-auto flex min-h-screen items-center justify-center py-16 sm:w-9/12">
      <div className="overflow-hidden bg-white pb-8 shadow sm:rounded-lg">
        <div className="border-t border-gray-200 pt-8 text-center">
          {isMobileUser ? (
            <>
              <a
                href="https://apps.apple.com/us/app/ribbiot/id6448410093"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://dev.ribbiot.com/download-on-the-app-store-logo.png"
                  alt="Apple App Store"
                  className="mx-auto block h-11"
                />
              </a>
              <h1 className=" text-4xl font-bold text-theme-green-primary sm:text-9xl">
                We have an app!
              </h1>
              <h1 className="py-8 text-lg font-medium sm:text-6xl">
                Click on the icon to download.
              </h1>
            </>
          ) : (
            <>
              <img
                width={200}
                height={200}
                className="m-auto"
                src="/error-frog.png"
                alt="Oops! The page you are looking for does not exist. It might have
            been moved or deleted."
              />
              <h1 className="text-7xl font-bold text-theme-green-primary">
                404
              </h1>
              <h1 className="p-8 text-6xl font-medium">{message}</h1>
              <p className="px-12 pb-8 text-2xl font-medium">
                {notFound ? (
                  <>Please make sure the URL is correct.</>
                ) : (
                  <>
                    Oops! The page you are looking for does not exist. It might
                    have been moved or deleted.
                  </>
                )}
              </p>
              <Button
                label="HOME"
                variant="primary"
                type="submit"
                className="w-32 !rounded-full !font-bold text-white"
                onClick={() => {
                  navigate("/");
                }}
              />
              <Button
                label="Support"
                variant="quarternary"
                type="submit"
                className="mx-auto mt-4 block w-32"
                onClick={() => {
                  window.open("https://ribbiot.zendesk.com/hc/en-us", "_blank");
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Page404;
