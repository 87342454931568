import { IAlert } from "@/UI/Alert";
import { useState } from "react";

export const useAlert = (initialAlertState?: Partial<IAlert>) => {
  const [alertStructure, setAlertStructure] = useState<IAlert>({
    open: initialAlertState?.open ?? false,
    onCancel:
      initialAlertState?.onCancel ??
      function () {
        setAlertStructure((prev) => ({ ...prev, open: false }));
      },
    onConfirm:
      initialAlertState?.onConfirm ??
      function () {
        return;
      },
    confirmButtonText: initialAlertState?.confirmButtonText ?? "Confirm",
    cancelButtonText: initialAlertState?.cancelButtonText ?? "Cancel",
    title: initialAlertState?.title ?? "",
    body: initialAlertState?.body ?? "",
  });
  const openAlert = () => {
    setAlertStructure((prev) => ({
      ...prev,
      open: true,
    }));
  };
  const closeAlert = () => {
    setAlertStructure((prev) => ({
      ...prev,
      open: false,
    }));
  };

  /**
   * Set the Alert parameters
   * @param setAlertParams
   */

  const setAlert = (setAlertParams: Partial<IAlert>) => {
    setAlertStructure((prev) => ({
      open: setAlertParams.open ?? prev.open,
      cancelButtonText:
        setAlertParams?.cancelButtonText ?? prev.cancelButtonText,
      confirmButtonText:
        setAlertParams.confirmButtonText ?? prev.confirmButtonText,
      title: setAlertParams.title ?? prev.title,
      onCancel: setAlertParams?.onCancel ?? prev.onCancel,
      onConfirm: setAlertParams.onConfirm ?? prev.onConfirm,
      body: setAlertParams.body ?? prev.body,
    }));
  };
  const isAlertOpen = alertStructure.open;
  return {
    alertProps: alertStructure,
    openAlert,
    closeAlert,
    setAlert,
    isAlertOpen,
  };
};
