import { FC } from "react";

interface CopyIconParams {
  className?: string;
  stroke?: string;
}
const CopyIcon: FC<CopyIconParams> = ({ className, stroke = "#3CD38B" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      className={className}
    >
      <rect
        x="4"
        y="4"
        width="12"
        height="13"
        rx="2"
        stroke={stroke}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2H5H11H13C13.3031 2 13.5957 2.04495 13.8715 2.12854C13.4981 0.896693 12.3538 0 11 0H3C1.34315 0 0 1.34315 0 3V12C0 13.3538 0.896693 14.4981 2.12854 14.8715C2.04495 14.5957 2 14.3031 2 14V12V5V3C2 2.44772 2.44772 2 3 2Z"
        fill={stroke}
      />
    </svg>
  );
};

export { CopyIcon };
