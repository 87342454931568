import { FormSubmission } from "@/types/forms/general";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const timecardsBaseUrl = import.meta.env.VITE_TIMECARDS_BASE_URL;

export interface CreateFormSubmissionBody {
  formId?: string;
  data?: Record<string, string>;
  fields?: { id: string; value: string }[];
  localDateTime?: string;
  version: string;
}
export interface UseCreateFormSubmissionMutationParams {
  body: CreateFormSubmissionBody;
}

export const useCreateFormSubmission = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<
    { data: FormSubmission },
    Error,
    UseCreateFormSubmissionMutationParams
  >({
    mutationFn: async ({ body }) => {
      const createFormSubmissionRes = await fetchWithAuth({
        url: `${timecardsBaseUrl}/form`,
        method: "POST",
        body: JSON.stringify(body),
      });

      if (!createFormSubmissionRes.ok) {
        throw new Error(`Error creating Form Submission`);
      }
      return createFormSubmissionRes.json();
    },
  });
};
