/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { useEffect } from "react";

export interface InfiniteScrollProps {
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, Error>>;
  hasNextPage: boolean | undefined;
  tableData: any[];
  lastRowSelector?: string;
}

const defaultLastRowTableSelector = "#table-container tbody tr:last-child";

export const useInfiniteScroll = ({
  fetchNextPage,
  hasNextPage = false,
  tableData,
  lastRowSelector = defaultLastRowTableSelector,
}: InfiniteScrollProps) => {
  const findLastRow = () => {
    return document.querySelector(lastRowSelector);
  };

  useEffect(() => {
    const lastRow = findLastRow();
    if (!tableData || !hasNextPage || !lastRow) return;
    let lastRowObserver: IntersectionObserver;
    const observeLastRow = async (lastRow: Element) => {
      lastRowObserver = new IntersectionObserver(async (entries) => {
        const lastRowEntry = entries[0];
        if (!lastRowEntry.isIntersecting) return;
        lastRowObserver.unobserve(lastRowEntry.target);
        fetchNextPage();
      });
      lastRowObserver.observe(lastRow);
    };

    observeLastRow(lastRow);

    return () => {
      lastRowObserver.disconnect();
      lastRowObserver.unobserve(lastRow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, tableData, fetchNextPage, lastRowSelector]);

  return null;
};
