import { classNames } from "@/utils/helpers/classNames";
import { FC, useEffect, useRef, useState } from "react";
import { HamburgerMenuIcon, RibbiotMobileLogo } from "@/assets";
import { MobileMenu } from "./MobileMenu";
import { useNavigate } from "react-router-dom";

type Props = {
  headerText: string;
};

export const MobileHeader: FC<Props> = ({ headerText }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);
  const hamburgerButtonRef = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const clickListener = (click: MouseEvent) => {
      if (hamburgerButtonRef.current?.contains(click.target as HTMLElement)) {
        setOpenMenu(true);
        return;
      }

      if (!sectionRef.current?.contains(click.target as HTMLElement)) {
        setOpenMenu(false);
        return;
      }
    };

    const escListener = (keyPressed: KeyboardEvent) => {
      if (keyPressed.key === "Escape") {
        setOpenMenu(false);
      }
    };

    window.addEventListener("click", clickListener);
    window.addEventListener("keydown", escListener);
    return () => {
      window.removeEventListener("click", clickListener);
      window.removeEventListener("keydown", escListener);
    };
  }, []);

  return (
    <header className="mb-[28px] flex flex-col gap-y-6">
      <div className="flex items-center justify-between">
        <button onClick={() => navigate("/schedule")} type="button">
          <RibbiotMobileLogo />
        </button>

        <button
          ref={hamburgerButtonRef}
          className={classNames(openMenu ? "rotate-90" : "")}
        >
          <HamburgerMenuIcon />
        </button>
      </div>

      <MobileMenu
        open={openMenu}
        setOpen={setOpenMenu}
        sectionRef={sectionRef}
      />

      <span className="text-2xl font-semibold">{headerText}</span>
    </header>
  );
};
