import { classNames } from "@/utils/helpers/classNames";
import { FC } from "react";
import {
  evaluateExpression,
  getTitleClassesByAttributes,
  transformTitleByFieldItem,
} from "@/utils/helpers/forms/formRendererHelpers";
import { RowField } from "@/types/forms/formEngine";

export interface RowSignatureFieldProps {
  fieldItem: RowField;
  value: string;
}
export const SignatureField: FC<RowSignatureFieldProps> = ({
  fieldItem,
  value,
}) => {
  const title = transformTitleByFieldItem(fieldItem);
  const hidden = evaluateExpression(fieldItem?.attributes?.hiddenIf);
  return (
    <div
      className={`space-y-1 ${hidden ? "hidden" : ""}`}
      style={{
        gridColumnStart: fieldItem?.columnStart,
        gridColumnEnd: fieldItem?.columnEnd,
      }}
    >
      {title && (
        <h4
          className={classNames(
            "rounded-sm  px-3 py-2 text-sm font-medium text-theme-black-secondary",
            getTitleClassesByAttributes(fieldItem?.attributes)
          )}
        >
          {title}
        </h4>
      )}
      {value && <img src={`data:image/png;base64,${value}`} alt="Red dot" />}
    </div>
  );
};
