import { Auth0Provider } from "@auth0/auth0-react";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

type IAuth0ProviderWithHistory = {
  children: ReactNode;
};

type AppState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  returnTo?: string | undefined;
};

export const Auth0ProviderWithHistory: FC<IAuth0ProviderWithHistory> = ({
  children,
}) => {
  const navigate = useNavigate();
  const domain = import.meta.env.VITE_AUTH0_DOMAIN ?? "";
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID ?? "";
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE ?? "";
  const scope = import.meta.env.VITE_AUTH0_SCOPE ?? "";

  const onRedirectCallback = (appState?: AppState | undefined) => {
    navigate(appState?.returnTo || window?.location.pathname);
  };

  const urlParams = new URLSearchParams(location.search);
  const loginHint = decodeURIComponent(urlParams.get("email") ?? "");

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
        scope,
        login_hint: loginHint,
      }}
      onRedirectCallback={onRedirectCallback}
      // Avoids possible missing_refresh_token errors on the client
      useRefreshTokensFallback={true}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
