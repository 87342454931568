/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider } from "@/UI/Divider";
import { FC } from "react";
import { Field } from "./Fields/FIeld";
import { MetadataField } from "./Fields/MetadataField";
import { NullField } from "./Fields/NullField";
import { RowField } from "@/types/forms/formEngine";
import { RowItemsContainer } from "./RowItemsContainer";
import { useFormRendererStoreSelectors } from "@/lib/zustand/formRendererStore";
import {
  getFormSubmissionValueById,
  getLayoutClassesByAttributes,
  renderFormComponent,
} from "@/utils/helpers/forms/formRendererHelpers";

export const FormRenderer: FC = () => {
  const formObject = useFormRendererStoreSelectors.use.formObject();

  return (
    <div>
      {formObject?.layouts.web?.fields?.map(
        (layoutField: any, layoutFieldIndex: number) => {
          switch (layoutField.type) {
            case "Divider":
              return (
                <Divider
                  additionalClasses={getLayoutClassesByAttributes(
                    layoutField.attributes
                  )}
                  key={layoutFieldIndex}
                />
              );

            case "Row": {
              return (
                <RowItemsContainer
                  key={layoutFieldIndex}
                  layoutField={layoutField}
                >
                  {layoutField.fields.map(
                    (fieldItem: RowField, index: number) => {
                      if (fieldItem?.text) {
                        return (
                          <Field
                            key={"textField" + fieldItem.text + index}
                            fieldItem={fieldItem}
                          />
                        );
                      }

                      if (fieldItem?.source === "metadata") {
                        return (
                          <MetadataField
                            key={"metadata" + fieldItem.id + index}
                            fieldItem={fieldItem}
                          />
                        );
                      }

                      const value = getFormSubmissionValueById(fieldItem?.id);

                      if (!value && fieldItem.attributes?.nullDash) {
                        return (
                          <NullField
                            key={`NullDash ${index}`}
                            fieldItem={fieldItem}
                          />
                        );
                      }

                      if (!value) return null;

                      const Component = renderFormComponent(fieldItem.type);

                      return (
                        <Component
                          key={fieldItem.id}
                          fieldItem={fieldItem}
                          value={value}
                        />
                      );
                    }
                  )}
                </RowItemsContainer>
              );
            }
            default:
          }
        }
      )}
    </div>
  );
};
