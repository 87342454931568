/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from "@/UI/Modal";
import { useDocumentDetailHelpers } from "@/utils/hooks/useDocumentDetailHelpers";
import { format } from "date-fns";
import * as React from "react";

export interface EditedField {
  id: string;
  name: string;
  beforeValue: any;
  afterValue: any;
}
interface ChangesModalProps {
  changeText: string;
  open: boolean;
  username: string;
  editedFields: EditedField[];
  editDate?: Date;
  onConfirm: () => void;
  onCancel: () => void;
  hideCancelButton?: boolean;
}

export const ChangesModal: React.FC<ChangesModalProps> = ({
  open,
  changeText,
  onConfirm,
  onCancel,
  editedFields,
  username,
  hideCancelButton = false,
  editDate,
}) => {
  const { transformDisplayValue } = useDocumentDetailHelpers();
  return (
    <Modal
      open={open}
      innerContainerClasses="!w-[700px] text-2xl font-semibold"
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText="Done"
      hideCancelButton={hideCancelButton}
    >
      <h1>Details for Timecard Edit</h1>
      <time className="text-base font-normal text-theme-black-secondary">
        {editDate && format(editDate, "eeee, MM/dd/yyyy 'at' h:mmaaa")}
      </time>
      <p className="text-base font-normal text-theme-black-secondary">
        <span className="capitalize">{username}</span> edited{" "}
        {editedFields.length === 1 && (
          <span className="font-semibold">{editedFields[0].name}</span>
        )}
        {editedFields.length === 2 && (
          <span className="font-semibold">
            {editedFields[0].name} <span className="font-normal">and</span>{" "}
            {editedFields[1].name}
          </span>
        )}
        {editedFields.length > 2 && (
          <span className="font-semibold">
            {editedFields
              .filter((editedField) => editedField.name.length > 0)
              .map((editedField) => editedField.name)
              .join(", ")}
          </span>
        )}
      </p>
      <p className="mt-4 text-sm font-normal text-theme-black-secondary">
        Notes
      </p>
      <p className="text-base font-normal text-theme-black-secondary">
        {changeText}
      </p>
      <div className="mt-4 max-h-[50vh] divide-y-2  overflow-y-auto rounded-lg bg-theme-white-secondary px-6 py-2">
        {editedFields.map((editedField) => (
          <div key={editedField.id} className="py-4">
            <h1 className="text-lg font-medium text-theme-black-secondary">
              {editedField.name}
            </h1>

            <p className="mt-4 text-sm font-normal text-theme-black-secondary">
              Original Value
            </p>
            <p className="text-base font-medium">
              {transformDisplayValue(
                editedField.id,
                editedField.beforeValue,
                editedField.name
              )}
            </p>
            <p className="mt-4 text-sm font-normal text-theme-black-secondary">
              Revised To
            </p>
            <p className="text-base font-medium">
              {transformDisplayValue(
                editedField.id,
                editedField.afterValue,
                editedField.name
              )}
            </p>
          </div>
        ))}
      </div>
    </Modal>
  );
};
