import { formatInTimeZone } from "date-fns-tz";
import { isValid, parseISO } from "date-fns";
import { usaDateFormat } from "@/utils/helpers/dateFormat";
import { Field } from "./FIeld";
import { FC } from "react";
import { RowField } from "@/types/forms/formEngine";
import { useFormRendererStoreSelectors } from "@/lib/zustand/formRendererStore";
import { get } from "@/utils/helpers/lodashCopies";
type DateFieldProps = {
  fieldItem: RowField;
  value?: string;
};

export const DateField: FC<DateFieldProps> = ({ fieldItem, value = "" }) => {
  const formSubmission = useFormRendererStoreSelectors.use.formSubmission();
  const timezoneOffset = get(formSubmission, "timezoneOffset");
  const parsedValue = parseISO(value);
  const getFormattedValue = () => {
    if (!isValid(parsedValue)) {
      return value;
    }
    return formatInTimeZone(parsedValue, timezoneOffset, usaDateFormat);
  };

  return <Field value={getFormattedValue()} fieldItem={fieldItem} />;
};
