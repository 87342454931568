export const MobileLogOutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
    >
      <g id="Group 1000005110">
        <g id="Group 1000005110_2">
          <path
            id="Rectangle 2720"
            d="M9 9V3H20V21H9V15"
            stroke="black"
            strokeWidth="2"
          />
          <path
            id="Vector 2279"
            d="M12 12H2M2 12L5.5 8.5M2 12L5.5 15.5"
            stroke="black"
            strokeWidth="2"
          />
          <path
            id="Rectangle 2721"
            d="M21 21.2192L15 22.7192V1.28078L21 2.78078V21.2192Z"
            fill="black"
            stroke="black"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};
