import { Control, Controller } from "react-hook-form";
import { FC, InputHTMLAttributes } from "react";
import { GoogleAutocomplete } from "./GoogleAutocomplete";

interface ControlledGoogleAutocompleteProps
  extends InputHTMLAttributes<HTMLInputElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  name: string;
  onClickEndAdorment?: () => void;
  required?: boolean;
}

export const ControlledGoogleAutocomplete: FC<
  ControlledGoogleAutocompleteProps
> = ({ control, name, onClickEndAdorment, required }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <GoogleAutocomplete
          error={error}
          onChange={onChange}
          onClickEndAdorment={onClickEndAdorment}
          value={value}
        />
      )}
    />
  );
};
