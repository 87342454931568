import { FC } from "react";
import { Controller } from "react-hook-form";
import { TimePicker } from ".";

type ControlledTimePickerProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  label?: string;
  required?: boolean | string;
  disabled?: boolean;
  dateFormat?: string;
};

const ControlledTimePicker: FC<ControlledTimePickerProps> = ({
  control,
  name,
  label,
  required = false,
  disabled = false,
  dateFormat,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TimePicker
          onChange={onChange}
          label={label}
          error={error}
          disabled={disabled}
          value={value}
          dateFormat={dateFormat}
        />
      )}
    />
  );
};

export { ControlledTimePicker };
