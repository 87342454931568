import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { queryClient } from "../general";
import { QUERY_KEYS } from "../constants";
import { useMutation } from "@tanstack/react-query";

const jobsBaseUrl = import.meta.env.VITE_JOBS_BASE_URL;

interface UseUploadJobDocumentMutationParams {
  document: {
    mime: string;
    data: string | ArrayBuffer | null | undefined;
  };
  jobId: string;
}

interface UseUploadJobDocumentMutationReturn {
  data: {
    $metadata: {
      httpStatusCode: number;
      requestId: string;
      extendedRequestId: string;
      attempts: number;
      totalRetryDelay: number;
    };
    ETag: string;
    ServerSideEncryption: string;
    Location: string;
    Extension: string;
  };
}

export const useUploadJobDocument = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<
    UseUploadJobDocumentMutationReturn,
    Error,
    UseUploadJobDocumentMutationParams
  >({
    mutationFn: async ({ document }) => {
      const apiRes = await fetchWithAuth({
        url: `${jobsBaseUrl}/upload-document`,
        method: "POST",
        body: JSON.stringify(document),
      });

      if (!apiRes.ok) {
        throw new Error(`Error while uploading Job Document`);
      }

      return apiRes.json();
    },
    onSuccess: (_, variables) => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.JOB_DOCUMENTS, { jobId: variables.jobId }],
        });
      }, 1000);
    },
  });
};
