import { FC } from "react";

type DangerIconProps = {
  warning?: boolean;
};
const DangerIcon: FC<DangerIconProps> = ({ warning = false }) => {
  const fill = warning ? "#F4A331" : "#D65D5A";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.8779 20C3.20264 20 2.78094 19.2585 3.11928 18.6661L11.2414 4.44342C11.579 3.85219 12.421 3.85219 12.7586 4.44342L20.8807 18.6661C21.2191 19.2585 20.7974 20 20.1221 20H3.8779ZM12.8766 17.3321H12.0568C12.038 17.3333 12.0191 17.3339 12 17.3339C11.9809 17.3339 11.962 17.3333 11.9432 17.3321H11.1234V15.5547H12.8766V17.3321ZM12.8766 13.7792H11.1234V9.33579H12.8766V13.7792Z"
        fill={fill}
      />
    </svg>
  );
};

export { DangerIcon };
