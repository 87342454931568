import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";
import { CreatePriceBookItemInput } from "@/lib/graphql/graphql";
import { graphql } from "@/lib/graphql";

const CreatePriceBookItem = graphql(`
  mutation CreatePriceBookItem($input: CreatePriceBookItemInput!) {
    createPriceBookItem(input: $input) {
      success
    }
  }
`);

export const useCreatePricebookItem = () => {
  const { graphqlRequest } = useAuthContext();
  return useMutation({
    mutationFn: async (input: CreatePriceBookItemInput) =>
      graphqlRequest(CreatePriceBookItem, { input }),
  });
};
