/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider } from "@/UI/Divider";
import { UseEditFormSubmissionHistoryReturn } from "@/lib/react-query/queryHooks/useEditFormSubmissionHistory";
import { useFormRendererStoreSelectors } from "@/lib/zustand/formRendererStore";
import { FormSubmissionHistoryAuditRecord } from "@/types/forms/general";
import {
  formatDateWithinTimezoneOffset,
  isValidISOString,
  usaDateTimeFormat,
} from "@/utils/helpers/dateFormat";
import { getDisplayName } from "@/utils/helpers/forms/formRendererHelpers";
import { useDocumentDetailHelpers } from "@/utils/hooks/useDocumentDetailHelpers";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { ChangesModal, EditedField } from "./ChangesModal";
import { useState } from "react";
import { LoadingSpinner } from "@/UI/Loading";

type EditHistoryProps = {
  auditData: UseEditFormSubmissionHistoryReturn | undefined;
  editHistoryLoading: boolean;
};
export const EditHistory: React.FC<EditHistoryProps> = ({
  auditData,
  editHistoryLoading,
}) => {
  const formSubmission = useFormRendererStoreSelectors.use.formSubmission();
  const [selectedAuditRecord, setSelectedAuditRecord] =
    useState<FormSubmissionHistoryAuditRecord>();
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const { getUserNameById } = useDocumentDetailHelpers();

  const getFormattedName = (userId: string) => {
    if (userId === "platform-system-user") return userId;

    const fullName = getUserNameById(userId);
    return fullName
      ? `${fullName?.split(" ")[0]} ${fullName?.split(" ")[1][0]}.`
      : "";
  };

  const getEditedFields = (auditRecord?: FormSubmissionHistoryAuditRecord) => {
    if (
      !auditRecord?.oldRecord?.fields ||
      !auditRecord?.newRecord?.fields ||
      !formSubmission
    ) {
      return [];
    }

    const changeFields = auditRecord.diffRecord.fields?.map(
      (field) => field.id
    );

    const beforeObject = auditRecord.oldRecord.fields
      .filter((field) => changeFields?.includes(field.id))
      .reduce((acc: any, curr: any) => {
        if (isValidISOString(curr.value)) {
          acc[curr.id] = formatDateWithinTimezoneOffset(
            parseISO(curr.value),
            formSubmission.timezoneOffset
          );
          return acc;
        }
        acc[curr.id] = curr.value;
        return acc;
      }, {});

    const afterObject = auditRecord.newRecord.fields
      .filter((field) => changeFields?.includes(field.id))
      .reduce((acc: any, curr: any) => {
        if (isValidISOString(curr.value)) {
          acc[curr.id] = formatDateWithinTimezoneOffset(
            parseISO(curr.value),
            formSubmission.timezoneOffset
          );
          return acc;
        }
        acc[curr.id] = curr.value;
        return acc;
      }, {});

    const editedFields: EditedField[] = [];

    Object.keys(afterObject).map((afterId) => {
      editedFields.push({
        id: afterId,
        name: getDisplayName(afterId) ?? "",
        beforeValue: beforeObject[afterId],
        afterValue: afterObject[afterId],
      });
    });
    if (auditRecord.diffRecord?.localDateTime) {
      editedFields.push({
        id: "localDateTime",
        name: getDisplayName("localDateTime") ?? "",
        afterValue: formatDateWithinTimezoneOffset(
          parseISO(auditRecord.newRecord.localDateTime),
          formSubmission.timezoneOffset
        ),
        beforeValue: formatDateWithinTimezoneOffset(
          parseISO(auditRecord.oldRecord.localDateTime),
          formSubmission.timezoneOffset
        ),
      });
    }
    return editedFields;
  };
  const sortedAuditData = auditData?.data
    .sort(
      (audita, auditb) =>
        new Date(auditb.createdAt).getTime() -
        new Date(audita.createdAt).getTime()
    )
    .filter((audit) => !Object.keys(audit.diffRecord).includes("pdfFilePath"));

  return (
    <div>
      <div className="mb-4 flex content-center justify-between">
        <div className="flex content-center justify-center">
          <h2 className="text-2xl font-medium">Edit History</h2>
          {editHistoryLoading && (
            <div className="translate-y-20">
              <LoadingSpinner />
            </div>
          )}
        </div>
        <span className="self-center">
          {sortedAuditData?.length ?? 0}{" "}
          {sortedAuditData?.length == 1 ? "Edit" : "Edits"}
        </span>
      </div>
      <Divider additionalClasses="border-t-2 my-4" />
      {sortedAuditData?.map((audit) => {
        return (
          <div key={audit.id}>
            <div className="text-sm text-theme-black-secondary">
              <div className="flex justify-between">
                <span>
                  {format(new Date(audit.createdAt), usaDateTimeFormat)}
                </span>
                <button
                  onClick={() => {
                    setSelectedAuditRecord(audit);
                    setDetailsModalOpen(true);
                  }}
                  className="underline"
                >
                  Details
                </button>
              </div>
              <p className="my-2">
                {getFormattedName(audit.userId)} edited{" "}
                {audit.diffRecord.fields
                  ?.filter((field) => !!getDisplayName(field.id))
                  ?.slice(0, 5)
                  .map((field, index, array) => (
                    <strong key={field.id}>
                      {array.length === 2 && index === 1 && (
                        <span className="font-normal"> and </span>
                      )}
                      {getDisplayName(field.id)}
                      <span>
                        {array.length - 1 !== index &&
                          array.length !== 2 &&
                          index !== 5 && <>, </>}
                      </span>
                      <span>
                        {array.length < audit.diffRecord.fields.length &&
                          array.length - 1 === index && <>...</>}
                      </span>
                    </strong>
                  ))}
              </p>
              <p>{audit.changeNotes}</p>
            </div>
            <Divider additionalClasses="border-t-2 my-4" />
          </div>
        );
      })}
      <ChangesModal
        open={detailsModalOpen}
        changeText={selectedAuditRecord?.changeNotes ?? ""}
        editDate={
          selectedAuditRecord && new Date(selectedAuditRecord.createdAt)
        }
        editedFields={getEditedFields(selectedAuditRecord)}
        username={getUserNameById(selectedAuditRecord?.userId ?? "") ?? ""}
        onConfirm={() => setDetailsModalOpen(false)}
        onCancel={() => setDetailsModalOpen(false)}
        hideCancelButton={true}
      />
    </div>
  );
};
