import { Branch } from "@/types/users/general";
import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { DefinedInitialDataOptions, useQuery } from "@tanstack/react-query";

const usersBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseBranchesQueryReturn {
  data: Branch[];
}

type Params = {
  options?: Partial<DefinedInitialDataOptions<UseBranchesQueryReturn>>;
};

export const useBranches = (params?: Params) => {
  const { fetchWithAuth } = useAuthContext();
  const options = params?.options;

  return useQuery<UseBranchesQueryReturn, Error>({
    queryKey: [QUERY_KEYS.BRANCHES],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${usersBaseUrl}/branch`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching branches");
      }

      return apiRes.json();
    },
    ...(options && { ...options }),
  });
};
