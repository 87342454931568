import { FC } from "react";
import { Controller } from "react-hook-form";
import { DatePicker } from ".";

type ControlledDatePickerProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  customWrapperClassName?: string;
  dateFormat?: string;
  disabled?: boolean;
  externalChangeHandler?: (date: Date | string | null) => void;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  name: string;
  parseValue?: boolean;
  required?: boolean | string;
};

const ControlledDatePicker: FC<ControlledDatePickerProps> = ({
  control,
  customWrapperClassName = "",
  dateFormat = "",
  disabled = false,
  externalChangeHandler,
  label,
  maxDate,
  minDate,
  name,
  parseValue,
  required = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DatePicker
          customWrapperClassName={customWrapperClassName}
          dateFormat={dateFormat}
          disabled={disabled}
          error={error}
          externalChangeHandler={externalChangeHandler}
          label={label}
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChange}
          parseValue={parseValue}
          value={value}
        />
      )}
    />
  );
};

export { ControlledDatePicker };
