import { DivisionSettingsForm } from "@/components/AccountSettings";

const CreateDivisionPage = () => {
  return (
    <div className="w-full bg-transparent">
      <div className="h-screen items-center overflow-y-auto">
        <h1 className="mb-12 mt-24 text-4xl font-semibold">
          Division Settings
        </h1>
        <DivisionSettingsForm divisionData={undefined} edit={false} />{" "}
      </div>
    </div>
  );
};
export default CreateDivisionPage;
