import { Control, Controller, Path, PathValue } from "react-hook-form";
import { MultiSelect, MultiSelectOption } from ".";
import { ChangeEvent } from "react";

type ControlledMultiSelectProps<T extends object> = {
  control: Control<T>;
  disabled?: boolean;
  label?: string;
  menuStyle?: Record<string, string | number>;
  name: Path<T>;
  options: MultiSelectOption;
  placeholder?: string;
  required?: boolean;
  selectStyle?: Record<string, string | number>;
};

function ControlledMultiSelect<T extends object>({
  control,
  disabled,
  label,
  menuStyle,
  name,
  options,
  placeholder = "",
  required = false,
  selectStyle,
}: ControlledMultiSelectProps<T>) {
  return (
    <Controller
      name={name}
      rules={{ required }}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <MultiSelect
            disabled={disabled}
            error={error}
            label={label}
            menuStyle={menuStyle}
            onChange={(newValue, _) =>
              onChange(newValue as ChangeEvent<Element> | PathValue<T, Path<T>>)
            }
            options={options}
            placeholder={placeholder}
            selectStyle={selectStyle}
            value={value}
          />
        );
      }}
    />
  );
}

export { ControlledMultiSelect };
