import { FC } from "react";

type Props = {
  width?: string;
  height?: string;
};

const CloseIcon: FC<Props> = ({ height = "40", width = "40" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
    >
      <g clipPath="url(#clip0_63_2711)">
        <mask
          id="mask0_63_2711"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <rect width="40" height="40" fill="black" />
        </mask>
        <g mask="url(#mask0_63_2711)">
          <path
            d="M28.3333 14.0167L25.9833 11.6667L20 17.65L14.0166 11.6667L11.6666 14.0167L17.65 20L11.6666 25.9833L14.0166 28.3333L20 22.35L25.9833 28.3333L28.3333 25.9833L22.35 20L28.3333 14.0167Z"
            fill="#575757"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_63_2711">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { CloseIcon };
