import { QUERY_KEYS } from "../constants";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { useQuery } from "@tanstack/react-query";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

export const useGoogleMapsAPIKey = () => {
  const { fetchWithAuth, hasWebAssetMap, hasWebSchedule, hasRibbiotAdmin } =
    useAuthContext();

  return useQuery<string, Error>({
    queryKey: [QUERY_KEYS.GOOGLE_MAP_KEY],
    queryFn: async () => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/googleMaps/googleApiKey`,
      });

      if (!apiRes.ok) {
        throw new Error("Error fetching API key");
      }

      return apiRes.json();
    },
    enabled: hasRibbiotAdmin || hasWebAssetMap || hasWebSchedule,
    staleTime: Infinity,
  });
};
