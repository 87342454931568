import { FC } from "react";

type Props = {
  pathClassName?: string;
  gclassName?: string;
};

const ProfileIcon: FC<Props> = ({ gclassName, pathClassName }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g className={gclassName} clipPath="url(#clip0_460_13497)">
        <path
          d="M11.7944 21.5889C17.1649 21.5889 21.5919 17.1552 21.5919 11.7944C21.5919 6.42695 17.1582 2 11.7877 2C6.42695 2 2 6.42695 2 11.7944C2 17.1552 6.43369 21.5889 11.7944 21.5889ZM11.7944 20.3223C7.07002 20.3223 3.27334 16.5188 3.27334 11.7944C3.27334 7.07002 7.06328 3.26358 11.7877 3.26358C16.5121 3.26358 20.3253 7.07002 20.3253 11.7944C20.3253 16.5188 16.5188 20.3223 11.7944 20.3223ZM18.4257 18.1604L18.4085 18.0772C17.8664 16.681 15.3216 15.0633 11.7944 15.0633C8.28008 15.0633 5.73525 16.6749 5.18037 18.0583L5.16016 18.1604C6.89072 19.9107 9.66006 20.9748 11.7975 20.9748C13.9477 20.9748 16.6514 19.9326 18.4257 18.1604ZM11.7944 13.391C13.6731 13.4015 15.1229 11.8029 15.1229 9.7124C15.1229 7.75186 13.6543 6.11494 11.7944 6.11494C9.93154 6.11494 8.45322 7.75186 8.46299 9.7124C8.46973 11.8029 9.91943 13.3775 11.7944 13.391Z"
          fill="black"
          className={pathClassName}
        />
      </g>
      <defs>
        <clipPath id="clip0_460_13497">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { ProfileIcon };
