import { Form } from "@/types/forms/general";
import { useAuthContext } from "../../../../components/Auth/AuthWrapper";
import { useMutation } from "@tanstack/react-query";

const userManagementBaseUrl = import.meta.env.VITE_USERS_BASE_URL;

interface UseEditFormMutationParams {
  form: string;
}

export const useEditForm = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<Form, Error, UseEditFormMutationParams>({
    mutationFn: async ({ form }) => {
      const apiRes = await fetchWithAuth({
        url: `${userManagementBaseUrl}/accounts/forms`,
        method: "PATCH",
        body: form,
      });

      if (!apiRes.ok) {
        throw new Error(`Error editing form.`);
      }

      return apiRes.json();
    },
  });
};
