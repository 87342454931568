import { AssetsAsset, Attachment } from "@/types/assetInventory/assets/general";
import { useAuthContext } from "../../../components/Auth/AuthWrapper";
import { QUERY_KEYS } from "../constants";
import { queryClient } from "../general";
import { useMutation } from "@tanstack/react-query";

const assetsInventoryBaseUrl = import.meta.env.VITE_ASSETS_INVENTORY_BASE_URL;

type Body = {
  attachments: Attachment[];
};

interface UseUpdateAttachmentMutationParams {
  assetId: string;
  attachmentIndex: number;
  body: Body;
  categoryId: string;
}

interface UseUpdateAttachmentMutationReturn {
  data: {
    asset: AssetsAsset;
  };
}

export const useUpdateAttachment = () => {
  const { fetchWithAuth } = useAuthContext();

  return useMutation<
    UseUpdateAttachmentMutationReturn,
    Error,
    UseUpdateAttachmentMutationParams
  >({
    mutationFn: async ({ assetId, body }) => {
      const apiRes = await fetchWithAuth({
        url: `${assetsInventoryBaseUrl}/v2/assets/${assetId}/attachment`,
        method: "PUT",
        body: JSON.stringify(body),
      });

      if (!apiRes.ok) {
        throw new Error(`Error editing attachment.`);
      }

      return apiRes.json();
    },
    onSuccess: (_mutationReturn, variables) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ASSET, { assetId: variables.assetId }],
        refetchType: "all",
        stale: true,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.ASSETS],
        refetchType: "all",
        stale: true,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CATEGORIES],
        refetchType: "all",
        stale: true,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CATEGORY, { categoryId: variables.categoryId }],
        refetchType: "all",
        stale: true,
      });

      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.CATEGORY_CHILDREN,
          { categoryId: variables.categoryId },
        ],
        refetchType: "all",
        stale: true,
      });
    },
  });
};
